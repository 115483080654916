import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { INationalSearchState } from '../../app/NationalSearch/reducer';
import { IPartnerSearchState } from '../../app/PartnerSearch/reducer';
import { IProjectsState } from '../../app/Projects/reducer';
import { fetchQueryRequest } from '../../app/Query/actions';
import { IQueryState } from '../../app/Query/reducer';
import { fetchSavedDocumentsRequest } from '../../app/Saved/actions';
import { selectableTabs } from '../../app/Search/actions';
import { ISearchState } from '../../app/Search/reducer';
import { IState } from '../../reducers';
import { store } from '../../store';
import Filter from '../Search/SearchResults/Filter/Filter';
import SearchResults from '../Search/SearchResults/FundingResults';
import NationalFundingResults from '../Search/SearchResults/NationalSearchResults';
import PartnersResults from '../Search/SearchResults/PartnerResult/PartnersResults';
import ProjectResults from '../Search/SearchResults/ProjectResult/ProjectResults';
import Tabs from '../Search/Tabs/Tabs';
import './QueryPage.css';

interface IProps {
  searchResults: ISearchState;
  nationalSearchResults: INationalSearchState;
  partners: IPartnerSearchState;
  query: IQueryState;
  projects: IProjectsState;
}

interface IHeaderProps {
  query: IQueryState;
}

const Header: React.FC<IHeaderProps> = ({ query }) => (
  <div className="query-page__title">
    <p>Showing results for query</p>

    <p>"{query.selectedQuery.query}"</p>
  </div>
);

class Query extends React.Component<IProps & RouteComponentProps> {
  componentDidMount() {
    this.getQuery();
    this.getSaved();
  }

  getSaved() {
    store.dispatch(fetchSavedDocumentsRequest());
  }

  getQuery() {
    // @ts-ignore
    const identifier = this.props.match.params.identifier;
    console.log(identifier);
    if (identifier) {
      store.dispatch(fetchQueryRequest(identifier));
    }
  }

  render() {
    const { query, searchResults, nationalSearchResults, partners, projects } = this.props;
    return (
      <div className="query-page">
        <div className="query-page-header">
          <Header query={query} />

          <Tabs />
        </div>

        {(searchResults.results.length > 0 ||
          nationalSearchResults.results.length > 0 ||
          partners.results.length > 0 ||
          projects.results.length > 0) && <Filter />}

        {searchResults.selectedTab === selectableTabs.FUNDING && <SearchResults />}

        {searchResults.selectedTab === selectableTabs.NATIONAL && <NationalFundingResults />}

        {searchResults.selectedTab === selectableTabs.PARTNERS && <PartnersResults />}

        {searchResults.selectedTab === selectableTabs.PROJECTS && <ProjectResults />}
      </div>
    );
  }
}

const QueryWithRouter = withRouter(Query);

const mapStateToProps = ({ searchResults, nationalSearchResults, partners, projects, query }: IState) => {
  return { searchResults, nationalSearchResults, partners, projects, query };
};

export const QueryPage = connect(mapStateToProps)(QueryWithRouter);
