import { Transition } from '@headlessui/react';
import * as React from 'react';
import { ReactComponent as IconArrowRight } from '../../../../assets/icon--arrow--right--tailwind.svg';
import './Collapsible.css';

interface ITransitionProps {
  children: React.ReactNode;
  setState: (state: boolean) => void;
  state: boolean;
  label: string;
}

const Collapsible: React.FunctionComponent<ITransitionProps> = ({
  children,
  setState,
  label,
  state = false,
}) => {
  const handleVisibility = () => {
    setState(!state);
  };
  return (
    <div>
      <div onClick={handleVisibility} className="collapse">
        {<IconArrowRight className={!state ? 'chevron-icon__right' : 'chevron-icon__down'} />}

        <p>{label}</p>
      </div>

      <Transition
        show={state}
        enter="transition--enter__leave"
        enterFrom="transition--from__to"
        enterTo="transition--to__from"
        leave="transition--enter__leave"
        leaveFrom="transition--to__from"
        leaveTo="transition--from__to"
      >
        {children}
      </Transition>
    </div>
  );
};

export default Collapsible;
