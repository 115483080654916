import { Icon } from '@fluentui/react/lib/Icon';
import * as React from 'react';

interface IBudgetsProps {
  budget_groups: any[];
}

interface IBudgetProps {
  budget: {
    actions: string[];
    budget_year_map: any;
  };
  keyProp: number;
}

interface IBudgetState {
  budgets_collapsed: boolean;
}

const Budget: React.FC<IBudgetProps> = ({ budget, keyProp }) => {
  const year = Object.keys(budget.budget_year_map)[0];
  const amount = budget.budget_year_map[year];

  return (
    <li key={keyProp}>
      {Number(year) > 0 ? year + ':' : ''} {amount}
      <ul>
        {budget.actions.map((topic: any, i: number) => (
          <li key={i}>{topic}</li>
        ))}
      </ul>
    </li>
  );
};

const Budgets: React.FC<IBudgetsProps> = ({ budget_groups }) => {
  const [state, setState] = React.useState<IBudgetState>({
    budgets_collapsed: false,
  });

  return (
    <React.Fragment>
      <a
        className="topic-info-drop"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setState({ budgets_collapsed: !state.budgets_collapsed });
        }}
      >
        <strong>
          Budget of the topic{' '}
          {state.budgets_collapsed ? <Icon iconName="ChevronDown" /> : <Icon iconName="ChevronUp" />}
        </strong>
      </a>

      {state.budgets_collapsed ? null : (
        <ul>
          {budget_groups.map((budget, key) => (
            <Budget budget={budget} key={key} keyProp={key} />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

export default Budgets;
