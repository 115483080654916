import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';

import callApi from '../../utils/callApi';

function* handleSearch(action: any): any {
  const { payload } = action;

  try {
    const res = yield call(
      callApi,
      'POST',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v1/project`,
      payload,
    );

    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      } else {
        switch (action.type) {
          case actions.ProjectsActionTypes.SEARCH_PROJECTS_REQUEST: {
            yield put(actions.searchProjectsResponse(res));
            break;
          }
        }
      }
    }
  } catch (err) {
    handleError(err);
  }
}

function* handleFetch(action: any): any {
  const { payload } = action;
  try {
    const res = yield call(
      callApi,
      'GET',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v1/project/${payload}`,
    );
    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      } else {
        switch (action.type) {
          case actions.ProjectsActionTypes.FETCH_PROJECT_REQUEST: {
            yield put(actions.fetchProjectResponse(res));
            yield put(actions.selectProject(res));
            break;
          }
        }
      }
    }
  } catch (err) {
    handleError(err);
  }
}

function* handleError(err: any) {
  if (err instanceof Error) {
    yield put(actions.searchProjectsError(err.message));
  } else {
    yield put(actions.searchProjectsError('An unknown error occured.'));
  }
}

function* searchProjectsRequest() {
  yield takeEvery(actions.ProjectsActionTypes.SEARCH_PROJECTS_REQUEST, handleSearch);
}

function* fetchProjectRequest() {
  yield takeEvery(actions.ProjectsActionTypes.FETCH_PROJECT_REQUEST, handleFetch);
}

function* projectsSaga() {
  yield all([fork(searchProjectsRequest), fork(fetchProjectRequest)]);
}

export default projectsSaga;
