import { logError } from './logger';

export const fetchHealthCheck = async () => {
  const searchUrl = process.env.REACT_APP_SEARCH_API_URL || '';

  try {
    const { response } = await fetch(searchUrl + '/api/v2/health', {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json());

    const { status, statusText, date, backendStatus, message } = response;

    return { status, statusText, date, backendStatus, message };
  } catch (error) {
    logError({ Message: error.message });
    return error.message;
  }
};
