import * as React from 'react';
import * as moment from 'moment';
import { useCalendarContext } from './Context';
import { useTooltip } from '../Tooltip';
import IconCalendar from '../../assets/icon--calendar.svg';
import './calendar.css';

export const MiniCalendar = () => {
  const ref = React.useRef(null);
  const { loading, openCalendar } = useCalendarContext();
  const today = moment();
  const date = today.format('D');

  const { toggleTooltip, tooltip } = useTooltip({
    ref,
    content: (
      <p>
        Click here to see all your saved funding topics in a calendar. You will get an email reminder when the
        related deadlines are approaching. You can modify the reminder preferences from your profile section
        on the top right corner.
      </p>
    ),
    align: 'bottomLeft',
  });

  const showCalendarModal = () => {
    document.body.classList.add('preventModalScrolling');
    openCalendar();
  };

  if (loading) {
    return null;
  }

  return (
    <div className="mini-calendar__wrapper">
      <div
        ref={ref}
        className="mini-calendar"
        onMouseEnter={() => toggleTooltip(true)}
        onMouseLeave={() => toggleTooltip(false)}
      >
        <img src={IconCalendar} alt="" />

        <button onClick={showCalendarModal}>{date}</button>
      </div>

      {tooltip}
    </div>
  );
};
