import * as React from 'react';
import { useCalendarContext } from './Context';
import * as moment from 'moment';
import IconBack from '../../assets/icon--arrow--left.svg';

export const ViewDate = () => {
  const { deadlines, viewDate, closeDate } = useCalendarContext();

  if (!viewDate) {
    return null;
  }

  const deadlinesForDate = deadlines
    .filter((deadline) => viewDate.isSame(moment(deadline.deadline), 'date'))
    .map((deadlineDate) => {
      const [callProgrammeName, ...callTitle] = deadlineDate.title.split(' - ');

      return {
        callProgrammeName,
        callTitle: callTitle.join(' - '),
        title: deadlineDate.title,
      };
    });

  return (
    <div className="calendar__view-date">
      <div className="calendar__view-date__header">
        <button onClick={() => closeDate()}>
          <img src={IconBack} alt="" />
        </button>

        <p>List of deadlines for {viewDate?.format('DD.MM.YYYY')}</p>
      </div>

      <div className="calendar__view-date__content">
        {deadlinesForDate.map((date, index) => (
          <DeadlineDate key={`${date.title}-${index}`} {...date} />
        ))}
      </div>
    </div>
  );
};

export default ViewDate;

interface IDeadlineDateProps {
  callProgrammeName: string;
  callTitle: string;
}

const DeadlineDate = ({ callProgrammeName, callTitle }: IDeadlineDateProps) => {
  return (
    <div className="calendar__view-date__deadline-date">
      <p>
        <strong>{callProgrammeName}</strong>
      </p>

      <p>{callTitle}</p>
    </div>
  );
};
