import { action } from 'typesafe-actions';

export const enum selectableTabs {
  FUNDING = 'FUNDING',
  NATIONAL = 'NATIONAL',
  PARTNERS = 'PARTNERS',
  PROJECTS = 'PROJECTS',
}

export interface ISearchQueryData {
  query: string;
  limit?: number;
  statuses?: string[];
  exclude?: any[];
}

export interface ISearchQuery {
  query: string;
  scope?: string;
}

export interface ISearchQueryResults {
  data: ISearchQuery[];
}

export interface ISearchClosedTopicsQuery {
  topics: { identifier: string };
}

export interface ISearchResult {
  call_title: string;
  title: string;
  similarity: string;
  type_of_action: string;
  deadline_dates: string[];
  call_identifier: string;
  topic_status: string;
  description: string;
  budget_numbers: string;
  trl_text: string;
  identifier: string;
  topic_id: string;
  publication_date: string;
  planned_opening_date: string;
  deadline1: string;
  budget_groups: object[];
  topic_url: string;
}

export interface ISearchResults {
  data: ISearchResult[];
}

export const enum SearchActionTypes {
  SEARCH_REQUEST = '@@search/REQUEST',
  SEARCH_SUCCESS = '@@search/SUCCESS',
  SEARCH_MORE_REQUEST = '@@search/MORE_REQUEST',
  SEARCH_MORE_SUCCESS = '@@search/MORE_SUCCESS',
  SEARCH_CLOSED_TOPICS_REQUEST = '@@search/CLOSED_TOPICS_REQUEST',
  SEARCH_CLOSED_TOPICS_SUCCESS = '@@search/CLOSED_TOPICS_SUCCESS',
  SEARCH_ERROR = '@@search/ERROR',
  SET_QUERY = '@@search/SET_QUERY',
  SET_FORM_VALID = '@@search/SET_FORM_VALID',
  SELECT_TAB = '@@search/SELECT_TAB',
  SET_ORDER_CONDITION = '@@search/SET_ORDER_CONDITION',
  ADD_FILTER_CONDITION = '@@search/ADD_FILTER_CONDITION',
  REMOVE_FILTER_CONDITION = '@@search/REMOVE_FILTER_CONDITION',
  CLEAR_FILTER = '@@search/CLEAR_FILTER',
  SELECT_ITEM = '@@search/SELECT_ITEM',
  UNSELECT_ITEM = '@@search/UNSELECT_ITEM',
  FETCH_TOPIC_SUCCESS = '@@search/FETCH_TOPIC_SUCCESS',
  CLEAR_SELECTED_TOPIC = '@@search/CLEAR_SELECTED_TOPIC',
  FETCH_TOPIC_INTERESTED_PARTNERS_REQUEST = '@@search/FETCH_TOPIC_INTERESTED_PARTNERS_REQUEST',
  FETCH_TOPIC_INTERESTED_PARTNERS_SUCCESS = '@@search/FETCH_TOPIC_INTERESTED_PARTNERS_SUCCESS',
  FETCH_TOPIC_DESCRIPTION_REQUEST = '@@search/FETCH_TOPIC_DESCRIPTION_REQUEST',
  FETCH_TOPIC_DESCRIPTION_SUCCESS = '@@search/FETCH_TOPIC_DESCRIPTION_SUCCESS',
  CLEAR_TOPIC_DESCRIPTION = '@@search/CLEAR_TOPIC_DESCRIPTION',
}

export const searchRequest = (data: ISearchQueryData) => action(SearchActionTypes.SEARCH_REQUEST, data);

export const searchResponse = (data: ISearchResults) => action(SearchActionTypes.SEARCH_SUCCESS, data);

export const searchMoreRequest = (data: ISearchQueryData) =>
  action(SearchActionTypes.SEARCH_MORE_REQUEST, data);

export const searchMoreResponse = (data: ISearchResults) =>
  action(SearchActionTypes.SEARCH_MORE_SUCCESS, data);

export const searchClosedTopicsRequest = (data: ISearchClosedTopicsQuery) =>
  action(SearchActionTypes.SEARCH_CLOSED_TOPICS_REQUEST, data);

export const searchClosedTopicsResponse = (data: ISearchResults) =>
  action(SearchActionTypes.SEARCH_CLOSED_TOPICS_SUCCESS, data);

export const searchError = (data: {}) => action(SearchActionTypes.SEARCH_ERROR, data);

export const setFormValid = () => action(SearchActionTypes.SET_FORM_VALID);

export const setQuery = (data: ISearchQuery) => action(SearchActionTypes.SET_QUERY, data.query);

export const selectTab = (data: string) => action(SearchActionTypes.SELECT_TAB, data);

export const setOrderCondition = (condition: string) =>
  action(SearchActionTypes.SET_ORDER_CONDITION, condition);

export const addFilterCondition = (key: string, value: string) =>
  action(SearchActionTypes.ADD_FILTER_CONDITION, { key, value });

export const removeFilterCondition = (key: string, value: string) =>
  action(SearchActionTypes.REMOVE_FILTER_CONDITION, { key, value });

export const clearFilter = () => action(SearchActionTypes.CLEAR_FILTER);

export const selectItem = (item: any) => action(SearchActionTypes.SELECT_ITEM, item);

export const unSelectItem = (item: any) => action(SearchActionTypes.UNSELECT_ITEM, item);

export const fetchTopicSuccess = (item: ISearchResult) => action(SearchActionTypes.FETCH_TOPIC_SUCCESS, item);

export const clearSelectedTopic = () => action(SearchActionTypes.CLEAR_SELECTED_TOPIC);

export const fetchTopicInterestedPartnersSuccess = (topicInterestedPartner: any, topicId: any) =>
  action(SearchActionTypes.FETCH_TOPIC_INTERESTED_PARTNERS_SUCCESS, {
    topicInterestedPartner,
    topicId,
  });

export const fetchTopicDescription = (data: any) =>
  action(SearchActionTypes.FETCH_TOPIC_DESCRIPTION_REQUEST, data);

export const fetchTopicDescriptionSuccess = (topicDescription: any) =>
  action(SearchActionTypes.FETCH_TOPIC_DESCRIPTION_SUCCESS, topicDescription);

export const clearTopicDescription = () => action(SearchActionTypes.CLEAR_TOPIC_DESCRIPTION);
