import { createId } from '@paralleldrive/cuid2';
import * as React from 'react';
import { connect } from 'react-redux';
import { fetchPartnerRequest } from '../../../app/PartnerSearch/actions';
import { removeSavedDocumentRequest } from '../../../app/Saved/actions';
import { ISavedState } from '../../../app/Saved/reducer';
import { selectableTabs } from '../../../app/Search/actions';
import PartnerDialog from '../../../components/Search/SearchResults/PartnerResult/PartnerDialog';
import { IState } from '../../../reducers';
import { store } from '../../../store';
import { ExpandableSection } from '../ExpandableSection';
import './SavedTopics.css';

interface IProps {
  saved: ISavedState;
  partners?: any;
}

interface IProjectProps {
  partner: any;
}

const Partner: React.FC<IProjectProps> = ({ partner }) => {
  const removeProject = (savedDocument: any) => {
    store.dispatch(removeSavedDocumentRequest(savedDocument.objectId));
  };

  const openProject = (savedDocument: any) => {
    store.dispatch(fetchPartnerRequest(Number(savedDocument.objectId)));
  };

  return (
    <div className="saved-topics__topic">
      <div className="saved-topics__topic-title">
        <p>{partner.title}</p>
      </div>

      <div className="saved-topics__topic-buttons">
        <button className="open" onClick={() => openProject(partner)}>
          Open
        </button>

        <button className="remove" onClick={() => removeProject(partner)}>
          Remove
        </button>
      </div>
    </div>
  );
};

const SavedProjects: React.FC<IProps> = ({ saved }) => {
  const savedPartners = saved.savedDocuments.filter(
    (document: any) => document.scope === selectableTabs.PARTNERS,
  );

  return (
    <ExpandableSection title="Saved partners" count={savedPartners.length}>
      <div className="saved-topics">
        {savedPartners.map((document: any) => (
          <Partner partner={document} key={createId()} />
        ))}

        <PartnerDialog />
      </div>
    </ExpandableSection>
  );
};

const mapStateToProps = ({ saved, partners }: IState) => {
  return { saved, partners };
};

export default connect(mapStateToProps)(SavedProjects);
