import { action } from 'typesafe-actions';

export interface ISearchQuery {
  query: string;
  scope?: string;
}

export interface ISearchQueryResults {
  data: ISearchQuery[];
}

export const enum QueryActionTypes {
  SAVE_QUERY_REQUEST = '@@query/SAVE_QUERY_REQUEST',
  SAVE_QUERY_SUCCESS = '@@query/SAVE_QUERY_SUCCESS',
  FETCH_QUERIES_REQUEST = '@@query/FETCH_QUERIES_REQUEST',
  FETCH_QUERIES_SUCCESS = '@@query/FETCH_QUERIES_SUCCESS',
  FETCH_QUERY_REQUEST = '@@query/FETCH_QUERY_REQUEST',
  FETCH_QUERY_SUCCESS = '@@query/FETCH_QUERY_SUCCESS',
  QUERY_ERROR = '@@query/QUERY_ERROR',
  SELECT_QUERY = '@@query/SELECT_QUERY',
}

export const saveQueryRequest = (data: ISearchQuery) => action(QueryActionTypes.SAVE_QUERY_REQUEST, data);

export const saveQueryResponse = (data: {}) => action(QueryActionTypes.SAVE_QUERY_SUCCESS, data);

export const fetchQueriesRequest = () => action(QueryActionTypes.FETCH_QUERIES_REQUEST);

export const fetchQueriesSuccess = (data: ISearchQueryResults) =>
  action(QueryActionTypes.FETCH_QUERIES_SUCCESS, data);

export const fetchQueryRequest = (data: string) => action(QueryActionTypes.FETCH_QUERY_REQUEST, data);

export const fetchQuerySuccess = (data: ISearchQuery) => action(QueryActionTypes.FETCH_QUERY_SUCCESS, data);

export const queryError = (data: {}) => action(QueryActionTypes.QUERY_ERROR, data);

export const selectQuery = (data: ISearchQuery) => action(QueryActionTypes.SELECT_QUERY, data);
