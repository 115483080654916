import { action } from 'typesafe-actions';

export interface ISaveQuery {
  scope: string;
  objectId: string;
  title: string;
  deadline?: string;
}

export interface ISaveQueryResults {
  data: ISaveQuery[];
}

export const enum SavedActionTypes {
  SAVE_DOCUMENT_REQUEST = '@@saved/SAVE_DOCUMENT_REQUEST',
  SAVE_DOCUMENT_SUCCESS = '@@saved/SAVE_DOCUMENT_SUCCESS',
  FETCH_SAVED_REQUEST = '@@saved/FETCH_SAVED_REQUEST',
  FETCH_SAVED_SUCCESS = '@@saved/FETCH_SAVED_SUCCESS',
  REMOVE_SAVE_REQUEST = '@@saved/REMOVE_SAVE_REQUEST',
  REMOVE_SAVE_SUCCESS = '@@saved/REMOVE_SAVE_SUCCESS',
  SAVED_ERROR = '@@saved/SAVED_ERROR',
}

export const saveDocumentRequest = (data: ISaveQuery) => action(SavedActionTypes.SAVE_DOCUMENT_REQUEST, data);

export const saveDocumentSuccess = (data: ISaveQuery) => action(SavedActionTypes.SAVE_DOCUMENT_SUCCESS, data);

export const fetchSavedDocumentsRequest = () => action(SavedActionTypes.FETCH_SAVED_REQUEST);

export const fetchSavedDocumentsSuccess = (data: ISaveQueryResults) =>
  action(SavedActionTypes.FETCH_SAVED_SUCCESS, data);

export const removeSavedDocumentRequest = (topicId: string) =>
  action(SavedActionTypes.REMOVE_SAVE_REQUEST, topicId);

export const removeSavedDocumentSuccess = (data: {}) => action(SavedActionTypes.REMOVE_SAVE_SUCCESS);

export const savedError = (data: {}) => action(SavedActionTypes.SAVED_ERROR);
