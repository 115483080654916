import * as React from 'react';
import { connect } from 'react-redux';

import { Spinner } from '@fluentui/react/lib/Spinner';
import * as auth from '../../app/Auth/Auth';
import { selectableTabs } from '../../app/Search/actions';
import { IState } from '../../reducers';
import { MiniCalendar } from '../Calendar/Mini';
import Intro from './Intro';
import SearchInput from './SearchInput/SearchInput';
import Filter from './SearchResults/Filter/Filter';
import SearchResults from './SearchResults/FundingResults';
import NationalFundingResults from './SearchResults/NationalSearchResults';
import PartnersResults from './SearchResults/PartnerResult/PartnersResults';
import ProjectResults from './SearchResults/ProjectResult/ProjectResults';
import Tabs from './Tabs/Tabs';

import { INationalSearchState } from '../../app/NationalSearch/reducer';
import { IPartnerSearchState } from '../../app/PartnerSearch/reducer';
import { IProjectsState } from '../../app/Projects/reducer';
import { ISearchState } from '../../app/Search/reducer';
import { ISettingsState, Onboarding } from '../../app/Settings/reducer';
import './SearchPage.css';

import { getSettings } from '../../app/Settings/actions';
import { store } from '../../store';

interface IProps {
  searchResults: ISearchState;
  nationalSearchResults: INationalSearchState;
  partners: IPartnerSearchState;
  projects: IProjectsState;
  settings: ISettingsState;
}

const Search: React.FC<IProps> = ({ searchResults, nationalSearchResults, partners, projects, settings }) => {
  const { selectedTab } = searchResults;

  const hasResults =
    searchResults.results.length > 0 ||
    nationalSearchResults.results.length > 0 ||
    partners.results.length > 0 ||
    projects.results.length > 0;

  const isAuthenticated = auth.isAuthenticated();

  React.useEffect(() => {
    if (isAuthenticated && !settings.loading && !settings.loaded) {
      store.dispatch(getSettings());
    }
  }, [isAuthenticated, settings.loading]);

  if (!isAuthenticated) {
    return <Unauthenticated />;
  }

  if (settings.loading) {
    return <Spinner />;
  }

  if (settings.settings.onboarding === Onboarding.SHOW_INTRO) {
    return <Intro />;
  }

  return (
    <div className="search-page">
      <div className="container query">
        <div className="aside">
          <MiniCalendar />
        </div>

        <div className="main">
          <SearchInput />
        </div>
      </div>

      <div className="container results">
        <div className="aside">
          <div className="filter-sticky-container">{hasResults && <Filter />}</div>
        </div>

        <div className="main">
          {auth.canUseSearch() && auth.isEmailVerified() && <Tabs />}

          {selectedTab === selectableTabs.FUNDING && <SearchResults />}

          {selectedTab === selectableTabs.NATIONAL && <NationalFundingResults />}

          {selectedTab === selectableTabs.PARTNERS && <PartnersResults />}

          {selectedTab === selectableTabs.PROJECTS && <ProjectResults />}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ searchResults, nationalSearchResults, partners, projects, settings }: IState) => {
  return { searchResults, nationalSearchResults, partners, projects, settings };
};

export const SearchPage = connect(mapStateToProps)(Search);

const Unauthenticated = () => {
  return (
    <div className="login-container">
      <h1>SPINBASE</h1>

      <h2>Funding and Partners for your Idea - Instantly</h2>

      <div className="actions">
        <button onClick={() => auth.login()}>Register</button>

        <button onClick={() => auth.login()}>Log in</button>
      </div>
    </div>
  );
};
