import { Reducer } from 'redux';
import { ExportActionTypes } from './actions';

export interface IExportState {
  isExporting: boolean;
  isExcelExporting: boolean;
}
export const initialState: IExportState = {
  isExporting: false,
  isExcelExporting: false,
};

const exportReducer: Reducer<IExportState> = (state = initialState, action): IExportState => {
  switch (action.type) {
    case ExportActionTypes.EXPORT_REQUEST_EU_TOPICS: {
      return { ...state, isExporting: true };
    }
    case ExportActionTypes.EXPORT_REQUEST_NATIONAL_TOPICS: {
      return { ...state, isExporting: true };
    }
    case ExportActionTypes.EXPORT_EXCEL_REQUEST: {
      return { ...state, isExcelExporting: true };
    }
    case ExportActionTypes.EXPORT_EXCEL_REQUEST_SUCCESS: {
      return { ...state, isExcelExporting: false };
    }
  }
  return state;
};

export { exportReducer };
