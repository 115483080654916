import { action } from 'typesafe-actions';

export interface IUser {
  isLoading: boolean;
}

export const enum UserActionTypes {
  SET_USER_PROFILE = '@@user/SET_PROFILE',
  CLEAR_USER_PROFILE = '@@user/CLEAR_PROFILE',
}

export const setUserProfile = (profile: auth0.Auth0UserProfile & IUser) =>
  action(UserActionTypes.SET_USER_PROFILE, profile);

export const clearUserProfile = () => action(UserActionTypes.CLEAR_USER_PROFILE);
