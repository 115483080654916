export const getEnvironment = (): string => {
  const fullUrl = window.location.host;
  const parts = fullUrl.split('.');

  if (parts[0] === 'dev') {
    return 'dev';
  }
  if (parts[0] === 'stg') {
    return 'stg';
  }
  return 'prod';
};
