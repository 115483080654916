import * as classnames from 'classnames';
import * as React from 'react';
import { updateSettings } from '../../../../app/Settings/actions';
import { store } from '../../../../store';

interface IReminderIntervalProps {
  loading: boolean;
  reminderInterval: number[];
}

export const ReminderIntervalSetting = ({ loading, reminderInterval }: IReminderIntervalProps) => {
  const [value, setValue] = React.useState<number[]>(reminderInterval);

  React.useEffect(() => {
    setValue(reminderInterval);
  }, [reminderInterval]);

  const handleChange = React.useCallback(
    (v: any) => {
      const newValue = value.indexOf(v) !== -1 ? value.filter((val) => val !== v) : [...value, v];

      setValue(newValue);
      store.dispatch(updateSettings({ reminderInterval: newValue }));
    },
    [value],
  );

  return (
    <div className="setting-item">
      <div>
        <div className="setting-label">Email reminders</div>
        <div className="setting-content">
          <div className="setting-option-group">
            <Option
              id="reminder-interval--14"
              value={14}
              selected={value.indexOf(14) !== -1}
              disabled={loading}
              onChange={() => handleChange(14)}
            />

            <Option
              id="reminder-interval--30"
              value={30}
              selected={value.indexOf(30) !== -1}
              disabled={loading}
              onChange={() => handleChange(30)}
            />
          </div>
        </div>

        <div className="setting-description">
          Select the duration(s) in days you wish to be reminded of calls before the deadline
        </div>
      </div>
    </div>
  );
};

interface IOptionProps {
  id: string;
  value: number;
  selected: boolean;
  disabled: boolean;
  onChange: () => void;
}

const Option = ({ id, value, selected, disabled, onChange }: IOptionProps) => {
  return (
    <label htmlFor={id} className={classnames('setting-option', { selected, disabled })}>
      <input
        type="checkbox"
        id={id}
        value={value}
        checked={selected}
        disabled={disabled}
        onChange={onChange}
      />

      <p>{value} days</p>
    </label>
  );
};
