// Removes inline styles from table elements
import { logError } from '../../utils/logger';

export const removeTableInlineStyles = (ref: React.RefObject<HTMLDivElement>) => {
  try {
    if (ref.current) {
      const tableElements = ref.current.querySelectorAll('table, thead, tr, th, tbody, td, tfoot');

      for (const element of Array.from<any>(tableElements)) {
        element.setAttribute('style', '');
      }
    }
  } catch (e) {
    logError(e);
  }
};

// Adds target="_blank" to external links
export const openExternalLinksInNewWindow = (ref: React.RefObject<HTMLDivElement>) => {
  try {
    if (ref.current) {
      const anchors = ref.current.querySelectorAll('a');

      for (const anchor of Array.from<HTMLAnchorElement>(anchors)) {
        const url = new URL(anchor.href);
        const isExternal = url.host !== location.host;

        if (isExternal) {
          anchor.setAttribute('target', '_blank');
        }
      }
    }
  } catch (e) {
    logError(e);
  }
};
