import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver';

export const exportToExcel = (excelData: any[]) => {
    const Max_CELL_LENGTH = 32767;
    const transformedData = excelData.map((row, rowIndex) => {
      const transformedRow: any = {};
      for (const key in row) {
        const value = row[key];

        if (typeof value === 'string' && value.length > Max_CELL_LENGTH) {
          const chunks = [];
          for (let i = 0; i < value.length; i += Max_CELL_LENGTH) {
            chunks.push(value.slice(i, i + Max_CELL_LENGTH));
          }

          chunks.forEach((chunk, index) => {
            transformedRow[`${key}_part${index + 1}`] = chunk;
          });
        } else {
          transformedRow[key] = value;
        }
      }
      return transformedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `Fundings.xlsx`);
  };