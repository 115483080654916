export const containsValue = (item: any, filterConditions: any[]) => {
  if (!filterConditions || filterConditions.length < 1) {
    return true;
  }
  let result: boolean = true;
  const filterableKeys = [...new Set(filterConditions.map((condition) => condition.key))];

  for (const key of filterableKeys) {
    result = checkKey(key, filterConditions, item);
    if (!result) {
      break;
    }
  }

  return result;
};

const checkKey = (key: string, filterConditions: any[], item: any) => {
  const keyConditions = filterConditions.filter((condition) => condition.key === key);

  let result = false;
  for (const condition of keyConditions) {
    if (item[key] !== undefined && (item[key] === condition.value || item[key].includes(condition.value))) {
      result = true;
    }
  }

  return result;
};
