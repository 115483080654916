import { Reducer } from 'redux';
import { FilterActionTypes } from './actions';

export interface IFilterState {
  selectedFilter: string;
}

export const initialState: IFilterState = {
  selectedFilter: '',
};

const filterReducer: Reducer<IFilterState> = (state = initialState, action): IFilterState => {
  switch (action.type) {
    case FilterActionTypes.OPEN_FILTER: {
      return { ...state, selectedFilter: action.payload };
    }
    case FilterActionTypes.CLOSE_FILTER: {
      return { ...state, selectedFilter: '' };
    }
  }
  return state;
};

export { filterReducer };
