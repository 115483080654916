import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import callApi from '../../utils/callApi';
import * as actions from './actions';
import { isTrialUser } from '../Auth/Auth';

function* handleFetch(action: any): any {
  const param = {
    searchCategory: 'eu_topic',
    status: action.payload.statuses ? action.payload.statuses.join(',') : 'Forthcoming,Draft,Open',
    q: action.payload.query,
    limit: action.payload.limit,
    offset: action.payload.offset,
    isTrialUser: isTrialUser(),
  };

  try {
    const res = yield call(callApi, 'post', process.env.REACT_APP_SEARCH_API_URL || '', `/v2/search`, param);
    if (res) {
      if (res.description) {
        throw new Error(res.description);
      }
      if (res.error) {
        throw new Error(res.error);
      }
      switch (action.type) {
        case '@@search/REQUEST': {
          yield put(actions.searchResponse(res));
          break;
        }
        case '@@search/MORE_REQUEST': {
          yield put(actions.searchMoreResponse(res));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.searchError(err.message));
    } else {
      yield put(actions.searchError('An unknown error occured.'));
    }
  }
}

function* handleClosedTopicsFetch(action: any): any {
  const payload = action.payload;

  try {
    const res = yield call(
      callApi,
      'post',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v2/search/closed_topics`,
      payload,
    );

    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      }
      switch (action.type) {
        case '@@search/CLOSED_TOPICS_REQUEST': {
          yield put(actions.searchClosedTopicsResponse(res));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.searchError(err.message));
    } else {
      yield put(actions.searchError('An unknown error occured.'));
    }
  }
}

function* handleFetchTopicDescription(action: any): any {
  const { payload } = action;
  const { topicId, query } = payload;
  const data = { query_data: query, searchCategory: 'eu_topic' };
  try {
    const res = yield call(
      callApi,
      'POST',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v2/search/documents/${topicId}`,
      data,
    );

    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      }
      switch (action.type) {
        case actions.SearchActionTypes.FETCH_TOPIC_DESCRIPTION_REQUEST: {
          if (typeof res.identifier !== 'undefined') {
            yield put(
              actions.searchClosedTopicsRequest({
                topics: { identifier: res.identifier },
              }),
            );
          }
          yield put(actions.fetchTopicDescriptionSuccess(res));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.searchError(err.message));
    } else {
      yield put(actions.searchError('An unknown error occured.'));
    }
  }
}

function* handleFetchTopicInterestedPartners(action: any): any {
  const { identifier, id } = action.payload;
  try {
    const res = yield call(
      callApi,
      'get',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v2/search/topic-interested-partners/${identifier}`,
    );
    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      }
      switch (action.type) {
        case actions.SearchActionTypes.SELECT_ITEM: {
          yield put(actions.fetchTopicInterestedPartnersSuccess(res, id));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.searchError(err.message));
    } else {
      yield put(actions.searchError('An unknown error occured.'));
    }
  }
}

function* watchSearchRequest() {
  yield takeEvery(actions.SearchActionTypes.SEARCH_REQUEST, handleFetch);
}

function* watchSearchMoreRequest() {
  yield takeEvery(actions.SearchActionTypes.SEARCH_MORE_REQUEST, handleFetch);
}

function* watchSearchClosedTopicsRequest() {
  yield takeEvery(actions.SearchActionTypes.SEARCH_CLOSED_TOPICS_REQUEST, handleClosedTopicsFetch);
}

function* fetchTopicDescription() {
  yield takeEvery(actions.SearchActionTypes.FETCH_TOPIC_DESCRIPTION_REQUEST, handleFetchTopicDescription);
}

function* fetchTopicInterestedPartnersRequest() {
  yield takeEvery(actions.SearchActionTypes.SELECT_ITEM, handleFetchTopicInterestedPartners);
}

function* searchSaga() {
  yield all([
    fork(watchSearchRequest),
    fork(watchSearchMoreRequest),
    fork(watchSearchClosedTopicsRequest),
    fork(fetchTopicDescription),
    fork(fetchTopicInterestedPartnersRequest),
  ]);
}

export default searchSaga;
