import * as React from 'react';

import { Spinner } from '@fluentui/react/lib/Spinner';
import { connect } from 'react-redux';
import { getSettings } from '../../../app/Settings/actions';
import { ISettingsState } from '../../../app/Settings/reducer';
import { IState } from '../../../reducers';
import { store } from '../../../store';
import { ReminderIntervalSetting } from './ReminderInterval';
import './ReminderSettings.css';

interface IPropsFromState extends ISettingsState {}

class ReminderSettings extends React.Component<IPropsFromState> {
  componentDidMount() {
    if (!this.props.loading) {
      store.dispatch(getSettings());
    }
  }

  render() {
    return (
      <div className="settings-card">
        <div className="settings-header">
          <h3>Settings</h3>
          {this.props.loading ? <Spinner /> : null}
        </div>

        <div className="settings-list">
          <ReminderIntervalSetting
            loading={this.props.loading}
            reminderInterval={this.props.settings.reminderInterval}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => state.settings;

export default connect(mapStateToProps)(ReminderSettings);
