import * as cn from 'classnames';
import * as React from 'react';
import { ITooltipProps, useTooltip } from '../Tooltip';
import './ActionButton.css';

interface IProps {
  text: string;
  disabled?: boolean;
  handleClick: () => void;
  iconName?: string;
  selected?: boolean;
  clicked?: boolean;
  tooltip?: ITooltipProps;
}

export const ActionButton: React.FC<IProps> = ({
  text,
  handleClick,
  selected,
  clicked,
  tooltip,
  disabled = false,
}) => {
  const ref = React.useRef(null);
  const { toggleTooltip, tooltip: tt } = useTooltip({
    ref,
    content: tooltip?.content,
    align: tooltip?.position,
  });

  return (
    <>
      <button
        ref={ref}
        disabled={disabled}
        className={cn('action-button', {
          'action-button--selected': selected,
          'action-button--clicked': clicked,
        })}
        onClick={handleClick}
        onMouseEnter={() => toggleTooltip(true)}
        onMouseLeave={() => toggleTooltip(false)}
      >
        {text}
        {tt}
      </button>
    </>
  );
};

export const DefaultButton: React.FC<IProps> = ({ text, handleClick, selected, clicked }) => (
  <button
    className={`action-button action-button__default ${selected && 'action-button--selected'} ${
      clicked && 'action-button--clicked'
    }`}
    onClick={handleClick}
  >
    {text}
  </button>
);
