import { action } from 'typesafe-actions';

export const enum FilterActionTypes {
  OPEN_FILTER = '@@filter/OPEN_FILTER',
  CLOSE_FILTER = '@@filter/CLOSE_FILTER',
}

export const openFilter = (filter: string) => action(FilterActionTypes.OPEN_FILTER, filter);

export const closeFilter = () => action(FilterActionTypes.CLOSE_FILTER);
