import * as React from 'react';

import './Tabs.css';

interface ITabProps {
  selected: boolean;
  tab: string;
  handleClick: (tab: string) => void;
  disabled: boolean;
  children: React.ReactNode;
}

const Tab: React.FC<ITabProps> = ({ selected, tab, handleClick, disabled, children }) => (
  <button
    disabled={disabled}
    onClick={() => handleClick(tab)}
    className={`tab ${selected ? 'tab--selected' : ''} ${disabled ? 'tab--disabled' : ''}`}
  >
    {children}
  </button>
);

export default Tab;
