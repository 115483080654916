// @ts-ignore
import Fuse from 'fuse.js';
import * as React from 'react';
import { connect } from 'react-redux';

import { Spinner } from '@fluentui/react/lib/Spinner';
import { createId } from '@paralleldrive/cuid2';
import { ActionButton, DefaultButton } from '../../../ActionButton/ActionButton';
import ResultLabel from '../ResultLabel';

import { IH2020Project, IPartnerSearchResult } from '../../../../app/PartnerSearch/actions';
import { IPartnerSearchState } from '../../../../app/PartnerSearch/reducer';
import { selectProject } from '../../../../app/Projects/actions';
import { IState } from '../../../../reducers';
import { containsValue } from '../../../../utils/filter';
import { convertToCurrency, convertToPercent } from '../../../../utils/numberConverter';
import ProjectDialog from '../ProjectResult/ProjectDialog';
import PartnerDialog from './PartnerDialog';

import { isTrialUser } from '../../../../app/Auth/Auth';
import { removeSavedDocumentRequest, saveDocumentRequest } from '../../../../app/Saved/actions';
import { ISavedState } from '../../../../app/Saved/reducer';
import { selectableTabs } from '../../../../app/Search/actions';
import { store } from '../../../../store';
import { Tooltip } from '../../../Tooltip';
import { SearchResultDetail } from '../SearchResultDetail';
import '../SearchResults.css';
import './PartnerResults.css';

interface IProps {
  partners: IPartnerSearchState;
  searchResults?: any;
  saved?: ISavedState;
}

const ShowingResultsForCall: React.FC<IProps> = ({ partners }) => (
  <div className="search-results__showing_call">
    {partners && <p>Showing results for call {partners.callTitle}</p>}
  </div>
);

const PartnersResults: React.FC<IProps> = ({ partners, searchResults, saved }) => {
  const filterCondition = (item: any) => {
    const { filterConditions } = searchResults;
    return containsValue(item, filterConditions);
  };

  const sortCondition = (a: any, b: any) => {
    return sortProjectsByRelevance(b.projects)[0].weight - sortProjectsByRelevance(a.projects)[0].weight;
  };

  const sortByName = (list: any[]): any[] => {
    const { orderCondition, query } = searchResults;
    if (orderCondition.key !== 'name') {
      return list;
    }
    const options = {
      keys: ['name'],
    };
    const fuse = new Fuse(list, options);
    return fuse.search(query);
  };

  const sortedPartners = sortByName(partners.results)
    .sort(sortCondition)
    .filter((item) => filterCondition(item));

  const loadingSpinnerStyling = {
    display: 'flex',
    height: '50vh',
    justifyContent: 'center',
  };

  return (
    <div className="search-results--container">
      <div className="search-results--content">
        {partners && partners.showingPartnersForCall && <ShowingResultsForCall partners={partners} />}

        {partners && partners.isFetching && (
          <div style={loadingSpinnerStyling}>
            <Spinner />
          </div>
        )}

        {partners && partners.results.length > 0 && !partners.isFetching && (
          <Group group={sortedPartners} key={createId()} saved={saved} />
        )}

        <PartnerDialog />

        <ProjectDialog />
      </div>
    </div>
  );
};

interface IProjectProps {
  project: IH2020Project;
  handleClick: (project: IH2020Project) => void;
}

const Project: React.FC<IProjectProps> = ({ project, handleClick }) => {
  const showCurrentModal = () => {
    handleClick(project);
    document.body.classList.add('preventModalScrolling');
  };

  return (
    <button className="search-results--project" onClick={showCurrentModal}>
      <div className="search-results--project__title">{project.title}</div>

      <div className="search-results--project__match">{convertToPercent(project.weight)} %</div>
    </button>
  );
};

interface IGroupProps {
  group: IPartnerSearchResult[];
  saved?: ISavedState;
}

const Group: React.FC<IGroupProps> = ({ group, saved }) => {
  const goToWebsite = (url: string) => {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    window.open(url);
  };

  const handleProjectClick = (project: IH2020Project) => {
    store.dispatch(selectProject(project));
  };

  const handleSave = (partner: IPartnerSearchResult) => {
    store.dispatch(
      saveDocumentRequest({
        scope: selectableTabs.PARTNERS,
        objectId: partner.id.toString(),
        title: partner.name,
      }),
    );
  };

  const handleUnsave = (partner: IPartnerSearchResult) => {
    store.dispatch(removeSavedDocumentRequest(partner.id.toString()));
  };

  const isSaved = (partner: IPartnerSearchResult) => {
    return (
      !!saved &&
      !!saved.savedDocuments.find(
        (obj: any) => obj.objectId === partner.id.toString() && obj.scope === selectableTabs.PARTNERS,
      )
    );
  };

  return (
    <div className="search-results--partner-group">
      {group.map((partner: IPartnerSearchResult, index: number) => {
        const { id, name, projects, city, country, activityType, totals } = partner;
        return (
          <div className="search-results--row" key={createId()}>
            {!isTrialUser() ? (
              <div className="search-results--first-column">
                <div className="search-results--result-name">{name}</div>

                <div className="search-results--partner-totals">
                  <div>
                    <table>
                      <tbody>
                        {Object.entries(totals).map(([role, { count, funding }]) => (
                          <tr key={`${id}-${role}`}>
                            <td>
                              <p>
                                Total projects as <strong>{role}</strong>:
                              </p>
                            </td>

                            <td className="numeric">
                              <strong>{count}</strong>
                            </td>

                            <td>
                              <p>with</p>
                            </td>

                            <td className="numeric">
                              <strong>{convertToCurrency(funding, 0)}</strong> funding
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="search-results--projects">
                  <p className="title">
                    <Tooltip
                      content={
                        <p>
                          This is the project in which, our AI has found it relevant to the text query in
                          question and from the listed organization. Please note, the organization could have
                          many other EU funded projects, but in order to facilitate the decison making, here
                          we only show the most relevant projects to your search topic.
                        </p>
                      }
                      position="topLeft"
                    />
                    Relevant projects:
                  </p>

                  <div>
                    {sortProjectsByRelevance(projects).map((project: IH2020Project) => {
                      return <Project key={createId()} project={project} handleClick={handleProjectClick} />;
                    })}
                  </div>
                </div>

                <div className="search-results--buttons search-results--buttons--reverse-row">
                  {isSaved(partner) ? (
                    <ActionButton text="Unsave" handleClick={() => handleUnsave(partner)} selected={true} />
                  ) : (
                    <ActionButton text="Save" handleClick={() => handleSave(partner)} />
                  )}

                  {partner.organizationUrl && (
                    <div>
                      <DefaultButton
                        text="Go to company website"
                        handleClick={() => goToWebsite(partner.organizationUrl)}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                {isTrialUser() && index < 3 ? (
                  <div className="search-results--first-column">
                    <div className="search-results--result-name">{name}</div>

                    <div className="search-results--partner-totals">
                      <div>
                        <table>
                          <tbody>
                            {Object.entries(totals).map(([role, { count, funding }]) => (
                              <tr key={`${id}-${role}`}>
                                <td>
                                  <p>
                                    Total projects as <strong>{role}</strong>:
                                  </p>
                                </td>

                                <td className="numeric">
                                  <strong>{count}</strong>
                                </td>

                                <td>
                                  <p>with</p>
                                </td>

                                <td className="numeric">
                                  <strong>{convertToCurrency(funding, 0)}</strong> funding
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="search-results--projects">
                      <p className="title">
                        <Tooltip
                          content={
                            <p>
                              This is the project in which, our AI has found it relevant to the text query in
                              question and from the listed organization. Please note, the organization could
                              have many other EU funded projects, but in order to facilitate the decison
                              making, here we only show the most relevant projects to your search topic.
                            </p>
                          }
                          position="topLeft"
                        />
                        Relevant projects:
                      </p>

                      <div>
                        {sortProjectsByRelevance(projects).map((project: IH2020Project) => {
                          return (
                            <Project key={createId()} project={project} handleClick={handleProjectClick} />
                          );
                        })}
                      </div>
                    </div>

                    <div className="search-results--buttons search-results--buttons--reverse-row">
                      {isSaved(partner) ? (
                        <ActionButton
                          text="Unsave"
                          handleClick={() => handleUnsave(partner)}
                          selected={true}
                        />
                      ) : (
                        <ActionButton text="Save" handleClick={() => handleSave(partner)} />
                      )}

                      {partner.organizationUrl && (
                        <div>
                          <DefaultButton
                            text="Go to company website"
                            handleClick={() => goToWebsite(partner.organizationUrl)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="search-results--subscribe">
                    <h1>
                      {'To see result details and unlock share feature please '}
                      <a className="" target="blank" href="https://spinbase.eu/pricing/">
                        {'upgrade your subcription'}
                      </a>
                    </h1>
                  </div>
                )}
              </div>
            )}
            <div className="search-results--second-column">
              <div className="search-results__details">
                <SearchResultDetail
                  value={`${convertToPercent(projects[0].weight)}%`}
                  label="Match"
                  highlight={true}
                  type="MATCH"
                  tooltip={{
                    content: (
                      <p>
                        Match %: This is the similarity score that the AI engine has given to each one of the
                        search results. It means that AI has read the whole text you provided as search query,
                        and analysed it word by word and then compared that with the texts associated with
                        each funding call topics in our entire database, and as a result it is recommending
                        that the higher the % is, there is a better match.
                      </p>
                    ),
                    position: 'topLeft',
                  }}
                />

                <SearchResultDetail
                  value={projects.length.toString()}
                  label={projects.length === 1 ? 'relevant project' : 'relevant projects'}
                  highlight={false}
                  type="RELEVANT_PROJECT"
                />
              </div>

              <div className="search-results--label-area">
                <ResultLabel
                  label="Location"
                  value={`${city}, ${country}`}
                  tooltip={{
                    content: <p>Location of the organization</p>,
                    position: 'topLeft',
                  }}
                />

                <div className="result-label__force-row">
                  <ResultLabel
                    label="Type"
                    value={activityType}
                    tooltip={{
                      content: <p>Type of the organization</p>,
                      position: 'topLeft',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const sortProjectsByRelevance = (projects: IH2020Project[]) => {
  return projects.sort((a: IH2020Project, b: IH2020Project) => {
    return b.weight - a.weight;
  });
};

const mapStateToProps = ({ partners, searchResults, saved }: IState) => {
  return { partners, searchResults, saved };
};

export default connect(mapStateToProps)(PartnersResults);
