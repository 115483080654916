import * as React from 'react';
import { connect } from 'react-redux';
import * as auth from '../../app/Auth/Auth';
import { fetchQueriesRequest } from '../../app/Query/actions';
import { fetchSavedDocumentsRequest } from '../../app/Saved/actions';
import { IState } from '../../reducers';
import { store } from '../../store';
import ExportCard from './ExportCard';
import PreviousQueries from './PreviousQueries/PreviousQueries';
import ProfileCard from './ProfileCard';
import './ProfilePage.css';
import ReminderSettings from './ReminderSettings';

// Disabled until national funding is approved for production deployment
// import SavedNationalTopics from './Saved/SavedNationalTopics';

import SavedPartners from './Saved/SavedPartners';
import SavedProjects from './Saved/SavedProjects';
import SavedTopics from './Saved/SavedTopics';

interface IPropsFromState {
  saved?: any;
}

export class ProfilePage extends React.Component<IPropsFromState> {
  componentDidMount() {
    this.fetchData();
    if (!auth.isAuthenticated()) {
      location.replace('/');
    }
  }

  fetchData() {
    store.dispatch(fetchQueriesRequest());
    store.dispatch(fetchSavedDocumentsRequest());
  }

  render() {
    const hasSavedData = this.props.saved.savedDocuments.length > 0;
    return (
      <div className="profile-page__container">
        <div className="profile-page__left-column">
          <ProfileCard />

          <ReminderSettings />

          <ExportCard hasSavedData={hasSavedData} />
        </div>

        <div className="profile-page__right-column">
          <SavedTopics />

          <SavedPartners />

          <SavedProjects />

          {/*
            Disabled until national funding is approved for production deployment
            Uncomment when National funding gets enabled
          */}
          {/* <SavedNationalTopics /> */}

          <PreviousQueries />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ saved }: IState) => {
  return { saved };
};

export default connect(mapStateToProps)(ProfilePage);
