import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { loadTheme } from '@fluentui/react/lib/Styling';

initializeIcons();
loadTheme({
  palette: {
    black: '#454647',
    neutralDark: '#5b5c5e',
    neutralLight: '#eaeaea',
    neutralLighter: '#f4f4f4',
    neutralLighterAlt: '#f8f8f8',
    neutralPrimary: '#2F3031',
    neutralPrimaryAlt: '#888a8b',
    neutralQuaternary: '#d0d0d0',
    neutralQuaternaryAlt: '#dadada',
    neutralSecondary: '#9fa1a2',
    neutralTertiary: '#b6b8b9',
    neutralTertiaryAlt: '#c8c8c8',
    themeDark: '#b4161b',
    themeDarkAlt: '#d51a20',
    themeDarker: '#851014',
    themeLight: '#fab8ba',
    themeLighter: '#fcd9da',
    themeLighterAlt: '#fef5f6',
    themePrimary: '#ED1C24',
    themeSecondary: '#ef363c',
    themeTertiary: '#f47378',
    white: '#fff',
  },
  fonts: {
    tiny: {
      fontFamily: "'Fira Sans', sans-serif",
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'anti-aliased',
      fontSize: '10px',
      fontWeight: 600,
    },
    xSmall: {
      fontFamily: "'Fira Sans', sans-serif",
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'anti-aliased',
      fontSize: '11px',
      fontWeight: 400,
    },
    small: {
      fontFamily: "'Fira Sans', sans-serif",
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'anti-aliased',
      fontSize: '12px',
      fontWeight: 400,
    },
    smallPlus: {
      fontFamily: "'Fira Sans', sans-serif",
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'anti-aliased',
      fontSize: '13px',
      fontWeight: 400,
    },
    medium: {
      fontFamily: "'Fira Sans', sans-serif",
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'anti-aliased',
      fontSize: '14px',
      fontWeight: 400,
    },
    mediumPlus: {
      fontFamily: "'Fira Sans', sans-serif",
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'anti-aliased',
      fontSize: '14px',
      fontWeight: 400,
    },
    large: {
      fontFamily:
        "'Raleway', 'Trebuchet MS', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'anti-aliased',
      fontSize: '17px',
      fontWeight: 300,
    },
    xLarge: {
      fontFamily: "'Fira Sans', sans-serif",
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'anti-aliased',
      fontSize: '21px',
      fontWeight: 100,
    },
    xxLarge: {
      fontFamily: "'Fira Sans', sans-serif",
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'anti-aliased',
      fontSize: '28px',
      fontWeight: 100,
    },
    superLarge: {
      fontFamily: "'Fira Sans', sans-serif",
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'anti-aliased',
      fontSize: '42px',
      fontWeight: 100,
    },
    mega: {
      fontFamily: "'Fira Sans', sans-serif",
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'anti-aliased',
      fontSize: '72px',
      fontWeight: 100,
    },
  },
});
