import * as React from 'react';
import classNames from 'classnames';
import './Checkbox.css'; // Import your checkbox CSS file

interface CheckboxProps {
  label: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked = false, onChange, disabled = false }) => {
  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <label className={classNames('checkbox', { 'checkbox--checked': checked, 'checkbox--disabled': disabled })}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        className="checkbox-input"
      />
      {label}
    </label>
  );
};

export default Checkbox;
