import * as React from 'react';
import '../../../components/Search/SearchResults/SearchResults.css';
import { isTrialUser } from '../../../app/Auth/Auth';

interface IFundingResultsProps {
  data: any;
  index: number;
}

export const FundingResults: React.FC<IFundingResultsProps> = ({ index, data }) => {
  return (
    <div className="search-results--row">
      {!isTrialUser() ? (
        <div className="search-results--first-column">
          <div className="search-results--result-name">{`${data?.callIdentifier[0]} ${data?.callTitle[0]}`}</div>
          <div className="search-results--result-description">{data?.summary}</div>
        </div>
      ) : (
        <div className="search-results--first-column">
          {isTrialUser() && index < 3 ? (
            <div>
              <div className="search-results--result-name">{`${data?.callIdentifier[0]} ${data?.callTitle[0]}`}</div>
              <div className="search-results--result-description">{data?.summary}</div>
            </div>
          ) : (
            <div className="search-results--subscribe">
              <div dangerouslySetInnerHTML={{ __html: data.descriptionByte[0] }} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
