/* Difficult files to refactor */
import { createId } from '@paralleldrive/cuid2';
import * as React from 'react';
import { connect } from 'react-redux';
import { clearSelectedProject } from '../../../../app/Projects/actions';
import { IProjectsState } from '../../../../app/Projects/reducer';
import { IState } from '../../../../reducers';
import { store } from '../../../../store';
import { DefaultButton } from '../../../ActionButton/ActionButton';
import { ContentDialog } from '../../../ContentDialog';
import './ProjectResults.css';

interface IProps {
  projects: IProjectsState;
}

class ProjectDialog extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  closeDialog() {
    store.dispatch(clearSelectedProject());
  }

  goToWebsite(url: string) {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    window.open(url);
  }

  participantsAsList(str: string) {
    return str.split(';');
  }

  render() {
    const { projects } = this.props;
    const { showProjectDialog, selectedProject } = projects;

    const hideCurrentModal = () => {
      this.closeDialog();
      document.body.classList.remove('preventModalScrolling');
    };

    if (!showProjectDialog) {
      return null;
    }
    return (
      <ContentDialog
        loading={{
          title: !selectedProject,
          content: !selectedProject,
          aside: !selectedProject,
        }}
        handleClose={hideCurrentModal}
        title={selectedProject.title}
        content={
          <>
            <h3>Project objective</h3>

            <p>{selectedProject.objective}</p>

            <h3>Project coordinator</h3>

            <p>
              {selectedProject.coordinator} ({selectedProject.coordinatorCountry})
            </p>
          </>
        }
        aside={
          <div className="project-information">
            <h3>Project information</h3>

            <p>
              <strong>Status</strong>
            </p>
            <p className="value">{selectedProject.status}</p>

            <p>
              <strong>Project started</strong>
            </p>
            <p className="value">{selectedProject.startDate}</p>

            <p>
              <strong>Project ended</strong>
            </p>
            <p className="value">{selectedProject.endDate}</p>

            {selectedProject.participants && (
              <>
                <p>
                  <strong>Participants</strong>
                </p>

                <ul>
                  {this.participantsAsList(selectedProject.participants).map((participant: string) => (
                    <li key={createId()}>
                      <p className="value">{participant}</p>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {selectedProject.projectUrl && (
              <div className="project-dialog__action-buttons">
                <DefaultButton
                  text="Go to project website"
                  handleClick={() => this.goToWebsite(selectedProject.projectUrl)}
                />
              </div>
            )}
          </div>
        }
      />
    );
  }
}

const mapStateToProps = ({ projects }: IState) => {
  return { projects };
};

export default connect(mapStateToProps)(ProjectDialog);
