import { getAccessToken } from '../app/Auth/Auth';

const callApi = async (method: string, url: string, path: string, data?: any) => {
  try {
    const res = await fetch(url + '/api' + path, {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return await res.json();
  } catch (error) {
    return { error };
  }
};

export default callApi;
