import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import { openExternalLinksInNewWindow, removeTableInlineStyles } from './utils';

interface IContentProps {
  loading: boolean;
  content: React.ReactNode;
}

export const Content = ({ loading, content }: IContentProps) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!loading) {
      openExternalLinksInNewWindow(ref);
      removeTableInlineStyles(ref);
    }
  }, [loading]);

  return (
    <div ref={ref} className="content-dialog__content">
      {loading ? <Loader /> : content}
    </div>
  );
};

const Loader = () => {
  return (
    <>
      <p>
        <Skeleton count={10} />
      </p>

      <p>
        <Skeleton count={5} />
      </p>

      <div className="topic__additional-data">
        <h2>
          <Skeleton height={20} />
        </h2>

        <h3>
          <Skeleton height={15} />
        </h3>

        <ul>
          <Skeleton count={5} wrapper={'li'} />
        </ul>

        <h3>
          <Skeleton height={15} />
        </h3>

        <ul>
          <Skeleton count={10} wrapper={'li'} />
        </ul>
      </div>
    </>
  );
};
