import * as React from 'react';
import { connect } from 'react-redux';
import './App.css';

import { getTrialDaysLeft, isTrialUser } from '../../app/Auth/Auth';
import { ReactComponent as BellIcon } from '../../assets/icon--bell.svg';

import { IState } from '../../reducers';
import { ISavedState } from '../../app/Saved/reducer';
import { ISettingsState } from '../../app/Settings/reducer';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { CalendarContextProvider } from '../Calendar/Context';
import { Calendar } from '../Calendar';

import './theme';

interface IRootProps {
  className?: string;
  children: React.ReactNode;
  user: auth0.Auth0UserProfile;
  saved: ISavedState;
  settings: ISettingsState;
}

class App extends React.Component<IRootProps> {
  constructor(props: IRootProps) {
    super(props);
  }

  render() {
    const { children } = this.props;
    return (
      <CalendarContextProvider saved={this.props.saved}>
        <div className="app" style={{ overflow: 'hidden' }}>
          <Header />

          {isTrialUser() && getTrialDaysLeft() > 0 && (
            <div className="trial_warning">
              <BellIcon className="bell_icon" />
              <label>
                <span>{`${getTrialDaysLeft()} `}</span>
                days left on your trial period
              </label>
            </div>
          )}

          <div className="app__content">{children}</div>

          <Footer />
        </div>

        <Calendar />
      </CalendarContextProvider>
    );
  }
}

const mapStateToProps = ({ user, saved, settings }: IState) => {
  return { user, saved, settings };
};
export default connect(mapStateToProps)(App);
