import { Spinner } from '@fluentui/react/lib/Spinner';
import * as React from 'react';
import { connect } from 'react-redux';
import { exportExcelRequest } from '../../../app/Export/actions';
import { IExportState } from '../../../app/Export/reducer';
import { IState } from '../../../reducers';
import { store } from '../../../store';
import './ExportCard.css';

interface IPropsFromState {
  hasSavedData: boolean;
  export: IExportState;
  exportExcelRequest: any;
}

class ExportCard extends React.Component<IPropsFromState> {
  fetchExcel() {
    store.dispatch(exportExcelRequest());
  }

  render() {
    if (!this.props.hasSavedData) {
      return null;
    }

    return (
      <div className="export-card">
        <h3>Export saved search results</h3>

        <p>Export your saved topics, partners and projects to an excel file.</p>

        {this.props.export.isExcelExporting ? <p>Generating excel...</p> : null}
        <div className="export-card__button-wrapper">
          <button
            className="action-button action-button__default"
            disabled={this.props.export.isExcelExporting}
            onClick={this.fetchExcel}
          >
            Export
          </button>

          {this.props.export.isExcelExporting ? <Spinner /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => {
  return { export: state.export };
};

const mapDispatchToProps = () => ({
  exportExcelRequest,
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportCard);
