export enum FundingActionTypes {
    FETCH_FUNDINGS = 'FETCH_FUNDINGS',
    FETCH_FUNDINGS_SUCCESS = 'FETCH_FUNDINGS_SUCCESS',
    FETCH_FUNDING_BY_REFERENCE = 'FETCH_FUNDING_BY_REFERENCE',
    FETCH_FUNDING_BY_REFERENCE_SUCCESS = 'FETCH_FUNDING_BY_REFERENCE_SUCCESS',
    FETCH_EXPORTS = 'FETCH_EXPORTS',
    FETCH_EXPORTS_SUCCESS = 'FETCH_EXPORTS_SUCCESS',
    CLEAR_FILTER = 'CLEAR_FILTER',
    SEARCH_REQUEST = 'SEARCH_REQUEST',
    SEARCH_REQUEST_REFERENCE = 'SEARCH_REQUEST_REFERENCE',
    EXPORT_REQUEST = 'EXPORT_REQUEST',
    SET_IS_EXPORTING = 'SET_IS_EXPORTING',
    SEARCH_REQUEST_FAILED = 'SEARCH_REQUEST_FAILED',
    SEARCH_REQUEST_REFERENCE_FAILED = 'SEARCH_REQUEST_REFERENCE_FAILED',
    EXPORT_REQUEST_ERROR = 'EXPORT_REQUEST_ERROR'
  }

  export const setIsExporting = (isExporting: boolean) => ({
    type: 'SET_IS_EXPORTING',
    payload: isExporting,
  });

  export const setSearchRequestFailed = (isSearchRequestFailed: boolean) => ({
    type: 'SEARCH_REQUEST_FAILED',
    payload: isSearchRequestFailed
  })

  export const setSearchRequestReferenceFailed = (isSearchRequestFailed: boolean) => ({
    type: 'SEARCH_REQUEST_REFERENCE_FAILED',
    payload: isSearchRequestFailed,
  })

  export const setExportRequestError = (error: boolean, loading: boolean) => ({
    type: 'EXPORT_REQUEST_ERROR',
    payload: error,
    loading: loading
  })
  
  // Action creator for successful funding fetch
  export const fetchFundings = (data: any[], totalPages: number, totalRecord: number, currentPage: number, pageSize: number, frameworkProgramme: any = [], status: any = []) => ({
    type: FundingActionTypes.FETCH_FUNDINGS_SUCCESS,
    payload: data,
    totalPage: totalPages,
    totalRecord: totalRecord,
    currentPage: currentPage,
    pageSize: pageSize,
    frameworkProgramme: frameworkProgramme,
    status: status
  });
  
  // Action creator for successful funding fetch by reference
  export const fetchFundingByReference = (data: any) => ({
    type: FundingActionTypes.FETCH_FUNDING_BY_REFERENCE_SUCCESS,
    payload: data,
  });

  export const fetchExports = (data: any[]) => ({
    type: FundingActionTypes.FETCH_EXPORTS_SUCCESS,
    payload: data,
  });

  export const searchRequestFundingReference = (payload: {
    reference: string;
  }) => ({
    type: FundingActionTypes.SEARCH_REQUEST_REFERENCE,
    payload,
  })
 
  // Action creator for searching with filters
  export const searchRequestFundings = (payload: {
    frameworkProgramme: string;
    status: string;
    limit: number;
    offset: number;
  }) => ({
    type: FundingActionTypes.SEARCH_REQUEST,
    payload,
  });

  export const exportRequest = (payload: {
    frameworkProgramme: string,
    status: string;
  }) => ({
    type: FundingActionTypes.EXPORT_REQUEST,
    payload,
  });
  