import * as React from 'react';
import { connect } from 'react-redux';
import { clearSelectedPartner, IH2020Project } from '../../../../app/PartnerSearch/actions';
import { IPartnerSearchState } from '../../../../app/PartnerSearch/reducer';
import { DefaultButton } from '../../../ActionButton/ActionButton';
import { store } from '../../../../store';
import { ContentDialog } from '../../../ContentDialog';
import './PartnerResults.css';

interface IProps {
  partners: IPartnerSearchState;
}

class PartnerDialog extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  closeDialog() {
    store.dispatch(clearSelectedPartner());
  }

  goToWebsite(url: string) {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    window.open(url);
  }

  render() {
    const { partners } = this.props;
    const { selectedPartner: partner, showPartnerDialog } = partners;

    const hideCurrentModal = () => {
      this.closeDialog();
      document.body.classList.remove('preventModalScrolling');
    };

    if (!showPartnerDialog) {
      return null;
    }

    return (
      <ContentDialog
        loading={{
          title: false,
          content: !partners || !partner,
          aside: false,
        }}
        handleClose={hideCurrentModal}
        title={partner.name}
        content={
          <>
            <h3>{partner.activityType}</h3>
            <p>Partner information:</p>
            <ul>
              <li>
                Location: {partner.city}, {partner.country}
              </li>

              <li>Role: {partner.role}</li>

              {partner.projects && partner.projects.length > 0 && (
                <>
                  <li>
                    <strong>Coordinating projects</strong>
                    <ul>
                      {partner.projects.map((project: IH2020Project, i: number) => (
                        <li key={project.title + i}>{project.title}</li>
                      ))}
                    </ul>
                  </li>
                </>
              )}
            </ul>

            <div className="partner-dialog__action-buttons">
              {partner.organizationUrl && (
                <DefaultButton text="Website" handleClick={() => this.goToWebsite(partner.organizationUrl)} />
              )}
            </div>
          </>
        }
        aside={null}
      />
    );
  }
}

const mapStateToProps = ({ partners }: any) => {
  return { partners };
};

export default connect(mapStateToProps)(PartnerDialog);
