import { FontWeights, IIconProps, Modal, getTheme, mergeStyleSets } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import { createId } from '@paralleldrive/cuid2';
import * as React from 'react';
import { connect } from 'react-redux';
import { ISearchState } from '../../../../app/Search/reducer';
import { IState } from '../../../../reducers';

interface ISearchResultsProps {
  searchResults: ISearchState;
  topicID: string;
}

interface ITopicOrganization {
  name: string;
  type: string;
  summary: string;
  country: string;
}

interface ITopicOrganizationsProps {
  topic_organizations: ITopicOrganization[];
  number_of_topics: number;
}

interface ITopicOrganizationsState {
  topic_organizations_collapsed: boolean;
  viewModal: boolean;
}

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    margin: '100px',
  },
  header: [
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
const ViewTopicOrganization: React.FC<ITopicOrganizationsProps> = ({
  topic_organizations,
  number_of_topics,
}: ITopicOrganizationsProps) => {
  const topicOrganization = [];
  for (let i = 0; i < number_of_topics && topic_organizations.length > i; i++) {
    let topicOrganizationSummary = topic_organizations[i].summary;
    const urlRegexp =
      /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/i;
    const emailRegexp =
      /\b(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\b/;

    const urlRegexpMatch = topicOrganizationSummary && topicOrganizationSummary.match(urlRegexp);

    if (urlRegexpMatch) {
      const urlContactDetail = urlRegexpMatch[0];
      let newUrlContactDetail = urlContactDetail;

      if (!/^https?:\/\//i.test(urlContactDetail)) {
        newUrlContactDetail = 'http://' + urlContactDetail;
      }

      topicOrganizationSummary = topicOrganizationSummary.replace(
        urlContactDetail,
        `<a href=${newUrlContactDetail}>${urlContactDetail}</a>`,
      );
    }

    const emailRegexpMatch = topicOrganizationSummary && topicOrganizationSummary.match(emailRegexp);
    let emailContactDetail;
    if (emailRegexpMatch) {
      emailContactDetail = emailRegexpMatch[0];
      topicOrganizationSummary = topicOrganizationSummary.replace(emailContactDetail, 'N/A');
    }
    topicOrganization.push(
      <li style={{ margin: '10px 0', listStyleType: 'none' }} key={createId()}>
        <div>
          <b>
            {topic_organizations[i].name} - {topic_organizations[i].country}
          </b>
        </div>

        <div style={{ fontWeight: 600 }}>{topic_organizations[i].type}</div>

        <div dangerouslySetInnerHTML={{ __html: topicOrganizationSummary }} />
      </li>,
    );
  }
  return <React.Fragment>{topicOrganization}</React.Fragment>;
};

class TopicOrganizations extends React.Component<ISearchResultsProps, ITopicOrganizationsState> {
  constructor(props: ISearchResultsProps) {
    super(props);
    this.state = {
      topic_organizations_collapsed: true,
      viewModal: false,
    };
  }

  render() {
    const {
      topicID,
      searchResults: { selectedItems },
    } = this.props;
    const selectedItem = selectedItems.find((findSelectedItem) => findSelectedItem.id === topicID);
    return (
      <div className="topic-organizations">
        {selectedItem && selectedItem.topic_organizations && selectedItem.topic_organizations.length && (
          <ul>
            <ViewTopicOrganization
              topic_organizations={selectedItem.topic_organizations}
              number_of_topics={5}
            />

            <div
              onClick={(e) => {
                e.preventDefault();
                this.setState({ viewModal: true });
              }}
              style={{ cursor: 'pointer' }}
            >
              <em style={{ textDecoration: 'underline' }}>link to view more partners</em>
            </div>

            {this.state.viewModal && (
              <Modal
                isOpen={this.state.viewModal}
                onDismiss={(e) => this.setState({ viewModal: false })}
                isBlocking={false}
                containerClassName={contentStyles.container}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className={contentStyles.header}
                >
                  <span id={'1'}>
                    Interested organizations, as they are mentioned in EU open access databases (SEDIA)
                  </span>

                  <IconButton
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ viewModal: false });
                    }}
                  />
                </div>

                <div className={contentStyles.body}>
                  <ViewTopicOrganization
                    topic_organizations={selectedItem.topic_organizations}
                    number_of_topics={selectedItem.topic_organizations.length}
                  />
                </div>
              </Modal>
            )}
          </ul>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ searchResults }: IState) => {
  return { searchResults };
};

export default connect(mapStateToProps)(TopicOrganizations);
