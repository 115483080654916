import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import callApi from '../../utils/callApi';
import * as actions from './actions';
import { isTrialUser } from '../Auth/Auth';

function* handleFetch(action: any): any {
  const param = {
    searchCategory: 'national',
    source_name: action.payload.source_name
      ? action.payload.source_name.join(',')
      : 'Research.fi,Business Finland',
    limit: action.payload.limit,
    offset: action.payload.offset,
    isTrialUser: isTrialUser(),
  };

  try {
    const res = yield call(callApi, 'post', process.env.REACT_APP_SEARCH_API_URL || '', `/v2/search`, param);
    if (res) {
      if (res.description) {
        throw new Error(res.description);
      }
      if (res.error) {
        throw new Error(res.error);
      }
      switch (action.type) {
        case '@@nationalSearch/REQUEST': {
          yield put(actions.nationalSearchResponse(res));
          break;
        }
        case '@@nationalSearch/MORE_REQUEST': {
          yield put(actions.nationalSearchMoreResponse(res));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.nationalSearchError(err.message));
    } else {
      yield put(actions.nationalSearchError('An unknown error occurred.'));
    }
  }
}

function* handleFetchTopicDescription(action: any): any {
  const { payload } = action;
  const { topicId, query } = payload;
  const data = { query_data: query, searchCategory: 'national' };
  try {
    const res = yield call(
      callApi,
      'POST',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v2/search/documents/${topicId}`,
      data,
    );

    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      }
      switch (action.type) {
        case actions.NationalSearchActionTypes.FETCH_TOPIC_DESCRIPTION_REQUEST: {
          yield put(actions.fetchNationalTopicDescriptionSuccess(res));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.nationalSearchError(err.message));
    } else {
      yield put(actions.nationalSearchError('An unknown error occured.'));
    }
  }
}

function* watchNationalSearchRequest() {
  yield takeEvery(actions.NationalSearchActionTypes.NATIONAL_SEARCH_REQUEST, handleFetch);
}

function* watchNationalSearchMoreRequest() {
  yield takeEvery(actions.NationalSearchActionTypes.NATIONAL_SEARCH_MORE_REQUEST, handleFetch);
}

function* fetchTopicDescription() {
  yield takeEvery(
    actions.NationalSearchActionTypes.FETCH_TOPIC_DESCRIPTION_REQUEST,
    handleFetchTopicDescription,
  );
}

function* nationalSearchSaga() {
  yield all([
    fork(watchNationalSearchRequest),
    fork(watchNationalSearchMoreRequest),
    fork(fetchTopicDescription),
  ]);
}

export default nationalSearchSaga;
