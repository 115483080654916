import * as React from 'react';
import { connect } from 'react-redux';

import { IState } from '../../reducers';

import './ProfilePage.css';

interface IPropsFromState {
  user: auth0.Auth0UserProfile;
}

const ProfileCard: React.FC<IPropsFromState> = ({ user }) => {
  return (
    <div className="profile-card">
      <img className="profile-card__image" src={user.picture} alt="" />
      <div className="profile-card__email">
        <p>
          <strong>Username: </strong>
          {user.email}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }: IState) => {
  return { user };
};

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);
