import * as React from 'react';

import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <div className="footer--legal">
        <p>
          <a target="_blank" href="https://spinba.se/Funding_Search_Trial_License_Agreement.pdf">
            Terms of service
          </a>
        </p>

        <p>
          <a
            target="_blank"
            href="https://spinbase.eu/wp-content/uploads/2024/01/Spinverse-Privacy-Policy-11-2023.pdf"
          >
            Privacy notice
          </a>
        </p>
      </div>

      <p>Spinbase service is partly based on information from European Union open data.</p>
    </footer>
  );
};

export default Footer;
