import * as React from 'react';
import * as cn from 'classnames';
import './ExpandableSection.css';
import { ReactComponent as IconArrowDown } from '../../../assets/profile--page--icon--arrow--down.svg';
import { ReactComponent as IconArrowUp } from '../../../assets/profile--page--icon--arrow--up.svg';

interface IExpandableSectionProps {
  title: string;
  count: number;
  children: React.ReactNode;
  inner?: boolean;
}

export const ExpandableSection = ({ title, count, children, inner = false }: IExpandableSectionProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      className={cn('expandable-section', {
        'expandable-section--expanded': isOpen,
        'expandable-section--inner': inner,
      })}
    >
      <button className="expandable-section__header" onClick={() => setIsOpen(!isOpen)}>
        <div className="title">{title}</div>

        {count > 0 ? <div className="count">{count}</div> : null}

        <Icon inner={inner} />
      </button>

      <div className="expandable-section__content">{children}</div>
    </div>
  );
};

const Icon = ({ inner = false }: { inner?: boolean }) => {
  return inner ? <IconArrowUp /> : <IconArrowDown />;
};
