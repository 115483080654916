import * as React from 'react';
import './Tooltip.css';
import { TooltipAlignment, useTooltip } from './useTooltip';

export { useTooltip };
export type { TooltipAlignment as TooltipPosition };

export interface ITooltipProps {
  content: React.ReactNode;
  position?: TooltipAlignment;
}

export const Tooltip = ({ content, position = 'bottom' }: ITooltipProps) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const { toggleTooltip, tooltip } = useTooltip({
    ref,
    content,
    align: position,
  });

  const handleClick = React.useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      toggleTooltip();
    },
    [toggleTooltip],
  );

  const handleBodyClick = React.useCallback(() => toggleTooltip(false), []);

  React.useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);

    return () => document.body.removeEventListener('click', handleBodyClick);
  }, [handleBodyClick]);

  return (
    <button
      ref={ref}
      className="tooltip"
      onClick={handleClick}
      onMouseEnter={handleClick}
      onMouseLeave={handleBodyClick}
    >
      <TooltipIcon />
      {tooltip}
    </button>
  );
};

const TooltipIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
      <path
        d="M12.63 0a12.629 12.629 0 1012.628 12.63A12.628 12.628 0 0012.63 0zm0 23.2A10.571 10.571 0 1123.2 12.629 10.568 10.568 0 0112.63 23.2z"
        fill="currentColor"
      />
      <path
        d="M14.394 9.53h-3.55v.006H9.522V11.6h1.321v7.37h-1.4v1.972h1.4v.014h3.55v-.014h1.161V18.97h-1.16z"
        fill="currentColor"
      />
      <path d="M12.594 8.125a1.778 1.778 0 10.048-3.548 1.777 1.777 0 10-.048 3.548z" fill="currentColor" />
    </svg>
  );
};
