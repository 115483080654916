import { Reducer } from 'redux';
import { SavedActionTypes } from './actions';

export interface ISaved {
  documentId: string;
  scope: string;
  title: string;
  deadline?: string;
}

export interface ISavedState {
  loading: boolean;
  savedDocuments: ISaved[];
}

export const initialState: ISavedState = {
  loading: false,
  savedDocuments: [],
};

const savedReducer: Reducer<ISavedState> = (state = initialState, action): ISavedState => {
  switch (action.type) {
    case SavedActionTypes.FETCH_SAVED_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case SavedActionTypes.FETCH_SAVED_SUCCESS: {
      return {
        ...state,
        loading: false,
        savedDocuments: action.payload,
      };
    }
    case SavedActionTypes.SAVE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        savedDocuments: [...state.savedDocuments, action.payload],
      };
    }
    default:
      return state;
  }
};

export { savedReducer };
