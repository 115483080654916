import * as React from 'react';
import { store } from '../../../store';
import { CheckboxGroup } from '../../Search/SearchResults/Filter/CheckboxGroup';
import { searchRequestFundings, exportRequest, setIsExporting } from '../../../app/Fundings/actions';
import { useSelector } from 'react-redux';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { exportToExcel } from '../../../utils/excelExport';
import IconArrowRigh from '../../../assets/icon--arrow--right.svg';


const statuses = [
  { key: 'topic_status', value: '31094501', label: 'Forthcoming' },
  { key: 'topic_status', value: '31094502', label: 'Open' },
  { key: 'topic_status', value: '31094503', label: 'Closed' },
];

const frameworkProgramme = [
  { key: 'framework_programme', value: '43108390', label: 'Horizon Europe (HORIZON)' },
  { key: 'framework_programme', value: '43251567', label: 'Connecting Europe Facility (CEF)' },
  { key: 'framework_programme', value: '43251814', label: 'Creative Europe Programme (CREA)' },
  { key: 'framework_programme', value: '43152860', label: 'Digital Europe Programme (DIGITAL' },
  { key: 'framework_programme', value: '43332642', label: 'EU4Health Programme (EU4H)' },
  { key: 'framework_programme', value: '45532249', label: 'EU Bodies and Agencies (EUBA)' },
  { key: 'framework_programme', value: '43252476', label: 'Single Market Programme (SMP)' },
  { key: 'framework_programme', value: '43353764', label: 'Erasmus+ (ERASMUS+)' },
  {
    key: 'framework_programme',
    value: '44416173',
    label: ' Interregional Innovation Investments Instrument (I3)',
  },
];

const Filter: React.FC = () => {
  const [callProgramme, setCallProgramme] = React.useState<string[]>(['43108390']);
  const [callStatus, setCallStatus] = React.useState<string[]>(['31094501', '31094502']);
  const exportData = useSelector((state: any) => state.funding.exportResults);
  const limit: number = 10;
  const offset: number = 0;
  const isExporting = useSelector((state: any) => state.funding.isExporting);
  const isloading = useSelector((state: any) => state.funding.loading);

  const handleCallProgrammeChange = (value: any) => {
    setCallProgramme((prev) => {
      const updatedProgramme = prev.includes(value) ? prev.filter((status) => status !== value) : [value];
      applyFilters(updatedProgramme, callStatus);
      return updatedProgramme;
    });
  };

  const handleCallStatusChange = (value: any) => {
    setCallStatus((prev) => {
      const updatedStatus = prev.includes(value)
        ? prev.filter((status) => status !== value)
        : [...prev, value];
      applyFilters(callProgramme, updatedStatus);
      return updatedStatus;
    });
  };

  React.useEffect(() => {
    applyFilters(callProgramme, callStatus);
  }, []);

  const handleExport = (updatedProgramme: string[], updatedStatus: string[]) => {
    store.dispatch(setIsExporting(true));
    store.dispatch(
      exportRequest({
        frameworkProgramme: JSON.stringify(updatedProgramme),
        status: JSON.stringify(updatedStatus),
      }),
    );
  };

  const applyFilters = (updatedProgramme: string[], updatedStatus: string[]) => {
    store.dispatch(
      searchRequestFundings({
        frameworkProgramme: JSON.stringify(updatedProgramme),
        status: JSON.stringify(updatedStatus),
        limit: limit,
        offset: offset,
      }),
    );
  };

  React.useEffect(() => {
    if (exportData && exportData.length > 0) {
      const processData = exportData.map((item: any) =>
        Object.entries(item).reduce((acc: any, [key, value]) => {
          acc[key] = Array.isArray(value) ? JSON.stringify(value) : value;
          return acc;
        }, {}),
      );
      exportToExcel(processData);
      store.dispatch(setIsExporting(false));
    }
  }, [exportData]);

  const filterableItems = [
    {
      value: 'call_programme_description',
      name: 'Call programme',
      items: frameworkProgramme,
      onChange: handleCallProgrammeChange,
      selectedItem: callProgramme,
    },
    {
      value: 'call_status',
      name: 'Call status',
      items: statuses,
      onChange: handleCallStatusChange,
      selectedItem: callStatus,
    },
  ];

  const clearFilters = () => {
    setCallProgramme([]);
    setCallStatus([]);
  };

  return (
    <div
      className="result-filter__container"
      style={isExporting || isloading ? { opacity: 0.75, pointerEvents: 'none' } : {}}
    >
      <div className="result-filter">
        <p className="filter-group-label">Filter by</p>
        {filterableItems.map((filterable: any) => (
          <CheckboxGroup
            key={`${filterable.name}_${filterable.value}`}
            label={filterable.name}
            name={filterable.name}
            onChange={filterable.onChange}
            options={filterable.items}
            value={filterable.selectedItem}
            hasShowMore={filterable.name === 'Call status' ? false : true}
          />
        ))}
        {callProgramme.length > 0 || callStatus.length > 0 ? (
          <div className="result-filter__filter-item">
            <button className="result-filter__clear-button" onClick={clearFilters}>
              Reset filters
            </button>
          </div>
        ) : null}
        {callProgramme.length > 0 || callStatus.length > 0 ? (
          <div className="result-filter__filter-item">
            <p className="filter-group-label">Export all</p>
            
            {!isExporting ? (
              <div className="export-all">
                <img src={IconArrowRigh} alt="next" className='export-icon' />
                <li onClick={() => handleExport(callProgramme, callStatus)} className='dropdown__item'>Export as excel (.xlsx)</li>
              </div>
              
            ) : (
              <Spinner />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Filter;
