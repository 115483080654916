import * as React from 'react';
import Collapsible from '../../Collapsible/Collapsible';
import TopicInformation from '../TopicInformation';
import TopicOrganizations from '../TopicOrganizations';
import { HighlightLegend } from './HighlightLegend';

interface ISearchResultAsideProps {
  topic: {
    id: any;
    metadata: {
      title: string;
      identifier: string;
      description: string;
      topic_url: string;
      call_url: string;
      call_programme: string;
      call_identifier: string;
      call_title: string;
      call_status: string;
      topic_status: string;
      call_programme_description: string;
      publication_date: string;
      planned_opening_date: string;
      deadline1: string;
      tags: string[];
      keywords: string[];
      summary_terms: string[];
      budgets_from_call: any[];
      budget_groups: any[];
    };
  };
}

interface CollapseToggleType {
  [key: string]: boolean;
}

export const SearchResultAside = (props: ISearchResultAsideProps) => {
  const [collapseTopicOrganization, setCollapseTopicOrganization] = React.useState(false);
  const [collapseTopicInfo, setCollapseTopicInfo] = React.useState(false);
  const [collapseTopicKeywords, setCollapseTopicKeywords] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState<CollapseToggleType>({
    tags: true,
    keywords: true,
    summaryTerms: true,
    budgetGroups: true,
  });

  const collapseToggle = (key: string) => {
    setCollapsed((getCollapsedStatus) => ({
      ...getCollapsedStatus,
      [key]: !getCollapsedStatus[key],
    }));
  };

  return (
    <>
      <div className="topic__info">
        <div className="topic__info__basic">
          <h3>Topic information:</h3>

          <TopicInformation topicMetadata={props.topic.metadata} />
        </div>

        <Collapsible
          children={<TopicOrganizations topicID={props.topic.id} />}
          setState={setCollapseTopicOrganization}
          state={collapseTopicOrganization}
          label={'Interested organizations, as they are mentioned in EU open access databases (SEDIA)'}
        />

        <Collapsible
          children={
            <ul>
              {props.topic.metadata.tags.map((tag: string, i: number) =>
                i > 4 && collapsed.tags ? '' : <li key={tag} dangerouslySetInnerHTML={{ __html: tag }} />,
              )}

              {props.topic.metadata.tags.length > 5 && (
                <button className="action-button" onClick={() => collapseToggle('tags')}>
                  {collapsed.tags ? 'Show more' : 'Show less'}
                </button>
              )}
            </ul>
          }
          setState={setCollapseTopicInfo}
          state={collapseTopicInfo}
          label={'Topic tags'}
        />

        <Collapsible
          children={
            <div className="topic-keywords">
              <ul>
                {props.topic.metadata.keywords.map((keyword: string, i: number) =>
                  i > 4 && collapsed.keywords ? (
                    ''
                  ) : (
                    <li key={keyword} dangerouslySetInnerHTML={{ __html: keyword }} />
                  ),
                )}

                {props.topic.metadata.keywords.length > 5 && (
                  <button className="action-button" onClick={() => collapseToggle('keywords')}>
                    {collapsed.keywords ? 'Show more' : 'Show less'}
                  </button>
                )}
              </ul>
            </div>
          }
          setState={setCollapseTopicKeywords}
          state={collapseTopicKeywords}
          label={'Topic keywords'}
        />

        <HighlightLegend />
      </div>
    </>
  );
};
