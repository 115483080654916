import { action } from 'typesafe-actions';
import { ISettings } from './types';

export const enum SettingsActionTypes {
  GET_SETTINGS = '@@settings/GET_SETTINGS',
  SET_SETTINGS = '@@settings/SET_SETTINGS',
  UPDATE_SETTINGS = '@@settings/UPDATE_SETTINGS',
}

export const setSettings = (settings: ISettings) => action(SettingsActionTypes.SET_SETTINGS, settings);
export const getSettings = () => action(SettingsActionTypes.GET_SETTINGS);
export const updateSettings = (settings: Partial<ISettings>) =>
  action(SettingsActionTypes.UPDATE_SETTINGS, settings);
