import * as React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { store } from './store';

import history from './history';

import Main from './Main';

unregisterServiceWorker();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<Main store={store} history={history} />);
