import { Reducer } from 'redux';
import { PartnerSearchActionTypes } from './actions';

export interface IPartnerSearchState {
  results: any[];
  groups: any[];
  error: any;
  isFetching: boolean;
  showPotentialPartners: boolean;
  showingPartnersForCall: boolean;
  callTitle: string;
  selectedPartner: any;
  showPartnerDialog: boolean;
}

export const initialState: IPartnerSearchState = {
  results: [],
  groups: [],
  error: '',
  isFetching: false,
  showPotentialPartners: false,
  showingPartnersForCall: false,
  callTitle: '',
  selectedPartner: {},
  showPartnerDialog: false,
};

const partnerSearchReducer: Reducer<IPartnerSearchState> = (
  state = initialState,
  action,
): IPartnerSearchState => {
  switch (action.type) {
    case PartnerSearchActionTypes.PARTNER_SEARCH_REQUEST: {
      return { ...state, isFetching: true };
    }
    case PartnerSearchActionTypes.PARTNER_SEARCH_SUCCESS: {
      return {
        ...state,
        results: [...action.payload],
        isFetching: false,
      };
    }
    case PartnerSearchActionTypes.PARTNER_SEARCH_ERROR: {
      return { ...state, error: action.payload, isFetching: false };
    }
    case PartnerSearchActionTypes.SHOW_POTENTIONAL_PARTNERS: {
      return { ...state, showPotentialPartners: true };
    }
    case PartnerSearchActionTypes.HIDE_POTENTIONAL_PARTNERS: {
      return { ...state, showPotentialPartners: false };
    }
    case PartnerSearchActionTypes.SEARCH_BY_CALL_DESCRIPTION: {
      return {
        ...state,
        callTitle: action.payload,
        showingPartnersForCall: true,
      };
    }
    case PartnerSearchActionTypes.CLEAR_CALL_QUERY: {
      return { ...state, callTitle: '', showingPartnersForCall: false };
    }
    case PartnerSearchActionTypes.SELECT_PARTNER: {
      return {
        ...state,
        selectedPartner: action.payload,
        showPartnerDialog: true,
      };
    }
    case PartnerSearchActionTypes.CLEAR_SELECTED_PARTNER: {
      return { ...state, selectedPartner: {}, showPartnerDialog: false };
    }
    case PartnerSearchActionTypes.FETCH_PARTNER_REQUEST: {
      return { ...state, isFetching: true };
    }
    case PartnerSearchActionTypes.FETCH_PARTNER_SUCCESS: {
      return {
        ...state,
        selectedPartner: action.payload,
        showPartnerDialog: true,
      };
    }
  }
  return state;
};

export { partnerSearchReducer };
