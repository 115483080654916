import type { Moment } from 'moment';
import * as moment from 'moment';
import * as React from 'react';

interface IProps {
  selectedItems: any;
  topicDescription: any;
  setExportClicked: any;
}

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}

const getNextDeadline = (dates: Date[]): Moment => {
  const currentDate = moment();
  let nextDeadline = moment(dates[0]);
  for (const dateStr of dates) {
    const date = moment(dateStr, 'DD.MMMM.YYYY');
    if (currentDate < date) {
      nextDeadline = date;
      break;
    }
  }
  return moment(nextDeadline);
};

const getBudgetYearMapForContent = (budgetGroups: any) => {
  if (budgetGroups && budgetGroups[0] && budgetGroups[0].budget_year_map) {
    const lis = [];
    const keys = Object.keys(budgetGroups[0].budget_year_map);
    for (const key of keys) {
      const value = budgetGroups[0].budget_year_map[key];
      lis.push(`${key} : ${value}`);
    }
    return lis.join();
  }
  return undefined;
};

export class ExportEUTopicsWholeText extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  componentDidMount() {
    this.createWholeTextWord();
  }

  createWholeTextWord() {
    const htmlHead = `
    <html>
    <head
        xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:w="urn:schemas-microsoft-com:office:word"
        xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
        xmlns="http://www.w3.org/TR/REC-html40"
      >
      </head>
      <meta charset='utf-8'>
      <body>
      <br>
      `;

    let menuContent: string = '';
    let tableOfContent: string = '';
    let preparedHTML: string = '';
    let htmlToDoc: string = '';

    this.props.selectedItems.map((topic: any, index: number) => {
      const getBudgetYearMap = (budgetGroups: any) => {
        if (budgetGroups && budgetGroups[0] && budgetGroups[0].budget_year_map) {
          const lis = [];
          const keys = Object.keys(budgetGroups[0].budget_year_map);
          for (const key of keys) {
            const value = budgetGroups[0].budget_year_map[key];
            lis.push(`<li>${key} : ${value}</li>`);
          }
          return lis.join();
        }
        return undefined;
      };

      tableOfContent += `
        <div style="
          margin-left: 8px;
          color: black;
        "
        >
          <h4>
            <a style="
              font-size: 11.0pt;
              color: black;
              text-decoration: none;
            "
            href="#${index}">${index + 1}. ${topic.title}</a>
          </h4>
          <div style="
            font-size: 10.0pt;
            margin-left: 12px;
            margin-top: 0px;
          ">
            <div style="
            ">
              <strong>Identifier: </strong>${topic.call_identifier}
            </div>
            <div style="
              margin-top: 6px;
            ">
              <strong>Deadline: </strong>${getNextDeadline(topic.deadline_dates).format('DD.MM.YYYY')}, 
              <strong>TRL: </strong>${topic.trl_text}, 
              <strong>Budget: </strong>${getBudgetYearMapForContent(topic.budget_groups)}
            </div>
          </div>
        </div>   
        `;

      menuContent = `
        <div 
          style="
            padding-top: 20px;
            "
          >
          <a style="
            color: darkblue;
            font-size: 16.0pt;
            "
            name="table-of-content"
          >
            Content
          </a>
          ${tableOfContent}
        </div>
      `;

      const callPageUrl = `https://ec.europa.eu/info/funding-tenders/opportunities/portal/screen/opportunities/topic-search;freeTextSearchKeyword=;typeCodes=0,1;statusCodes=31094501,31094502,31094503;programCode=null;programDivisionCode=null;focusAreaCode=null;crossCuttingPriorityCode=null;callCode=${topic.call_identifier};sortQuery=submissionStatus;orderBy=asc;onlyTenders=false;topicListKey=topicSearchTablePageState`;

      const deadline = getNextDeadline(topic.deadline_dates);

      const table = `
        <table 
        border=1
        cellspacing=0
        cellpadding=0
        style="
          font-family: Arial;
          font-size: 11.0pt;
          height: 150px;
          width: 600px;
          border-collapse:collapse;
          border:none;
          mso-border-alt:solid windowtext .5pt;"
        >
        <thead>
          <tr>
            <th style="
              font-size: 12.0pt;
              min-width: 100%;
              border: 1px solid black;
              text-align: left;
              color: white;
              background-color: darkcyan;"
              colspan="2"
            >
              Topic Information
            </th>
          </tr>
        </thead>
        <tbody>
            <tr >
              <td >Programme</td>
              <td>${topic.call_programme_description ? topic.call_programme_description : ''}</td>
            </tr>
            <tr>
              <td>Topic Status</td>
              <td>${topic.topic_status ? topic.topic_status : ''}</td>
            </tr>
            <tr>
              <td>Call identifier</td>
              <td>${topic.call_identifier ? topic.call_identifier : ''}</td>
            </tr>
            <tr>
              <td>Call title</td>
              <td>${topic.call_title ? topic.call_title : ''}</td>
            </tr>
            <tr>
              <td>Publication date</td>
              <td>${topic.publication_date ? topic.publication_date : ''}</td>
            </tr>
            <tr>
              <td>Opening date</td>
              <td>${topic.planned_opening_date ? topic.planned_opening_date : ''}</td>
            </tr>
            <tr>
              <td>Next deadline</td>
              <td>${moment(deadline).format('DD.MM.YYYY')}</td>
            </tr>
            <tr>
              <td>Budget of the topic</td>
              <td>
                <ul>
                  ${getBudgetYearMap(topic.budget_groups)}
                  <li>${
                    topic.budget_groups &&
                    topic.budget_groups[0] &&
                    topic.budget_groups[0].actions &&
                    topic.budget_groups[0].actions[0]
                  }</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>External link</td>
              <td>
                <div>
                  ${topic.topic_url ? `<a href=${topic.topic_url}>Topic Page</a> ` : ''}
                  ${`<a href=${callPageUrl}>Call Page</a>`}
                </div>
              </td>
            </tr>
          </tbody>
        </table><br>`;

      preparedHTML += `
      <div >
        <br style="page-break-after: always;">&nbsp;</br>
        <div style="
          margin-left: 10px;
          margin-right: 10px;"
          margin-bottom: 10px;"
        >
          <a
            name="${index}"
            style="
              font-family: Arial;
              font-size: 14.0pt;
              color: #8b0000;"
          >
            ${topic.title}
          </a>
          <br>
          <br>

          ${table}
          <div style="
            font-family: Arial;
            font-size: 11pt;
            text-align: justify;"
          >
            ${this.props.topicDescription[topic.id]}
          </div>
        </div>

        <br>
        <br>
        <br>
        <a style="
          margin-left: 8px;
          color: black;
          text-decoration: none;
          font-size: 11.0pt;
          "
          href='#table-of-content'
          >Back to table of content
          </a>

        <div>
          <hr>
          <br>
          <br>
          <br>
          <div style="
            padding: 6px 0px 6px 0px;
            color: black;
            font-size: 11px;
            "
          >
          This call can be found with Spinbase, the AI-powered tool to find EU funding call, partners and projects. Try it here:
          <a style="
            text-decoration: none;
            "
            href="https://spinbase.eu" target="blank">
            <img width="40" height="18" src="https://spinba.se/logo--spinbase.png">
          </a>
        </div>
      </div>
        `;
    });

    const htmlFoot = `</body></html>`;

    preparedHTML = preparedHTML.replace(
      /<(?:span|SPAN) class='topicdescriptionkind'>(.*?)<\/(?:span|SPAN)>(?::|\s:)/g,
      `<div class="topicdescriptionkind" style="
        color: white;
        font-weight: bold;
        background-color: darkcyan;
        font-size: 14pt;
        font-family: 'Arial';
      ">$1</div>`,
    );

    preparedHTML = preparedHTML.replace(/ExpectedOutcome/g, 'Expected Outcome');

    htmlToDoc = `${htmlHead}${menuContent}${preparedHTML}${htmlFoot}`;
    const blob = new Blob(['\ufeff', htmlToDoc], {
      type: 'application/msword',
    });

    const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(htmlToDoc);
    let filename: any;
    filename = filename ? filename + '.doc' : 'eu-funding.doc';
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }
    document.body.removeChild(downloadLink);
    this.props.setExportClicked(false);
  }

  render() {
    return <div />;
  }
}
