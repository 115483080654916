import * as React from 'react';

import { IState } from '../../../../../reducers';
import './SearchResult.css';

import { connect } from 'react-redux';

import { INationalSearchState } from '../../../../../app/NationalSearch/reducer';

import { fetchNationalTopicDescription } from '../../../../../app/NationalSearch/actions';
import { ContentDialog } from '../../../../ContentDialog';
import { NationalSearchResultAside } from './Aside';
import { NationalSearchResultMain } from './Main';
import { Share } from './Share';

interface INationalSearchResultProps {
  nationalSearchResults: INationalSearchState;
  topic: {
    id: any;
    metadata: {
      applicant_types: string[];
      application_terms: string;
      application_url: string;
      categories: string[];
      deadline_date: string;
      deadline_ongoing: boolean;
      description: string;
      eligible_countries: string[];
      foundation_name: string;
      foundation_url: string;
      funding_types: string[];
      keywords: string;
      opening_date: string;
      source: string | null;
      source_name: string;
      title: string;
      url: string;
    };
    summary: {
      keywords: string[];
      preview: string;
    };
  };
  onDismiss: () => any;
  fetchNationalTopicDescription: (v: any) => void;
}

class NationalSearchResult extends React.Component<INationalSearchResultProps> {
  constructor(props: INationalSearchResultProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: INationalSearchResultProps) {
    const {
      topic: nextPropsTopic,
      nationalSearchResults: { topicDescription: nextPropsTopicDescription },
    } = nextProps;
    return (
      nextPropsTopic !== undefined &&
      nextPropsTopic.id !== undefined &&
      nextPropsTopicDescription[nextPropsTopic.id] !== undefined
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: INationalSearchResultProps) {
    if (
      nextProps.topic &&
      this.props.topic &&
      !nextProps.nationalSearchResults.topicDescription[nextProps.topic.id] &&
      !nextProps.nationalSearchResults.isDescriptionFetching
    ) {
      this.props.fetchNationalTopicDescription({
        topicId: nextProps.topic.id,
        query: nextProps.nationalSearchResults.query,
      });
    }
  }

  render() {
    const { topic } = this.props;

    const hideCurrentModal = () => {
      this.props.onDismiss();
      document.body.classList.remove('preventModalScrolling');
    };

    return (
      <ContentDialog
        loading={{
          title: !topic || !('metadata' in topic),
          content:
            !topic ||
            !('metadata' in topic) ||
            !(topic.id in this.props.nationalSearchResults.topicDescription) ||
            this.props.nationalSearchResults.isClosedTopicsFetching,
          aside: !topic || !('metadata' in topic),
        }}
        handleClose={hideCurrentModal}
        title={topic.metadata ? topic.metadata.title.replace(/<(?:.|\n)*?>/gm, '') : ''}
        content={
          <NationalSearchResultMain nationalSearchResults={this.props.nationalSearchResults} topic={topic} />
        }
        aside={<NationalSearchResultAside topic={topic} />}
        asideAfter={<Share topicMetadata={topic.metadata} />}
      />
    );
  }
}
const mapStateToProps = ({ nationalSearchResults }: IState) => {
  return { nationalSearchResults };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchNationalTopicDescription: (data: any) => dispatch(fetchNationalTopicDescription({ ...data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(NationalSearchResult);
