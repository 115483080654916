import { Reducer } from 'redux';
import { ProjectsActionTypes, IProjectResult } from './actions';

export interface IProjectsState {
  results: IProjectResult[];
  loading: boolean;
  showProjectDialog: boolean;
  selectedProject: any;
}

export const initialState: IProjectsState = {
  results: [],
  loading: false,
  showProjectDialog: false,
  selectedProject: {},
};

const projectsReducer: Reducer<IProjectsState> = (state = initialState, action): IProjectsState => {
  switch (action.type) {
    case ProjectsActionTypes.SEARCH_PROJECTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ProjectsActionTypes.SEARCH_PROJECTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        results: action.payload,
      };
    }
    case ProjectsActionTypes.FETCH_PROJECT_REQUEST: {
      return { ...state, loading: true };
    }
    case ProjectsActionTypes.FETCH_PROJECT_SUCCESS: {
      return { ...state, loading: false, selectedProject: action.payload };
    }
    case ProjectsActionTypes.SELECT_PROJECT: {
      return {
        ...state,
        selectedProject: action.payload,
        showProjectDialog: true,
      };
    }
    case ProjectsActionTypes.CLEAR_SELECTED_PROJECT: {
      return {
        ...state,
        selectedProject: undefined,
        showProjectDialog: false,
      };
    }
  }
  return state;
};

export { projectsReducer };
