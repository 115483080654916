import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';

import callApi from '../../utils/callApi';

function* handleSaveDocument(action: any): any {
  const { payload } = action;

  if (payload.deadline === 'Invalid date') {
    payload.deadline = '1970-01-01';
  }

  try {
    const res = yield call(callApi, 'POST', process.env.REACT_APP_SEARCH_API_URL || '', `/v1/saved`, payload);
    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      } else {
        switch (action.type) {
          case actions.SavedActionTypes.SAVE_DOCUMENT_REQUEST: {
            yield put(actions.saveDocumentSuccess(res));
            yield put(actions.fetchSavedDocumentsRequest());
            break;
          }
        }
      }
    }
  } catch (err) {
    handleError(err);
  }
}

function* handleRemoveSavedDocument(action: any): any {
  const { payload } = action;
  try {
    const res = yield call(
      callApi,
      'DELETE',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v1/saved/${payload}`,
    );

    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      } else {
        switch (action.type) {
          case actions.SavedActionTypes.REMOVE_SAVE_REQUEST: {
            yield put(actions.removeSavedDocumentSuccess(res));
            yield put(actions.fetchSavedDocumentsRequest());
            break;
          }
        }
      }
    }
  } catch (err) {
    handleError(err);
  }
}

function* handleFetchSaves(action: any): any {
  try {
    const res = yield call(callApi, 'GET', process.env.REACT_APP_SEARCH_API_URL || '', `/v1/saved`);

    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      } else {
        switch (action.type) {
          case actions.SavedActionTypes.FETCH_SAVED_REQUEST: {
            yield put(actions.fetchSavedDocumentsSuccess(res));
            break;
          }
        }
      }
    }
  } catch (err) {
    handleError(err);
  }
}

function* handleError(err: any) {
  if (err instanceof Error) {
    yield put(actions.savedError(err.message));
  } else {
    yield put(actions.savedError('An unknown error occured.'));
  }
}

function* saveDocumentRequest() {
  yield takeEvery(actions.SavedActionTypes.SAVE_DOCUMENT_REQUEST, handleSaveDocument);
}

function* removeSavedDocumentRequest() {
  yield takeEvery(actions.SavedActionTypes.REMOVE_SAVE_REQUEST, handleRemoveSavedDocument);
}

function* fetchSavesRequest() {
  yield takeEvery(actions.SavedActionTypes.FETCH_SAVED_REQUEST, handleFetchSaves);
}

function* savedSaga() {
  yield all([fork(saveDocumentRequest), fork(removeSavedDocumentRequest), fork(fetchSavesRequest)]);
}

export default savedSaga;
