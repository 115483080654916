import { Reducer } from 'redux';
import { SettingsActionTypes } from './actions';
import { ISettings, Onboarding } from './types';

export { Onboarding } from './types';

export interface ISettingsState {
  loading: boolean;
  loaded: boolean;
  settings: ISettings;
}

export const initialState: ISettingsState = {
  loading: false,
  loaded: false,
  settings: {
    reminderInterval: [],
    onboarding: Onboarding.SHOW_INTRO,
  },
};

const settingsReducer: Reducer<ISettingsState> = (state = initialState, action): ISettingsState => {
  switch (action.type) {
    case SettingsActionTypes.GET_SETTINGS: {
      return {
        ...state,
        loading: true,
      };
    }

    case SettingsActionTypes.SET_SETTINGS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    }

    case SettingsActionTypes.UPDATE_SETTINGS: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return state;
    }
  }
};

export { settingsReducer };
