import * as React from 'react';
import * as auth from '../../app/Auth/Auth';

interface ICustomRouterProp {
  location: {
    search: string;
  };
}

export class Login extends React.Component<ICustomRouterProp> {
  public componentDidMount() {
    const email = (new URLSearchParams(this.props.location.search).get('email') || '').replace(/ /g, '+');
    auth.login(email);
  }
  public render() {
    return <div className="login-page" />;
  }
}
