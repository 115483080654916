import * as React from 'react';
import * as moment from 'moment';
import type { Moment } from 'moment';
import Day from './Day';

interface IWeekProps {
  week: Moment;
}

export const Week = ({ week }: IWeekProps) => {
  const days = getDays(week);

  return (
    <div className="calendar__week">
      {days.map((day) => (
        <Day key={day.format('M-D')} date={day} />
      ))}
    </div>
  );
};

export default Week;

const getDays = (week: Moment) => {
  const days = [];
  const date = moment(week);

  while (date.isSame(week, 'week')) {
    days.push(moment(date));
    date.add(1, 'day');
  }

  days.push(moment(date));

  return days;
};
