import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { IState } from '../../reducers';

import * as auth from '../../app/Auth/Auth';

import './Header.css';

import Logo from '../../assets/spinverse_spinbase_logo_color6.png';

import { ReactComponent as UserIcon } from '../../assets/user--icon.svg';

interface IProps {
  user: auth0.AdfsUserProfile;
}

const Header: React.FC<IProps> = ({ user }) => {
  const handleLogout = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    auth.logout();
  };

  return (
    <header>
      <a href="https://spinbase.eu/" target="blank">
        <img src={Logo} className="header__logo" alt="Spinverse Spinbase" title="Spinverse Spinbase" />
      </a>
      {auth.isAuthenticated() ? (
        <nav>
          <Link to="/">Home</Link>

          <Link to="/fundings" style={(!auth.isEmailVerified() || auth.hasTrialExpired()) ? {pointerEvents: "none"}: {pointerEvents: "auto"}}>Fundings</Link>

          <HelpMenu />

          {user.picture ? (
            <Link to="/profile" className="header__profile">
              <img src={user.picture} alt={user.name} />
            </Link>
          ) : (
            <Link to="#" className="header__profile">
              <UserIcon className="user_icon" />
            </Link>
          )}

          <a href="#" className="header__logout" onClick={handleLogout}>
            Log out
          </a>
        </nav>
      ) : null}
    </header>
  );
};

const mapStateToProps = ({ user }: IState) => {
  return { user };
};

export default connect(mapStateToProps)(Header);

const HelpMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpenMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="menu__layout">
      {isOpen && <button onClick={handleOpenMenu} className="background-button" />}
      <div onMouseOver={handleOpenMenu} className="menu__button">
        Help
      </div>
      {isOpen ? (
        <div onMouseLeave={handleOpenMenu}>
          <div className="help__menu__tooltip-arrow" />

          <div className="help__menu">
            <a
              className="menu__url"
              href="https://spinbase.eu/support/"
              target={'blank'}
              onClick={handleOpenMenu}
            >
              Resources
            </a>

            <a
              className="menu__url"
              href="mailto:helpdesk@spinbase.eu"
              target={'blank'}
              onClick={handleOpenMenu}
            >
              Contact
            </a>
          </div>
        </div>
      ) : (
        <div className="" />
      )}
    </div>
  );
};
