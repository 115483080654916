import { action } from 'typesafe-actions';

export interface INationalSearchQueryData {
  query: string;
  limit?: number;
  source_name?: string[];
  exclude?: any[];
}

export interface INationalSearchQuery {
  query: string;
  scope?: string;
}

export interface INationalSearchQueryResults {
  data: INationalSearchQuery[];
}

export interface INationalSearchResult {
  application_term: string;
  application_url: string;
  call_identifier: string;
  call_programme_description: string;
  call_status: string;
  call_title: string;
  deadline_date: string;
  deadline_ongoing: boolean;
  description: string;
  eligible_countries: string[];
  foundation_name: string;
  foundation_url: string;
  html_desc: string;
  opening_date: string;
  similarity: string;
  source: string;
  source_name: string | null;
  title: string;
  topic_id: string;
  topic_url: string;
}

export interface INationalSearchResults {
  data: INationalSearchResult[];
}

export const enum NationalSearchActionTypes {
  NATIONAL_SEARCH_REQUEST = '@@nationalSearch/REQUEST',
  NATIONAL_SEARCH_SUCCESS = '@@nationalSearch/SUCCESS',
  NATIONAL_SEARCH_MORE_REQUEST = '@@nationalSearch/MORE_REQUEST',
  NATIONAL_SEARCH_MORE_SUCCESS = '@@nationalSearch/MORE_SUCCESS',
  NATIONAL_SEARCH_CLOSED_TOPICS_REQUEST = '@@nationalSearch/CLOSED_TOPICS_REQUEST',
  NATIONAL_SEARCH_CLOSED_TOPICS_SUCCESS = '@@nationalSearch/CLOSED_TOPICS_SUCCESS',
  NATIONAL_SEARCH_ERROR = '@@nationalSearch/ERROR',
  SET_QUERY = '@@nationalSearch/SET_QUERY',
  SET_FORM_VALID = '@@nationalSearch/SET_FORM_VALID',
  SELECT_TAB = '@@nationalSearch/SELECT_TAB',
  SET_ORDER_CONDITION = '@@nationalSearch/SET_ORDER_CONDITION',
  ADD_FILTER_CONDITION = '@@nationalSearch/ADD_FILTER_CONDITION',
  REMOVE_FILTER_CONDITION = '@@nationalSearch/REMOVE_FILTER_CONDITION',
  CLEAR_FILTER = '@@nationalSearch/CLEAR_FILTER',
  SELECT_ITEM = '@@nationalSearch/SELECT_ITEM',
  UNSELECT_ITEM = '@@nationalSearch/UNSELECT_ITEM',
  FETCH_TOPIC_SUCCESS = '@@nationalSearch/FETCH_TOPIC_SUCCESS',
  CLEAR_SELECTED_TOPIC = '@@nationalSearch/CLEAR_SELECTED_TOPIC',
  FETCH_TOPIC_DESCRIPTION_REQUEST = '@@nationalSearch/FETCH_TOPIC_DESCRIPTION_REQUEST',
  FETCH_TOPIC_DESCRIPTION_SUCCESS = '@@nationalSearch/FETCH_TOPIC_DESCRIPTION_SUCCESS',
  CLEAR_TOPIC_DESCRIPTION = '@@nationalSearch/CLEAR_TOPIC_DESCRIPTION',
}

export const nationalSearchRequest = (data: INationalSearchQueryData) =>
  action(NationalSearchActionTypes.NATIONAL_SEARCH_REQUEST, data);

export const nationalSearchResponse = (data: INationalSearchResults) =>
  action(NationalSearchActionTypes.NATIONAL_SEARCH_SUCCESS, data);

export const nationalSearchMoreRequest = (data: INationalSearchQueryData) =>
  action(NationalSearchActionTypes.NATIONAL_SEARCH_MORE_REQUEST, data);

export const nationalSearchMoreResponse = (data: INationalSearchResults) =>
  action(NationalSearchActionTypes.NATIONAL_SEARCH_MORE_SUCCESS, data);

export const nationalSearchError = (data: {}) =>
  action(NationalSearchActionTypes.NATIONAL_SEARCH_ERROR, data);

export const setNationalOrderCondition = (condition: string) =>
  action(NationalSearchActionTypes.SET_ORDER_CONDITION, condition);

export const addNationalFilterCondition = (key: string, value: string) =>
  action(NationalSearchActionTypes.ADD_FILTER_CONDITION, { key, value });

export const removeNationalFilterCondition = (key: string, value: string) =>
  action(NationalSearchActionTypes.REMOVE_FILTER_CONDITION, { key, value });

export const clearNationalFilter = () => action(NationalSearchActionTypes.CLEAR_FILTER);

export const selectNationalItem = (item: any) => action(NationalSearchActionTypes.SELECT_ITEM, item);

export const unSelectNationalItem = (item: any) => action(NationalSearchActionTypes.UNSELECT_ITEM, item);

export const fetchNationalTopicDescription = (data: any) =>
  action(NationalSearchActionTypes.FETCH_TOPIC_DESCRIPTION_REQUEST, data);

export const fetchNationalTopicDescriptionSuccess = (topicDescription: any) =>
  action(NationalSearchActionTypes.FETCH_TOPIC_DESCRIPTION_SUCCESS, topicDescription);
