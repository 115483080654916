import * as React from 'react';
import type { TooltipPosition } from '../../Tooltip';
import { Tooltip } from '../../Tooltip';

import './ResultLabel.css';

interface IResultLabelProps {
  label: string;
  value: string | React.ReactNode;
  tooltip?: {
    content: React.ReactNode;
    position?: TooltipPosition;
  };
}

const ResultLabel: React.FC<IResultLabelProps> = ({ label, value, tooltip }) => {
  return (
    <div className="result-label">
      {tooltip ? <Tooltip content={tooltip.content} position={tooltip.position} /> : null}

      <div className="result-label__value">
        <strong>{label}:</strong> <span>{value}</span>
      </div>
    </div>
  );
};

export default ResultLabel;
