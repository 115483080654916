import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Aside } from './Aside';
import { Content } from './Content';

import { useWindowScrollPosition } from './hooks';

import './ContentDialog.css';

interface IContentDialogProps {
  loading: {
    title: boolean;
    content: boolean;
    aside: boolean;
  };
  title: string;
  content: React.ReactNode;
  aside: React.ReactNode;
  asideAfter?: React.ReactNode;
  handleClose: () => void;
}

export const ContentDialog = ({
  loading,
  title,
  content,
  aside,
  asideAfter = null,
  handleClose,
}: IContentDialogProps) => {
  const containerRef = useWindowScrollPosition();

  return (
    <div className="content-dialog-container">
      <div className="content-dialog" ref={containerRef}>
        <h2>{loading.title ? <Skeleton height={20} width={400} /> : title}</h2>

        <CloseButton onClick={handleClose} />

        <div className="content-dialog__content">
          <div className="content-dialog__content-wrapper">
            <Content loading={loading.content} content={content} />

            <Aside loading={loading.aside} content={aside} after={asideAfter} />
          </div>
        </div>
      </div>
    </div>
  );
};

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button className="close" onClick={onClick}>
      <svg width="43" height="43" viewBox="0 0 43 43">
        <circle cx="21.5" cy="21.5" r="21" fill="none" stroke="currentColor" strokeWidth="1" />

        <line
          x2="18"
          y2="18"
          transform="translate(13 13)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="3"
        />

        <line
          x1="18"
          y2="18"
          transform="translate(13 13)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </svg>
    </button>
  );
};
