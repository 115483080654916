import { createId } from '@paralleldrive/cuid2';
import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import { capitalize } from '../../../utils/strings';
import { ExpandableSection } from '../ExpandableSection';
import './PreviousQueries.css';

import Modal from '../../Modal/Modal';

// Possible development for query search details
/* import { ActionButton } from '../../ActionButton/ActionButton';
import { useHistory } from 'react-router'; */

interface IProps {
  query?: any;
}

interface IQueryProps {
  query: any;
  modal?: any;
}

const ShareModal: React.FC<IQueryProps> = ({ query }) => (
  <Modal>
    <p>Share this query by copying the link below and sending it to your contact.</p>

    <p>{query.identifier}</p>
  </Modal>
);

const PreviousQueries: React.FC<IProps> = ({ query }) => {
  // Possible development for query search details
  // const history = useHistory();

  const { previousQueries } = query;
  const queriesByScope = previousQueries.reduce((acc: any[], QueryData: any) => {
    const { scope } = QueryData;
    if (!acc[scope]) {
      acc[scope] = [];
    }
    acc[scope].push(QueryData);
    return acc;
  }, {});

  return (
    <ExpandableSection title="Previous queries" count={previousQueries.length}>
      <div className="previous-queries">
        <ShareModal query={query.selectedQuery} />

        {[
          'FUNDING',
          {
            /*'NATIONAL'*/
            // Uncomment when National funding gets enabled
          },
          'PARTNERS',
          'PROJECTS',
        ].map((scope: string) => {
          const queries = queriesByScope[scope];
          if (!queries) {
            return null;
          }

          return (
            <ExpandableSection key={scope} title={capitalize(scope.toLowerCase())} count={queries.length}>
              {queries.map((getEachQuery: any) => (
                <p className="previous-queries__query" key={createId()}>
                  "{getEachQuery.query}"{/* Possible development for query search details */}
                  {/*<ActionButton*/}
                  {/*  text={`"${getEachQuery.query}"`}*/}
                  {/*  handleClick={() => history.push(`/query/${getEachQuery.identifier}`)}*/}
                  {/*/>*/}
                </p>
              ))}
            </ExpandableSection>
          );
        })}
      </div>
    </ExpandableSection>
  );
};

const mapStateToProps = ({ modal, query }: IState) => {
  return { modal, query };
};

export default connect(mapStateToProps)(PreviousQueries);
