import { Reducer } from 'redux';
import { ModalActionTypes } from './actions';

export interface IModalState {
  showModal: boolean;
  modalClosed: boolean;
  modalOpened: boolean;
}

export const initialState: IModalState = {
  showModal: false,
  modalClosed: false,
  modalOpened: false,
};

const modalReducer: Reducer<IModalState> = (state = initialState, action): IModalState => {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL: {
      return { ...state, showModal: true, modalOpened: true };
    }
    case ModalActionTypes.CLOSE_MODAL: {
      return { ...state, showModal: false, modalClosed: true };
    }
  }
  return state;
};

export { modalReducer };
