const callExport = async (method: string, url: string, path: string, _data?: any) => {
  const res = await fetch(url + '/api' + path, {
    method,
    headers: {
      Authorization: `Basic c3BpbnZlcnNlOmNZbUI4SndWYUs3UW5qUmpUY2hy`,
    },
  });
  return await res.blob();
};

export default callExport;
