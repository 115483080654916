import { Spinner } from '@fluentui/react/lib/Spinner';
import { createId } from '@paralleldrive/cuid2';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { isTrialUser } from '../../../../app/Auth/Auth';
import { IProjectResult, selectProject } from '../../../../app/Projects/actions';
import { IProjectsState } from '../../../../app/Projects/reducer';
import { removeSavedDocumentRequest, saveDocumentRequest } from '../../../../app/Saved/actions';
import { ISavedState } from '../../../../app/Saved/reducer';
import { selectableTabs } from '../../../../app/Search/actions';
import { ISearchState } from '../../../../app/Search/reducer';
import { ActionButton, DefaultButton } from '../../../ActionButton/ActionButton';
import { IState } from '../../../../reducers';
import { store } from '../../../../store';
import { containsValue } from '../../../../utils/filter';
import { convertToPercent, getThousands } from '../../../../utils/numberConverter';
import { cut } from '../../../../utils/strings';
import ResultLabel from '../ResultLabel';
import { SearchResultDetail } from '../SearchResultDetail';
import '../SearchResults.css';
import ProjectDialog from './ProjectDialog';

interface IProjectProps {
  project: IProjectResult;
  saved: boolean;
  index: number;
}

const Project: React.FC<IProjectProps> = ({ project, saved, index }) => {
  const handleReadMore = () => {
    store.dispatch(selectProject(project));
    document.body.classList.add('preventModalScrolling');
  };

  const goToWebsite = () => {
    let { projectUrl } = project;
    if (!/^https?:\/\//i.test(projectUrl)) {
      projectUrl = 'https://' + projectUrl;
    }
    window.open(projectUrl);
  };

  const getRoundedString = (cost: string) => {
    const thousands = getThousands(Number(cost));
    if (thousands < 1000) {
      return `${thousands}K`;
    } else {
      return `${getThousands(thousands)}M`;
    }
  };

  const handleSave = () => {
    store.dispatch(
      saveDocumentRequest({
        scope: selectableTabs.PROJECTS,
        objectId: project.id.toString(),
        title: project.title,
      }),
    );
  };

  const handleUnsave = () => {
    store.dispatch(removeSavedDocumentRequest(project.id.toString()));
  };

  return (
    <div className="search-results--row">
      {!isTrialUser() ? (
        <div className="search-results--first-column">
          <div className="search-results--result-name">{project.title}</div>

          <div className="search-results--result-description">{cut(project.objective, 400)}</div>

          <div className="search-results--buttons">
            <div className="search-results--buttons__default">
              <DefaultButton text="Read more" handleClick={handleReadMore} />
            </div>

            <div className="search-results--buttons__action">
              {project.projectUrl && <ActionButton text="Go to project website" handleClick={goToWebsite} />}

              {saved ? (
                <ActionButton text="Unsave" handleClick={handleUnsave} selected={true} />
              ) : (
                <ActionButton text="Save" handleClick={handleSave} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="search-results--first-column">
          {isTrialUser() && index < 3 ? (
            <div>
              <div className="search-results--result-name">{project.title}</div>

              <div className="search-results--result-description">{cut(project.objective, 400)}</div>

              <div className="search-results--buttons">
                <div className="search-results--buttons__default">
                  <DefaultButton text="Read more" handleClick={handleReadMore} />
                </div>

                <div className="search-results--buttons__action">
                  {project.projectUrl && (
                    <ActionButton text="Go to project website" handleClick={goToWebsite} />
                  )}

                  {saved ? (
                    <ActionButton text="Unsave" handleClick={handleUnsave} selected={true} />
                  ) : (
                    <ActionButton text="Save" handleClick={handleSave} />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="search-results--subscribe">
              <h1>
                {'To see result details and unlock share feature please '}
                <a className="" target="blank" href="https://spinbase.eu/pricing/">
                  {'upgrade your subcription'}
                </a>
              </h1>
            </div>
          )}
        </div>
      )}

      <div className="search-results--second-column">
        <div className="search-results--icon-area">
          <SearchResultDetail
            highlight={true}
            type="MATCH"
            label="Match"
            value={`${convertToPercent(project.weight)}%`}
            tooltip={{
              content: (
                <p>
                  Match %: This is the similarity score that the AI engine has given to each one of the search
                  results. It means that AI has read the whole text you provided as search query, and analysed
                  it word by word and then compared that with the texts associated with each funding call
                  topics in our entire database, and as a result it is recommending that the higher the % is,
                  there is a better match.
                </p>
              ),
              position: 'topLeft',
            }}
          />

          <SearchResultDetail
            highlight={false}
            type="STARTED"
            label="Started"
            value={moment(project.startDate).format('DD.MM.YYYY')}
            tooltip={{
              content: <p>This is the starting date of the project.</p>,
              position: 'topLeft',
            }}
          />

          <SearchResultDetail
            highlight={false}
            type="BUDGET"
            label="€"
            value={getRoundedString(project.totalCost)}
            tooltip={{
              content: <p>This is the allocated funding for this project.</p>,
              position: 'topRight',
            }}
          />
        </div>

        <div className="search-results--label-area">
          <ResultLabel
            label="Call"
            value={project.call}
            tooltip={{
              content: <CallTooltipContent />,
              position: 'topLeft',
            }}
          />

          <ResultLabel
            label="Coordinator"
            value={project.coordinator}
            tooltip={{
              content: <CoordinatorTooltipContent />,
              position: 'topLeft',
            }}
          />
        </div>
      </div>
    </div>
  );
};

interface IProps {
  projects: IProjectsState;
  searchResults: ISearchState;
  saved: ISavedState;
}

class ProjectResults extends React.Component<IProps> {
  filterCondition(item: any) {
    const { filterConditions } = this.props.searchResults;
    return containsValue(item, filterConditions);
  }

  isSaved(project: any): boolean {
    return !!this.props.saved.savedDocuments.find(
      (obj: any) => obj.objectId === project.id.toString() && obj.scope === selectableTabs.PROJECTS,
    );
  }

  render() {
    const { results, loading } = this.props.projects;

    const loadingSpinnerStyling = {
      display: 'flex',
      height: '50vh',
      justifyContent: 'center',
    };

    return (
      <div className="search-results--container">
        <div className="search-results--content">
          {loading && (
            <div style={loadingSpinnerStyling}>
              <Spinner />
            </div>
          )}

          {results &&
            results.length > 0 &&
            results
              .filter((item) => this.filterCondition(item))
              .map((project: IProjectResult, index: number) => {
                return (
                  <Project key={createId()} project={project} saved={this.isSaved(project)} index={index} />
                );
              })}

          <ProjectDialog />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ projects, searchResults, saved }: IState) => {
  return { projects, searchResults, saved };
};

export default connect(mapStateToProps)(ProjectResults);

const CallTooltipContent = () => {
  return (
    <>
      <p>This is the funding call that has provided the funding for this project.</p>
    </>
  );
};

const CoordinatorTooltipContent = () => {
  return (
    <>
      <p>Name of Coordinator.</p>
    </>
  );
};
