import * as React from 'react';
import Filter from './Filters/Filter';
import { FundingResults } from './FundingResults/FundingResults';
import { store } from '../../store';
import { useSelector } from 'react-redux';
import { Spinner } from '@fluentui/react/lib/Spinner';
import './FundingsPage.css';
import { searchRequestFundings } from '../../app/Fundings/actions';
import IconArrowRigh from '../../assets/icon--arrow--right.svg';
import IconArrowLeft from '../../assets/icon--arrow--left2.svg';
import { MessageBar, MessageBarType } from '@fluentui/react';

const Fundings: React.FC = () => {
  const isExporting = useSelector((state: any) => state.funding.isExporting);

  const fundingResults = useSelector((state: any) => state.funding.results);
  const loading = useSelector((state: any) => state.funding.loading);
  const currentFrameworkProgramme = useSelector((state: any) => state.funding.frameworkProgramme);
  const currentStatus = useSelector((state: any) => state.funding.status);
  const limit = 10;
  const currentPage = useSelector((state: any) => state.funding.currentPage);
  const totalPages = useSelector((state: any) => state.funding.totalPage);
  const isSearchRequestError = useSelector((state: any) => state.funding.isSearchRequestError);
  const isExportRequestError = useSelector((state: any) => state.funding.isExportRequestError);

  const [toastMessage, setToastMessage] = React.useState<string | null>(null);
  const [toastType, setToastType] = React.useState<MessageBarType | undefined>(undefined);

  const showToast = (message: string, type: MessageBarType, duration = 7000) => {
    setToastMessage(message);
    setToastType(type);
    setTimeout(() => {
      setToastMessage(null);
    }, duration);
  };

  React.useEffect(() => {
    if (isSearchRequestError) {
      showToast('An error occurred while fetching results. Please refresh', MessageBarType.error, 7000);
    }
    if (isExportRequestError) {
      showToast('An error occured while exporting data. Please refresh', MessageBarType.error, 7000);
    }
  }, [isExportRequestError, isSearchRequestError]);

  const handlePageChange = (newPage: number) => {
    if (newPage < 1) {
      return;
    }
    if (newPage > totalPages) {
      return;
    }
    const offset: number = (newPage - 1) * limit;
    store.dispatch(
      searchRequestFundings({
        frameworkProgramme: currentFrameworkProgramme,
        status: currentStatus,
        limit: limit,
        offset: offset,
      }),
    );
  };

  return (
    <div className="search-page">
      {toastMessage && (
        <MessageBar
          messageBarType={toastType}
          isMultiline={false}
          onDismiss={() => setToastMessage(null)}
          dismissButtonAriaLabel="Close"
        >
          {toastMessage}
        </MessageBar>
      )}
      <div className="container result">
        <div className="aside">
          <div className="filter-sticky-container">
            <Filter />
          </div>
        </div>
        <div className="main" style={isExporting ? { opacity: 0.75, pointerEvents: 'none' } : {}}>
          <div className="search-results--container">
            <div className="search-results--content">
              {loading && !isSearchRequestError && (
                <div className="spinner-container">
                  <Spinner />
                </div>
              )}
              {!loading && !isSearchRequestError && fundingResults.length > 0
                ? fundingResults.map((result: any, index: number) => (
                    <FundingResults key={`${result.reference}`} index={index} data={result} />
                  ))
                : !loading && <p className="no-data">No data to show</p>}
            </div>
          </div>
          {!loading && !isSearchRequestError && fundingResults.length > 0 && (
            <div className="pagination">
              <div className="pagination-button">
                <img src={IconArrowLeft} alt="previous" onClick={() => handlePageChange(currentPage - 1)} />
              </div>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <div className="pagination-button">
                <img src={IconArrowRigh} alt="next" onClick={() => handlePageChange(currentPage + 1)} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const FundingsPage = Fundings;
