import * as React from 'react';
import Collapsible from '../../Collapsible/Collapsible';
import TopicInformation from '../TopicInformation';
// import { HighlightLegend } from './HighlightLegend';

interface INationalSearchResultAsideProps {
  topic: {
    id: any;
    metadata: {
      applicant_types: string[];
      application_terms: string;
      application_url: string;
      categories: string[];
      deadline_date: string;
      deadline_ongoing: boolean;
      description: string;
      eligible_countries: string[];
      foundation_name: string;
      foundation_url: string;
      funding_types: string[];
      keywords: string;
      opening_date: string;
      source: string | null;
      source_name: string;
      title: string;
      url: string;
    };
    summary: {
      keywords: string[];
      preview: string;
    };
  };
}

interface CollapseToggleType {
  [key: string]: boolean;
}

export const NationalSearchResultAside = (props: INationalSearchResultAsideProps) => {
  const [collapseTopicCategory, setCollapseTopicCategory] = React.useState(false);
  const [collapseTopicKeywords, setCollapseTopicKeywords] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState<CollapseToggleType>({
    categories: true,
    keywords: true,
  });
  const collapseToggle = (key: string) => {
    setCollapsed((getCollapsedStatus) => ({
      ...getCollapsedStatus,
      [key]: !getCollapsedStatus[key],
    }));
  };

  return (
    <>
      <div className="topic__info">
        <div className="topic__info__basic">
          <h3>Topic information:</h3>

          <TopicInformation topicMetadata={props.topic.metadata} />
        </div>

        <Collapsible
          children={
            <div className="topic-tags">
              <ul>
                {props.topic.metadata.categories.map((tag: string, i: number) =>
                  i > 4 && collapsed.categories ? (
                    ''
                  ) : (
                    <li key={tag} dangerouslySetInnerHTML={{ __html: tag }} />
                  ),
                )}

                {props.topic.metadata.categories.length > 5 && (
                  <button className="action-button" onClick={() => collapseToggle('categories')}>
                    {collapsed.categories ? 'Show more' : 'Show less'}
                  </button>
                )}
              </ul>
            </div>
          }
          setState={setCollapseTopicCategory}
          state={collapseTopicCategory}
          label={'Categories'}
        />

        <Collapsible
          children={
            <div className="topic-keywords">
              <ul>
                {props.topic.summary.keywords.map((keyword: string, i: number) =>
                  i > 4 && collapsed.keywords ? (
                    ''
                  ) : (
                    <li key={keyword} dangerouslySetInnerHTML={{ __html: keyword }} />
                  ),
                )}

                {props.topic.summary.keywords.length > 5 && (
                  <button className="action-button" onClick={() => collapseToggle('keywords')}>
                    {collapsed.keywords ? 'Show more' : 'Show less'}
                  </button>
                )}
              </ul>
            </div>
          }
          setState={setCollapseTopicKeywords}
          state={collapseTopicKeywords}
          label={'Keywords'}
        />

        {/* <HighlightLegend /> */}
      </div>
    </>
  );
};
