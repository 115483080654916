import * as React from 'react';
import * as classnames from 'classnames';
import IconArrowUp from '../../../../../assets/icon--arrow--up.svg';
import IconArrowDown from '../../../../../assets/icon--arrow--down.svg';
import './CheckboxGroup.css';

interface ICheckboxGroupOption {
  label: string;
  value: any;
  key?: any;
}

interface ICheckboxGroupProps {
  label: string;
  name: string;
  value: any;
  options: ICheckboxGroupOption[];
  hasShowMore?: boolean;
  onChange: (value: any) => void;
}

export const CheckboxGroup = ({
  name,
  label,
  options,
  value,
  hasShowMore = true,
  onChange,
}: ICheckboxGroupProps) => {
  const [isOpen, setIsOpen] = React.useState(!hasShowMore);
  const showMore = hasShowMore && !isOpen && value.length > 0 && value.length < options.length;

  return (
    <div
      className={classnames('checkbox-group', {
        'checkbox-group--open': isOpen,
      })}
    >
      <button className="checkbox-group-label" onClick={() => setIsOpen(!isOpen)}>
        {label}
        {hasShowMore ? <img src={isOpen ? IconArrowUp : IconArrowDown} alt="" /> : null}
      </button>

      <div className="checkbox-group-options">
        {options.map((option, index) => (
          <CheckboxOption
            key={`${name}-${index}`}
            id={`${name}-${index}`}
            name={name}
            label={option.label}
            value={option.value}
            checked={value.includes(option.value)}
            onChange={() => onChange(option.value)}
          />
        ))}
      </div>

      {showMore ? (
        <button className="checkbox-group__show-more" onClick={() => setIsOpen(!isOpen)}>
          Show {options.length - value.length} more option
          {options.length - value.length > 1 ? 's' : ''}
          <img src={IconArrowDown} alt="" />
        </button>
      ) : null}
    </div>
  );
};

interface ICheckboxOptionProps {
  id: string;
  name: string;
  label: string;
  value: any;
  checked: boolean;
  onChange: () => void;
}

const CheckboxOption = ({ id, name, label, value, checked, onChange }: ICheckboxOptionProps) => {
  return (
    <label
      className={classnames('checkbox-group-option', {
        'checkbox-group-option--checked': checked,
      })}
      htmlFor={id}
      aria-labelledby={`${id}-label`}
    >
      <input type="checkbox" id={id} name={name} value={value} onChange={onChange} checked={checked} />

      <p id={`${id}-label`}>{label}</p>
    </label>
  );
};
