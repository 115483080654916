import { action } from 'typesafe-actions';

export interface IH2020Project {
  rcn: number;
  id: number;
  acronym: string;
  status: string;
  programme: string;
  topics: string;
  frameworkProgramme: string;
  title: string;
  startDate: string;
  endDate: string;
  projectUrl: string;
  objective: string;
  totalCost: string;
  ecMaxContribution: string;
  call: string;
  fundingScheme: string;
  coordinator: string;
  coordinatorCountry: string;
  participants: string;
  participantCountries: string;
  subjects: string;
  weight: number;
}

interface IPartnerTotals {
  [key: string]: {
    count: number;
    funding: number;
  };
}

export interface IPartnerSearchResult {
  projectRcn: number;
  projectID: number;
  projectAcronym: string;
  role: string;
  id: number;
  name: string;
  shortName: string;
  activityType: string;
  endOfParticipation: boolean;
  ecContribution: string;
  country: string;
  street: string;
  city: string;
  postCode: string;
  organizationUrl: string;
  vatNumber: string;
  contactForm: string;
  contactType: string;
  contactTitle: string;
  contactFirstNames: string;
  contactLastNames: string;
  contactFunction: string;
  contactTelephoneNumber: string;
  contactFaxNumber: string;
  contactEmail: string;
  count: number;
  contribution_sum: any;
  projectIds: string;
  projects: IH2020Project[];
  totals: IPartnerTotals;
}

export const enum PartnerSearchActionTypes {
  PARTNER_SEARCH_REQUEST = '@@partnerSearch/REQUEST',
  PARTNER_SEARCH_SUCCESS = '@@partnerSearch/SUCCESS',
  PARTNER_SEARCH_ERROR = '@@partnerSearch/ERROR',
  SHOW_POTENTIONAL_PARTNERS = '@@partnerSearch/SHOW_POTENTIONAL_PARTNERS',
  HIDE_POTENTIONAL_PARTNERS = '@@partnerSearch/HIDE_POTENTIONAL_PARTNERS',
  SEARCH_BY_CALL_DESCRIPTION = '@@partnerSearch/SEARCH_BY_CALL_DESCRIPTION',
  CLEAR_CALL_QUERY = '@@partnerSearch/CLEAR_CALL_QUERY',
  SELECT_PARTNER = '@@partnerSearch/SELECT_PARTNER',
  SELECT_PROJECT = '@@partnerSearch/SELECT_PROJECT',
  CLEAR_SELECTED_PARTNER = '@@partnerSearch/CLEAR_SELECTED_PARTNER',
  CLEAR_SELECTED_PROJECT = '@@partnerSearch/CLEAR_SLEECTED_PROJECT',
  FETCH_PARTNER_REQUEST = '@@partnerSearch/FETCH_PARTNER_REQUEST',
  FETCH_PARTNER_SUCCESS = '@@partnerSearch/FETCH_PARTNER_SUCCESS',
}

export const partnerSearchRequest = (query: string) =>
  action(PartnerSearchActionTypes.PARTNER_SEARCH_REQUEST, query);

export const partnerSearchResponse = (data: IPartnerSearchResult[]) =>
  action(PartnerSearchActionTypes.PARTNER_SEARCH_SUCCESS, data);

export const partnerSearchError = (data: {}) => action(PartnerSearchActionTypes.PARTNER_SEARCH_ERROR, data);

export const openPotentionalPartners = () => action(PartnerSearchActionTypes.SHOW_POTENTIONAL_PARTNERS);

export const hidePotentionalPartners = () => action(PartnerSearchActionTypes.HIDE_POTENTIONAL_PARTNERS);

export const searchByCallDescription = (query: string) =>
  action(PartnerSearchActionTypes.SEARCH_BY_CALL_DESCRIPTION, query);

export const clearCallQuery = () => action(PartnerSearchActionTypes.CLEAR_CALL_QUERY);

export const selectPartner = (partner: IPartnerSearchResult) =>
  action(PartnerSearchActionTypes.SELECT_PARTNER, partner);

export const clearSelectedPartner = () => action(PartnerSearchActionTypes.CLEAR_SELECTED_PARTNER);

export const fetchPartnerRequest = (id: number) => action(PartnerSearchActionTypes.FETCH_PARTNER_REQUEST, id);

export const fetchPartnerResponse = (data: IPartnerSearchResult) =>
  action(PartnerSearchActionTypes.FETCH_PARTNER_SUCCESS, data);
