import * as React from 'react';

import { connect } from 'react-redux';

import * as auth from '../../../app/Auth/Auth';
import { clearCallQuery, partnerSearchRequest } from '../../../app/PartnerSearch/actions';
import { IState } from '../../../reducers';
import { store } from '../../../store';

import Tab from './Tab';

import { nationalSearchRequest } from '../../../app/NationalSearch/actions';
import { searchProjectsRequest } from '../../../app/Projects/actions';
import { saveQueryRequest } from '../../../app/Query/actions';
import { searchRequest, selectableTabs, selectTab } from '../../../app/Search/actions';

import './Tabs.css';

interface IPropsFromState {
  searchResults: any;
  user: any;
}

interface IComponentState {
  tabs: string[];
  selectedIndex: number;
}

type AllProps = IPropsFromState;

export class Tabs extends React.Component<AllProps, IComponentState & IPropsFromState> {
  public readonly state: IPropsFromState & IComponentState = {
    tabs: ['Funding', 'National', 'Partners', 'Projects'],
    selectedIndex: 0,
    user: {},
    searchResults: {},
  };

  constructor(props: AllProps) {
    super(props);
  }

  handleFundingSearch = (tab: string) => {
    const { query } = this.props.searchResults;
    const searchLimit = 30;
    store.dispatch(saveQueryRequest({ query, scope: selectableTabs.FUNDING }));
    store.dispatch(searchRequest({ query, exclude: [], limit: searchLimit }));
    store.dispatch(selectTab(tab));
  };

  handleNationalFundingSearch = (tab: string) => {
    const { query } = this.props.searchResults;
    const searchLimit = 30;
    store.dispatch(saveQueryRequest({ query, scope: selectableTabs.NATIONAL }));
    store.dispatch(nationalSearchRequest({ query, limit: searchLimit }));
    store.dispatch(selectTab(tab));
  };

  handlePartnerSearch = (tab: string) => {
    const { query } = this.props.searchResults;
    store.dispatch(saveQueryRequest({ query, scope: selectableTabs.PARTNERS }));
    store.dispatch(partnerSearchRequest(query));
    store.dispatch(selectTab(tab));
    store.dispatch(clearCallQuery());
  };

  handleProjectSearch = (tab: string) => {
    const { query } = this.props.searchResults;
    store.dispatch(saveQueryRequest({ query, scope: selectableTabs.PROJECTS }));
    store.dispatch(searchProjectsRequest({ query }));
    store.dispatch(selectTab(tab));
  };

  render() {
    const { formValid, selectedTab } = this.props.searchResults;
    if (this.props.user.isLoading) {
      return null;
    }
    if (!auth.isAuthenticated()) {
      return null;
    }

    return (
      <div className="tabs-container">
        <div className="tabs">
          <div className="tabs__spacer" />

          <div className="tabs__spacer" />

          <div className="tabs__spacer" />

          <Tab
            selected={selectedTab === selectableTabs.FUNDING}
            tab={selectableTabs.FUNDING}
            handleClick={this.handleFundingSearch}
            disabled={!formValid}
          >
            Funding
          </Tab>

          {/*
            Disabled until national funding is approved for production deployment
            Uncomment when National funding gets enabled
            */}
          {/*<Tab*/}
          {/*  selected={selectedTab === selectableTabs.NATIONAL}*/}
          {/*  tab={selectableTabs.NATIONAL}*/}
          {/*  handleClick={this.handleNationalFundingSearch}*/}
          {/*  disabled={!formValid}*/}
          {/*>*/}
          {/*  National Funding*/}
          {/*</Tab>*/}

          <Tab
            selected={selectedTab === selectableTabs.PARTNERS}
            tab={selectableTabs.PARTNERS}
            handleClick={this.handlePartnerSearch}
            disabled={!formValid}
          >
            Partners
          </Tab>

          <Tab
            selected={selectedTab === selectableTabs.PROJECTS}
            tab={selectableTabs.PROJECTS}
            handleClick={this.handleProjectSearch}
            disabled={!formValid}
          >
            Projects
          </Tab>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ searchResults, user }: IState) => {
  return { searchResults, user };
};

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
