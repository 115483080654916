import * as moment from 'moment';
import * as React from 'react';

interface IProps {
  selectedItems: any;
  topicDescription: any;
  setExportClicked: any;
}

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}

export class ExportNationalTopicsWholeText extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  componentDidMount() {
    this.createWholeTextWord();
  }

  createWholeTextWord() {
    const htmlHead = `
    <html>
      <head
        xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:w="urn:schemas-microsoft-com:office:word"
        xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
        xmlns="http://www.w3.org/TR/REC-html40"
      >
      </head>
      <meta charset='utf-8'>
      <body>
      <br>
      `;

    let menuContent: string = '';
    let tableOfContent: string = '';
    let preparedHTML: string = '';
    let htmlToDoc: string = '';

    this.props.selectedItems.map((topic: any, index: number) => {
      const deadline =
        topic.deadline_date !== 'N/A' ? moment(topic.deadline_date).format('DD.MM.YYYY') : 'Ongoing';
      const openingDate =
        topic.opening_date !== 'N/A' ? moment(topic.opening_date).format('DD.MM.YYYY') : 'N/A';

      tableOfContent += `
      <div style="
      margin-left: 8px;
      color: black;
    "
    >
      <h4>
        <a style="
          font-size: 11.0pt;
          color: black;
          text-decoration: none;
        "
        href="#${index}">${index + 1}. ${topic.title}</a>
      </h4>
      <div style="
        font-size: 10.0pt;
        margin-left: 12px;
        margin-top: 0px;
      ">
        <div style="
          margin-top: 6px;
        ">
          <strong>Deadline: </strong>${deadline}, 
        </div>
      </div>
    </div>   
      `;

      menuContent = `
      <div 
        style="
          padding-top: 20px;
          "
        >
        <a style="
          color: darkblue;
          font-size: 16.0pt;
          "
          name="table-of-content"
        >
          Content
        </a>
        ${tableOfContent}
      </div>
    `;

      const table = `
        <table 
          border=1
          cellspacing=0
          cellpadding=0
          style="
            font-family: Arial;
            font-size: 11.0pt;
            height: 150px;
            width: 600px;
            border-collapse:collapse;
            border:none;
            mso-border-alt:solid windowtext .5pt;"
          >
        <thead>
          <tr>
            <th style="
              font-size: 12.0pt;
              min-width: 100%;
              border: 1px solid black;
              text-align: left;
              color: white;
              background-color: #3AA0A7;"
              colspan="2"
            >
              Topic Information
            </th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <td>Call identifier</td>
              <td>${topic.topic_id ? topic.topic_id : ''}</td>
            </tr>
            <tr>
              <td>Call title</td>
              <td>${topic.call_title ? topic.call_title : ''}</td>
            </tr>
            <tr>
              <td>Opening date</td>
              <td>${openingDate}</td>
            </tr>
            <tr>
              <td>Next deadline</td>
              <td>${deadline}</td>
            </tr>
            <tr>
              <td>Source name</td>
              <td>${topic.source_name}</td>
            </tr>
            <tr>
              <td>External links</td>
              <td>
                <ul>
                  ${
                    topic.application_url
                      ? `
                  <li>
                    <a style="
                    font-size: 10.0pt;
                    color: black;
                    "
                    href=${topic.application_url}>Application Page</a>
                  </li>`
                      : ''
                  }
                  
                  ${
                    topic.topic_url
                      ? `
                  <li>
                    <a style="
                    font-size: 10.0pt;
                    color: black;
                    "
                    href=${topic.topic_url}>Topic Page</a>
                  </li>`
                      : ''
                  }

                  ${
                    topic.foundation_url
                      ? `
                  <li>
                    <a style="
                    font-size: 10.0pt;
                    color: black;
                    "
                    href=${topic.foundation_url}>Foundation Page</a>
                  </li>`
                      : ''
                  }
                </ul>
              </td>
            </tr>
          </tbody>
        </table><br>`;

      preparedHTML += `
      <div >
        <br style="page-break-after: always;">&nbsp;</br>
        <div style="
          margin-left: 10px;
          margin-right: 10px;"
          margin-bottom: 10px;"
        >
          <a
            name="${index}"
            style="
              font-family: Arial;
              font-size: 14.0pt;
              color: #8b0000;"
          >
            ${topic.title}
          </a>
          <br>
          <br>

          ${table}
          <div style="
            font-family: Arial;
            font-size: 11pt;
            text-align: justify;"
          >
            ${this.props.topicDescription[topic.id]}
          </div>
        </div>

        <br>
        <br>
        <br>
        <a style="
          margin-left: 8px;
          color: black;
          text-decoration: none;
          font-size: 11.0pt;
          "
          href='#table-of-content'
          >Back to table of content
          </a>

        <div>
          <hr>
          <br>
          <br>
          <br>
          <div style="
            padding: 6px 0px 6px 0px;
            color: black;
            font-size: 11px;
            "
          >
          This call can be found with Spinbase, the AI-powered tool to find EU funding call, partners and projects. Try it here:
          <a style="
            text-decoration: none;
            "
            href="https://spinbase.eu" target="blank">
            <img width="40" height="18" src="https://spinba.se/logo--spinbase.png">
          </a>
        </div>
      </div>
        `;
    });

    const htmlFoot = `</body></html>`;

    preparedHTML = preparedHTML.replace(
      /<(?:span|SPAN) class='topicdescriptionkind'>(.*?)<\/(?:span|SPAN)>(?::|\s:)/g,
      `<div class="topicdescriptionkind" 
        style="
          color: white;
          font-weight: bold;
          background-color: darkcyan;
          font-size: 12pt;
          font-family: 'Arial';
      "
      >$1</div>`,
    );

    preparedHTML = preparedHTML.replace(/ExpectedOutcome/g, 'Expected Outcome');

    htmlToDoc = `${htmlHead}${menuContent}${preparedHTML}${htmlFoot}`;

    const blob = new Blob(['\ufeff', htmlToDoc], {
      type: 'application/msword',
    });

    const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(htmlToDoc);
    let filename: any;
    filename = filename ? filename + '.doc' : 'national-funding.doc';
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }
    document.body.removeChild(downloadLink);
    this.props.setExportClicked(false);
  }

  render() {
    return <div />;
  }
}
