import { put, call, all, fork, takeEvery } from 'redux-saga/effects';
import callApi from '../../utils/callApi';
import * as actions from './actions';
import { ISettings } from './types';
import { validateSettings } from './validate';
import { logError } from '../../utils/logger';

function* handleSettingsFetch(): any {
  try {
    const settings = yield call(callApi, 'get', process.env.REACT_APP_SEARCH_API_URL || '', `/v1/settings`);

    yield put(actions.setSettings(settings));
  } catch (err) {
    logError('error', err);
  }
}

function* handleSettingUpdate(action: any): any {
  const settings: Partial<ISettings> = action.payload;
  const valid = validateSettings(settings);

  if (valid) {
    try {
      const newSettings = yield call(
        callApi,
        'put',
        process.env.REACT_APP_SEARCH_API_URL || '',
        `/v1/settings`,
        {
          settings,
        },
      );

      yield put(actions.setSettings(newSettings));
    } catch (err) {
      logError('error', err);
    }
  }
}

function* fetchSettingsRequest() {
  yield takeEvery(actions.SettingsActionTypes.GET_SETTINGS, handleSettingsFetch);
}

function* updateSettingsRequest() {
  yield takeEvery(actions.SettingsActionTypes.UPDATE_SETTINGS, handleSettingUpdate);
}

function* settingsSaga() {
  yield all([fork(fetchSettingsRequest), fork(updateSettingsRequest)]);
}

export default settingsSaga;
