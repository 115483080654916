import { Reducer } from 'redux';
import { UserActionTypes, IUser } from './actions';

export const initialState: auth0.Auth0UserProfile & IUser = {
  clientID: '',
  created_at: '',
  identities: [],
  name: '',
  nickname: '',
  picture: '',
  sub: '',
  updated_at: '',
  user_id: '',
  isLoading: true,
};

const userReducer: Reducer<auth0.Auth0UserProfile & IUser> = (
  state = initialState,
  action,
): auth0.Auth0UserProfile & IUser => {
  switch (action.type) {
    case UserActionTypes.SET_USER_PROFILE: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    }
    case UserActionTypes.CLEAR_USER_PROFILE: {
      return {
        ...initialState,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export { userReducer };
