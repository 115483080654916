import * as moment from 'moment';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

interface ITopicInformationProps {
  topicMetadata: any;
}

const TopicInformation: React.FC<ITopicInformationProps> = ({ topicMetadata }) => {
  if (!topicMetadata) {
    return (
      <div>
        <ul className="topic-information">
          <li>
            <strong>Programme</strong>
            <p>
              <Skeleton height={12} />
            </p>
          </li>

          <li>
            <strong>Topic status</strong>
            <p>
              <Skeleton />
            </p>
          </li>

          <li>
            <strong>Call identifier</strong>
            <p>
              <Skeleton />
            </p>
          </li>

          <li>
            <strong>Call title</strong>
            <p>
              <Skeleton />
            </p>
          </li>

          <li>
            <strong>Publication date</strong>
            <p>
              <Skeleton />
            </p>
          </li>

          <li>
            <strong>Opening date</strong>
            <p>
              <Skeleton />
            </p>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div>
      <ul className="topic-information">
        {topicMetadata.foundation_name && (
          <li>
            <strong>Foundation</strong>
            {topicMetadata.foundation_name}
          </li>
        )}

        <li>
          <strong>Source name</strong>
          {topicMetadata.source_name}
        </li>

        <li>
          <strong>Call title</strong>
          {topicMetadata.title}
        </li>

        <li>
          <strong>Deadline date</strong>
          {formatDate(topicMetadata.deadline_date)}
        </li>

        <li>
          <strong>Opening date</strong>
          {moment(topicMetadata.opening_date).isValid()
            ? moment(topicMetadata.opening_date).format('DD.MM.YYYY')
            : 'N/A'}
        </li>

        <li>
          <strong>Elegible countries</strong>

          {topicMetadata.eligible_countries.map((country: string) => country)}
        </li>

        {topicMetadata.funding_types.length > 0 && (
          <li>
            <strong>Deadline date</strong>
            {topicMetadata.funding_types.map((fundingType: string) => fundingType)}
          </li>
        )}

        {topicMetadata.foundation_url || topicMetadata.url ? (
          <li>
            <strong>External links:</strong>
            <div className="external-links">
              {topicMetadata.foundation_url ? (
                <a className="action-button" target="_blank" href={topicMetadata.foundation_url}>
                  Foundation page
                </a>
              ) : null}

              {topicMetadata.url ? (
                <a className="action-button" target="_blank" href={topicMetadata.url}>
                  Call page
                </a>
              ) : null}
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

const formatDate = (dateToFormat: string) => {
  const deadlineDate = moment(dateToFormat).format('DD.MM.YYYY');
  if (deadlineDate === 'Invalid date') {
    return 'Ongoing';
  }
  return deadlineDate;
};

export default TopicInformation;
