import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { partnerSearchRequest } from '../PartnerSearch/actions';
import { searchRequest, selectTab, selectableTabs, setQuery } from '../Search/actions';
import * as actions from './actions';

import callApi from '../../utils/callApi';
import { nationalSearchRequest } from '../NationalSearch/actions';
import { searchProjectsRequest } from '../Projects/actions';

function* handleSaveQuery(action: any): any {
  const payload = action.payload;
  try {
    const res = yield call(callApi, 'post', process.env.REACT_APP_SEARCH_API_URL || '', `/v1/query`, payload);

    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      } else {
        switch (action.type) {
          case actions.QueryActionTypes.SAVE_QUERY_REQUEST: {
            yield put(actions.saveQueryResponse(res));
            break;
          }
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.queryError(err.message));
    } else {
      yield put(actions.queryError('An unknown error occured.'));
    }
  }
}

function* handleFetchQueries(action: any): any {
  try {
    const res = yield call(callApi, 'get', process.env.REACT_APP_SEARCH_API_URL || '', `/v1/query`);

    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      } else {
        switch (action.type) {
          case actions.QueryActionTypes.FETCH_QUERIES_REQUEST: {
            yield put(actions.fetchQueriesSuccess(res));
            break;
          }
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.queryError(err.message));
    } else {
      yield put(actions.queryError('An unknown error occured.'));
    }
  }
}

function* handleFetchQuery(action: any): any {
  try {
    const res = yield call(
      callApi,
      'get',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v1/query/${action.payload}`,
    );
    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      } else {
        switch (action.type) {
          case actions.QueryActionTypes.FETCH_QUERY_REQUEST: {
            yield put(actions.fetchQuerySuccess(res));
            if (res.scope === selectableTabs.FUNDING) {
              yield put(selectTab(selectableTabs.FUNDING));
              yield put(searchRequest({ query: res.query }));
            } else if (res.scope === selectableTabs.NATIONAL) {
              yield put(selectTab(selectableTabs.NATIONAL));
              yield put(nationalSearchRequest(res.query));
            } else if (res.scope === selectableTabs.PARTNERS) {
              yield put(selectTab(selectableTabs.PARTNERS));
              yield put(partnerSearchRequest(res.query));
            } else {
              yield put(selectTab(selectableTabs.PROJECTS));
              yield put(searchProjectsRequest({ query: res.query }));
            }
            yield put(setQuery({ query: res.query }));
            break;
          }
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.queryError(err.message));
    } else {
      yield put(actions.queryError('An unknown error occured.'));
    }
  }
}

function* saveQueryRequest() {
  yield takeEvery(actions.QueryActionTypes.SAVE_QUERY_REQUEST, handleSaveQuery);
}

function* fetchQueriesRequest() {
  yield takeEvery(actions.QueryActionTypes.FETCH_QUERIES_REQUEST, handleFetchQueries);
}

function* fetchQueryRequest() {
  yield takeEvery(actions.QueryActionTypes.FETCH_QUERY_REQUEST, handleFetchQuery);
}

function* querySaga() {
  yield all([fork(saveQueryRequest), fork(fetchQueriesRequest), fork(fetchQueryRequest)]);
}

export default querySaga;
