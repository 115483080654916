export const convertToCurrency = (num: number, maximumFractionDigits: number = 2): string => {
  return new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits,
  }).format(num);
};

export const convertToPercent = (num: number): number => {
  return Number((num * 100).toFixed());
};

export const getTrlNumber = (trl: string): number => {
  if (!isNaN(Number(trl.charAt(1)))) {
    return Number(trl.slice(0, 2));
  }
  return Number(trl.charAt(0)) || 0;
};

export const getThousands = (num: number) => {
  return Math.round(num / 1000);
};
