import * as React from 'react';

interface IAsideProps {
  loading: boolean;
  content: React.ReactNode;
  after?: React.ReactNode;
}

export const Aside = ({ loading, content, after = null }: IAsideProps) => {
  return (
    <>
      {content && (
        <div className="content-dialog__aside">
          <div className="content-dialog__aside__wrapper">{loading ? null : content}</div>
          {after}
        </div>
      )}
    </>
  );
};
