import { Icon } from '@fluentui/react/lib/Icon';
import * as React from 'react';
import './Accordion.css';

interface IAccordionProps {
  title?: string;
  showTitle?: string;
  hideTitle?: string;
  children: React.ReactNode;
}

export const Accordion = ({ title, showTitle = title, hideTitle = showTitle, children }: IAccordionProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="accordion">
      <button className="accordion__toggle" onClick={() => setIsOpen(!isOpen)}>
        <Icon iconName={isOpen ? 'ChevronUp' : 'ChevronDown'} />
        {isOpen ? hideTitle : showTitle}
      </button>

      <div className={`accordion__content${isOpen ? ' accordion__content--visible' : ''}`}>{children}</div>
    </div>
  );
};
