import { action } from 'typesafe-actions';

export interface IProjectQueryData {
  query: string;
  page?: number;
}

export interface IProjectResult {
  rcn: number;
  id: number;
  acronym: string;
  status: string;
  programme: string;
  topics: string;
  frameworkProgramme: string;
  title: string;
  startDate: string;
  endDate: string;
  projectUrl: string;
  objective: string;
  totalCost: string;
  ecMaxContribution: string;
  call: string;
  fundingScheme: string;
  coordinator: string;
  coordinatorCountry: string;
  participants: string;
  participantCountries: string;
  subjects: string;
  h2020_organizations?: any[];
  weight: number;
}

export const enum ProjectsActionTypes {
  SEARCH_PROJECTS_REQUEST = '@@projects/SEARCH_PROJECTS_REQUEST',
  SEARCH_PROJECTS_SUCCESS = '@@projects/SEARCH_PROJECTS_SUCCESS',
  FETCH_PROJECT_REQUEST = '@@projects/FETCH_PROJECT_REQUEST',
  FETCH_PROJECT_SUCCESS = '@@projects/FETCH_PROJECT_SUCCESS',
  PROJECTS_ERROR = '@@projects/PROJECTS_ERROR',
  SELECT_PROJECT = '@@projects/SELECT_PROJECT',
  CLEAR_SELECTED_PROJECT = '@@projects/CLEAR_SLEECTED_PROJECT',
}

export const searchProjectsRequest = (data: IProjectQueryData) =>
  action(ProjectsActionTypes.SEARCH_PROJECTS_REQUEST, data);

export const searchProjectsResponse = (data: IProjectResult[]) =>
  action(ProjectsActionTypes.SEARCH_PROJECTS_SUCCESS, data);

export const searchProjectsError = (data: {}) => action(ProjectsActionTypes.PROJECTS_ERROR, data);

export const fetchProjectRequest = (id: number) => action(ProjectsActionTypes.FETCH_PROJECT_REQUEST, id);

export const fetchProjectResponse = (data: IProjectResult) =>
  action(ProjectsActionTypes.FETCH_PROJECT_SUCCESS, data);

export const selectProject = (project: IProjectResult) => action(ProjectsActionTypes.SELECT_PROJECT, project);

export const clearSelectedProject = () => action(ProjectsActionTypes.CLEAR_SELECTED_PROJECT);
