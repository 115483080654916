import { ISettings } from './types';

export const validateSettings = (settings: Partial<ISettings>) => {
  let valid = true;
  const validateFunctions = {
    reminderInterval: validateReminderInterval,
    onboarding: () => true,
  };

  for (const key of Object.keys(settings)) {
    if (!(key in validateFunctions)) {
      valid = false;
    } else {
      // @ts-ignore
      valid = valid && validateFunctions[key](settings[key]);
    }
  }

  return valid;
};

const validateReminderInterval = (values: number[]) => {
  let valid = true;
  const validValues = [14, 30];

  for (const value of values) {
    valid = valid && validValues.includes(value);
  }

  return valid;
};
