import * as cn from 'classnames';
import * as React from 'react';
import { ITooltipProps, useTooltip } from '../../Tooltip';

type SearchResultDetailType = 'MATCH' | 'DEADLINE' | 'STARTED' | 'BUDGET' | 'RELEVANT_PROJECT' | 'OPENING';

interface ISearchResultDetailProps {
  value: string;
  label: string;
  highlight: boolean;
  type: SearchResultDetailType;
  tooltip?: ITooltipProps;
}

export const SearchResultDetail = ({ value, label, highlight, type, tooltip }: ISearchResultDetailProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { toggleTooltip, tooltip: tt } = useTooltip({
    ref,
    content: tooltip?.content,
    align: tooltip?.position,
  });

  return (
    <>
      <div
        ref={ref}
        className={cn('search-results__detail', `search-results__detail--${type.toLowerCase()}`, {
          'search-results__detail--highlight': highlight,
        })}
        onMouseEnter={() => toggleTooltip(true)}
        onMouseLeave={() => toggleTooltip(false)}
      >
        <div className="search-results__detail__value">{value}</div>

        <div className="search-results__detail__label">{label}</div>
      </div>

      {tt}
    </>
  );
};
