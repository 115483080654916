import { useCallback, useEffect, useRef } from 'react';

// Scrolls the dialog to fill the view when scrolled past the header
export const useWindowScrollPosition = () => {
  const ref = useRef<HTMLDivElement>(null);

  const handleWindowScroll = useCallback(() => {
    if (ref.current) {
      const y = Math.max(25, 120 - window.scrollY);
      ref.current.style.top = `${y}px`;
      ref.current.style.bottom = `${y}px`;
      ref.current.style.height = `calc(100vh - ${y}px - ${y}px)`;
    }
  }, []);

  useEffect(() => {
    handleWindowScroll();

    window.addEventListener('scroll', handleWindowScroll);

    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, []);

  return ref;
};
