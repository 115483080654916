import { Reducer } from 'redux';
import { QueryActionTypes } from './actions';

export interface IQuery {
  query: string;
  public: boolean;
  scope: string;
  identifier: string;
}

export interface IQueryState {
  previousQueries: IQuery[];
  selectedQuery: IQuery;
}

export const initialState: IQueryState = {
  previousQueries: [],
  selectedQuery: { query: '', public: false, scope: '', identifier: '' },
};

const queryReducer: Reducer<IQueryState> = (state = initialState, action): IQueryState => {
  switch (action.type) {
    case QueryActionTypes.FETCH_QUERIES_SUCCESS: {
      return {
        ...state,
        previousQueries: action.payload,
      };
    }
    case QueryActionTypes.FETCH_QUERY_SUCCESS: {
      return {
        ...state,
        selectedQuery: action.payload,
      };
    }
    case QueryActionTypes.SELECT_QUERY: {
      return {
        ...state,
        selectedQuery: action.payload,
      };
    }
  }
  return state;
};

export { queryReducer };
