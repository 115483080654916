import { Reducer } from 'redux';
import * as auth from '../Auth/Auth';
import { selectableTabs } from '../Search/actions';
import { NationalSearchActionTypes } from './actions';

export interface INationalSearchState {
  results: any[];
  isFetching: boolean;
  isClosedTopicsFetching: boolean;
  isDescriptionFetching: boolean;
  error: string;
  closedEntries: boolean;
  closedTopics: any[];
  query: string;
  exclude: any[];
  formValid: boolean;
  searchInputValid: boolean;
  selectedTab: string;
  orderCondition: any;
  selectedItems: any[];
  filterConditions: any[];
  selectedTopic: any;
  topicDescription: any;
}

export const initialState: INationalSearchState = {
  results: [],
  error: '',
  isFetching: false,
  isClosedTopicsFetching: false,
  isDescriptionFetching: false,
  closedTopics: [],
  query: '',
  closedEntries: false,
  exclude: [],
  formValid: false,
  searchInputValid: false,
  selectedTab: selectableTabs.NATIONAL,
  orderCondition: {
    name: 'Deadline date',
    key: 'deadline_date',
    value: 'deadline_date',
  },
  selectedItems: [],
  filterConditions: [
    { key: 'source_name', value: 'Research.fi' },
    { key: 'source_name', value: 'Business Finland' },
  ],
  selectedTopic: {},
  topicDescription: {},
};

const nationalSearchReducer: Reducer<INationalSearchState> = (
  state = initialState,
  action,
): INationalSearchState => {
  switch (action.type) {
    case NationalSearchActionTypes.NATIONAL_SEARCH_REQUEST: {
      return {
        ...state,
        selectedItems: [],
        topicDescription: {},
        isFetching: true,
      };
    }
    case NationalSearchActionTypes.NATIONAL_SEARCH_SUCCESS: {
      return {
        ...state,
        results: action.payload.results,
        isFetching: false,
        exclude: getIds(action.payload.results),
        error: '',
      };
    }
    case NationalSearchActionTypes.NATIONAL_SEARCH_MORE_REQUEST: {
      return { ...state, isFetching: true };
    }
    case NationalSearchActionTypes.NATIONAL_SEARCH_MORE_SUCCESS: {
      return {
        ...state,
        results: [...state.results, ...action.payload.results],
        isFetching: false,
        exclude: [...state.exclude, ...getIds(action.payload.results)],
      };
    }
    case NationalSearchActionTypes.NATIONAL_SEARCH_CLOSED_TOPICS_REQUEST: {
      return {
        ...state,
        isClosedTopicsFetching: true,
      };
    }
    case NationalSearchActionTypes.NATIONAL_SEARCH_CLOSED_TOPICS_SUCCESS: {
      return {
        ...state,
        isClosedTopicsFetching: false,
        closedTopics: [...state.closedTopics, ...action.payload],
      };
    }
    case NationalSearchActionTypes.NATIONAL_SEARCH_ERROR: {
      if (action.payload === 'invalid_token') {
        auth.login();
      }
      return { ...state, error: action.payload, isFetching: false };
    }
    case NationalSearchActionTypes.SET_QUERY: {
      if (action.payload.trim().length < 1) {
        return {
          ...state,
          formValid: false,
          searchInputValid: false,
          query: action.payload,
        };
      }
      return {
        ...state,
        formValid: true,
        searchInputValid: true,
        query: action.payload,
      };
    }
    case NationalSearchActionTypes.SET_FORM_VALID: {
      return { ...state, formValid: true, searchInputValid: true };
    }
    case NationalSearchActionTypes.SELECT_TAB: {
      return {
        ...state,
        selectedTab: action.payload,
        filterConditions:
          action.payload === selectableTabs.NATIONAL
            ? [
                { key: 'source_name', value: 'Research.fi' },
                { key: 'source_name', value: 'Business Finland' },
              ]
            : [],
      };
    }
    case NationalSearchActionTypes.SET_ORDER_CONDITION: {
      return { ...state, orderCondition: action.payload };
    }
    case NationalSearchActionTypes.ADD_FILTER_CONDITION: {
      return {
        ...state,
        filterConditions: [
          ...state.filterConditions,
          { key: action.payload.key, value: action.payload.value },
        ],
      };
    }
    case NationalSearchActionTypes.REMOVE_FILTER_CONDITION: {
      return {
        ...state,
        filterConditions: state.filterConditions.filter(
          (condition) => !(condition.key === action.payload.key && condition.value === action.payload.value),
        ),
      };
    }
    case NationalSearchActionTypes.CLEAR_FILTER: {
      if (state.selectedTab === selectableTabs.NATIONAL) {
        return {
          ...state,
          filterConditions: [
            { key: 'source_name', value: 'Research.fi' },
            { key: 'source_name', value: 'Business Finland' },
          ],
        };
      }

      return {
        ...state,
        filterConditions: [],
      };
    }
    case NationalSearchActionTypes.SELECT_ITEM: {
      const alreadySelected = state.selectedItems.some(
        (selectedItem) => selectedItem.id === action.payload.id,
      );
      if (alreadySelected) {
        return state;
      } else {
        return {
          ...state,
          selectedItems: [...state.selectedItems, action.payload],
        };
      }
    }
    case NationalSearchActionTypes.UNSELECT_ITEM: {
      const newTopicDescription = { ...state.topicDescription };
      delete newTopicDescription[action.payload.id];
      return {
        ...state,
        selectedItems: state.selectedItems.filter((item) => item.id !== action.payload.id),
        topicDescription: newTopicDescription,
      };
    }
    case NationalSearchActionTypes.FETCH_TOPIC_SUCCESS: {
      return {
        ...state,
        selectedTopic: action.payload,
      };
    }
    case NationalSearchActionTypes.CLEAR_SELECTED_TOPIC: {
      return {
        ...state,
        selectedTopic: {},
      };
    }
    case NationalSearchActionTypes.FETCH_TOPIC_DESCRIPTION_REQUEST: {
      return {
        ...state,
        isDescriptionFetching: true,
      };
    }
    case NationalSearchActionTypes.FETCH_TOPIC_DESCRIPTION_SUCCESS: {
      return {
        ...state,
        isDescriptionFetching: false,
        topicDescription: {
          ...state.topicDescription,
          [action.payload.id]: action.payload.highlighted_texts.description[0],
        },
        selectedTopic: action.payload,
      };
    }
    case NationalSearchActionTypes.CLEAR_TOPIC_DESCRIPTION: {
      return {
        ...state,
        topicDescription: {},
      };
    }
  }
  return state;
};

const getIds = (results: any) => {
  return [
    ...results.map((res: any) => {
      return res.id;
    }),
  ];
};

export { nationalSearchReducer };
