import * as React from 'react';
import * as classnames from 'classnames';
import { useCalendarContext } from './Context';
import * as moment from 'moment';
import type { Moment } from 'moment';

interface IDayProps {
  date: Moment;
}

export const Day = ({ date }: IDayProps) => {
  const { deadlines, month, openDate } = useCalendarContext();
  const sameMonth = date.isSame(month, 'month');
  const dayReminders = deadlines.filter((deadline) => deadline.deadline.isSame(date, 'date'));

  return (
    <div
      className={classnames('calendar__day', {
        'calendar__day--today': date.isSame(moment(), 'date'),
        'calendar__day--not-same-month': !sameMonth,
        'calendar__day--has-deadline': dayReminders.length > 0,
      })}
    >
      <button onClick={() => openDate(date)}>{date.format('D')}</button>
    </div>
  );
};

export default Day;
