import * as React from 'react';
import './RadioGroup.css';

interface IRadioGroupOption {
  label: string;
  value: any;
}

interface IRadioGroupProps {
  name: string;
  value: any;
  options: IRadioGroupOption[];
  onChange: (value: any) => void;
}

export const RadioGroup = ({ name, options, value, onChange }: IRadioGroupProps) => {
  return (
    <div className="radio-group">
      {options.map((option, index) => (
        <RadioOption
          key={`${name}-${index}`}
          id={`${name}-${index}`}
          name={name}
          {...option}
          checked={option.value === value}
          onChange={() => onChange(option.value)}
        />
      ))}
    </div>
  );
};

interface IRadioOptionProps {
  id: string;
  name: string;
  label: string;
  value: any;
  checked: boolean;
  onChange: () => void;
}

const RadioOption = ({ id, name, label, value, checked, onChange }: IRadioOptionProps) => {
  return (
    <label className="radio-group-option" htmlFor={id} aria-labelledby={`${id}-label`}>
      <input type="radio" id={id} name={name} value={value} onChange={onChange} checked={checked} />

      <p id={`${id}-label`}>{label}</p>
    </label>
  );
};
