import { Icon } from '@fluentui/react/lib/Icon';
import { createId } from '@paralleldrive/cuid2';
import * as classnames from 'classnames';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import IconArrowDown from '../../../../assets/icon--arrow--down.svg';
import IconArrowUp from '../../../../assets/icon--arrow--up.svg';

import { closeFilter, openFilter } from '../../../../app/Filter/actions';
import { IFilterState } from '../../../../app/Filter/reducer';
import { IState } from '../../../../reducers';
import { store } from '../../../../store';
import './Dropdown.css';

interface IClassName {
  customDropdownList?: string;
  customDropdownItem?: string;
  customDropdownItemName?: string;
}

interface IDropdownProps {
  items: any[];
  value: string;
  selectedConditions: string[];
  onSelect: (item: any) => void;
  label?: string;
  showIconWhenSelected?: boolean;
  iconName?: string;
  filter: IFilterState;
  identifier: string;
  className?: IClassName;
}

interface IDropdown {
  listOpen: boolean;
}

class Dropdown extends React.Component<IDropdownProps, IDropdown> {
  constructor(props: IDropdownProps) {
    super(props);

    this.isSelected = this.isSelected.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.isOpen = this.isOpen.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event: any) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || (!domNode.contains(event.target) && this.isOpen())) {
      this.toggleList();
    }
  };

  isOpen() {
    return this.props.filter.selectedFilter === this.props.identifier;
  }

  toggleList() {
    if (this.isOpen()) {
      store.dispatch(closeFilter());
    } else {
      store.dispatch(openFilter(this.props.identifier));
    }
  }

  isSelected(item: any) {
    const { selectedConditions } = this.props;
    return selectedConditions.filter((condition: any) => condition.value === item.value).length > 0;
  }

  render() {
    const { items, value, label, showIconWhenSelected, iconName, filter, identifier } = this.props;
    const listOpen = filter.selectedFilter === identifier;
    return (
      <div className="dropdown__wrapper">
        <div className="dropdown__header">
          <button className="dropdown__header--title" onClick={this.toggleList}>
            {label && <div className="dropdown__label">{label}</div>}
            <div className="dropdown__value">
              {value}
              <img src={listOpen ? IconArrowUp : IconArrowDown} alt="" />
            </div>
          </button>

          <div className="dropdown__list-container">
            {listOpen && (
              <ul
                className={classnames(
                  'dropdown__list',
                  this.props.className && this.props.className.customDropdownList,
                )}
              >
                {items.map((item) => {
                  return (
                    <li
                      className={classnames(
                        `dropdown__item`,
                        `${this.isSelected(item) ? 'dropdown__item--selected' : null}`,
                        this.props.className && this.props.className.customDropdownItem,
                      )}
                      key={createId()}
                      onClick={() => this.props.onSelect(item)}
                    >
                      <div
                        className={classnames(
                          'dropdown__item--name',
                          this.props.className && this.props.className.customDropdownItemName,
                        )}
                      >
                        {item.name}
                      </div>

                      {showIconWhenSelected && this.isSelected(item) && (
                        <div className="dropdown__item--icon">
                          <Icon iconName={iconName} />
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ filter }: IState) => {
  return { filter };
};

export default connect(mapStateToProps)(Dropdown);
