import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';

import callApi from '../../utils/callApi';

function* handleFetch(action: any): any {
  const id: number = action.payload;

  try {
    const res = yield call(
      callApi,
      'get',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v1/partner_search/${id}`,
    );
    if (res) {
      if (res.description) {
        throw new Error(res.description);
      }
      if (res.error) {
        throw new Error(res.message);
      }
      switch (action.type) {
        case actions.PartnerSearchActionTypes.FETCH_PARTNER_REQUEST: {
          yield put(actions.fetchPartnerResponse(res));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.partnerSearchError(err.stack!));
    } else {
      yield put(actions.partnerSearchError('An unknown error occured.'));
    }
  }
}

function* handleSearch(action: any): any {
  const payload = {
    query: action.payload,
  };

  try {
    const res = yield call(
      callApi,
      'post',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v1/partner_search`,
      payload,
    );

    if (res) {
      if (res.description) {
        throw new Error(res.description);
      }
      if (res.error) {
        throw new Error(res.message);
      }
      switch (action.type) {
        case '@@partnerSearch/REQUEST': {
          yield put(actions.partnerSearchResponse(res));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.partnerSearchError(err.stack!));
    } else {
      yield put(actions.partnerSearchError('An unknown error occured.'));
    }
  }
}

function* watchFetchPartnerRequest() {
  yield takeEvery(actions.PartnerSearchActionTypes.FETCH_PARTNER_REQUEST, handleFetch);
}

function* watchPartnerSearchRequest() {
  yield takeEvery(actions.PartnerSearchActionTypes.PARTNER_SEARCH_REQUEST, handleSearch);
}

function* partnerSearchSaga() {
  yield all([fork(watchPartnerSearchRequest)]), yield all([fork(watchFetchPartnerRequest)]);
}

export default partnerSearchSaga;
