import * as React from 'react';
import * as moment from 'moment';
import type { Moment } from 'moment';
import { useCalendarContext } from './Context';
import Week from './Week';
import IconArrowLeft from '../../assets/icon--arrow--left2.svg';
import IconArrowRight from '../../assets/icon--arrow--right.svg';

interface IMonthProps {}

export const Month = ({}: IMonthProps) => {
  const { month, previousMonth, nextMonth } = useCalendarContext();
  let monthName = month.format('MMMM');
  const previousMonthName = month.clone().subtract(1, 'month').format('MMM');
  const nextMonthName = month.clone().add(1, 'month').format('MMM');
  const weeks = getWeeks(month);

  if (!month.isSame(moment(), 'year')) {
    monthName += ` ${month.get('year')}`;
  }

  const weekdays = Array.from(new Array(7)).map((_: never, i: number) => moment().day((i + 1) % 7));

  return (
    <div className="calendar__month">
      <div className="calendar__month__header">
        <button onClick={previousMonth}>
          <img src={IconArrowLeft} alt={previousMonthName} />
        </button>

        <p className="calendar__month__name">{monthName}</p>

        <button onClick={nextMonth}>
          <img src={IconArrowRight} alt={nextMonthName} />
        </button>
      </div>

      <div className="calendar__week-header">
        {weekdays.map((day) => (
          <p key={day.toISOString()}>{day.format('ddd')}</p>
        ))}
      </div>

      {weeks.map((week) => (
        <Week key={week.format('yyyy-w')} week={week} />
      ))}
    </div>
  );
};

export default Month;

const getWeeks = (month: Moment) => {
  const weeks = [];
  const startOfMonth = moment(month).startOf('month');
  const date = moment(startOfMonth);
  date.subtract(date.get('day'), 'days');

  const days = [];
  while (date.isSameOrBefore(startOfMonth, 'month')) {
    days.push(moment(date));
    date.add(1, 'day');
  }

  if (startOfMonth.get('day') === 0) {
    weeks.push(moment(startOfMonth).subtract(6, 'days'));
  }

  for (const day of days) {
    if (day.get('day') === 1) {
      weeks.push(moment(day));
    }
  }

  return weeks;
};
