import * as moment from 'moment';
import * as React from 'react';

import { connect } from 'react-redux';

import { IColumn } from '@fluentui/react/lib/DetailsList';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { createId } from '@paralleldrive/cuid2';
import memoize from 'memoize-one';

import {
  INationalSearchResult,
  nationalSearchMoreRequest,
  selectNationalItem,
  unSelectNationalItem,
} from '../../../app/NationalSearch/actions';
import { removeSavedDocumentRequest, saveDocumentRequest } from '../../../app/Saved/actions';
import { selectableTabs } from '../../../app/Search/actions';
import { IState } from '../../../reducers';
import { store } from '../../../store';
import { containsValue } from '../../../utils/filter';
import { ActionButton, DefaultButton } from '../../ActionButton/ActionButton';
import NationalSearchResult from './NationalSearchResults/NationalSearchResult';
import { Share } from './NationalSearchResults/NationalSearchResult/Share';
import ResultLabel from './ResultLabel';

import { isTrialUser } from '../../../app/Auth/Auth';
import { SearchResultDetail } from './SearchResultDetail';
import './SearchResults.css';

interface ICallRowProps {
  index: number;
  call: INationalSearchResult;
  selected: boolean;
  saved: boolean;
  selectCall: (call: any) => void;
}

const CallRow: React.FC<ICallRowProps> = ({ index, call, selected, saved, selectCall }) => {
  const handleReadMore = () => {
    store.dispatch(selectNationalItem(call));
    selectCall(call);
    document.body.classList.add('preventModalScrolling');
  };

  const toggleSelected = () => {
    if (!selected) {
      store.dispatch(selectNationalItem(call));
    } else {
      store.dispatch(unSelectNationalItem(call));
    }
  };

  const handleSave = () => {
    store.dispatch(
      saveDocumentRequest({
        scope: selectableTabs.NATIONAL,
        objectId: call.topic_id,
        title: `${call.title}`,
        deadline: call.deadline_date !== 'N/A' ? moment(call.deadline_date).format() : undefined,
      }),
    );
  };

  const handleUnsaved = () => {
    store.dispatch(removeSavedDocumentRequest(call.topic_id));
  };

  const getApplicationUrl = () => {
    const applicationUrl = call.application_url;
    if (applicationUrl.startsWith('http')) {
      return (
        <a className="search-results__detail__url" target="blank" href={applicationUrl}>
          Go to application
        </a>
      );
    }
    return 'URL not provided';
  };

  return (
    <div className="search-results--row">
      {!isTrialUser() ? (
        <div className="search-results--first-column">
          <div className="search-results--result-name">{call.title}</div>

          <div className="search-results--result-description">{call.description.replace(' ...', '...')}</div>

          <div className="search-results--buttons">
            <div className="search-results--buttons__default">
              <DefaultButton text="Read more" handleClick={handleReadMore} />
            </div>

            <div className="search-results--buttons__action">
              <ActionButton text="Select" handleClick={toggleSelected} selected={selected} />
              {saved ? (
                <ActionButton text="Unsave" handleClick={handleUnsaved} selected={true} />
              ) : (
                <ActionButton text="Save" handleClick={handleSave} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="search-results--first-column">
          {isTrialUser() && index < 3 ? (
            <div>
              <div className="search-results--result-name">{call.title}</div>

              <div className="search-results--result-description">
                {call.description.replace(' ...', '...')}
              </div>

              <div className="search-results--buttons">
                <div className="search-results--buttons__default">
                  <DefaultButton text="Read more" handleClick={handleReadMore} />
                </div>

                <div className="search-results--buttons__action">
                  <ActionButton text="Select" handleClick={toggleSelected} selected={selected} />
                  {saved ? (
                    <ActionButton text="Unsave" handleClick={handleUnsaved} selected={true} />
                  ) : (
                    <ActionButton text="Save" handleClick={handleSave} />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="search-results--subscribe">
              <h1>
                {'To see result details and unlock share feature please '}
                <a className="" target="blank" href="https://spinbase.eu/pricing/">
                  {'upgrade your subscription'}
                </a>
              </h1>
            </div>
          )}
        </div>
      )}

      <div className="search-results--second-column-national">
        <div className="search-results__details">
          <SearchResultDetail
            value={call.deadline_date !== 'N/A' ? moment(call.deadline_date).format('DD.MM.YYYY') : 'Ongoing'}
            label={'Deadline'}
            highlight={false}
            type="DEADLINE"
            tooltip={{
              content: <p>Deadline for this topic.</p>,
              position: 'topLeft',
            }}
          />

          <SearchResultDetail
            value={call.opening_date !== 'N/A' ? moment(call.opening_date).format('DD.MM.YYYY') : 'N/A'}
            label="Opening"
            highlight={false}
            type="OPENING"
            tooltip={{
              content: <p>Opening date for this funding</p>,
              position: 'topRight',
            }}
          />
        </div>

        <div className="search-results--label-area">
          <ResultLabel
            label="To apply"
            value={getApplicationUrl()}
            tooltip={{
              content: <p>If provided, apply by going to the website</p>,
              position: 'topLeft',
            }}
          />

          {
            <ResultLabel
              label="Source name"
              value={call.source_name}
              tooltip={{
                content: 'Name of the funding source',
                position: 'topLeft',
              }}
            />
          }
        </div>

        <Share
          topicMetadata={{
            index: index,
            title: call.title,
            deadline_date: call.deadline_date,
            foundation_url: call.foundation_url,
          }}
        />
      </div>
    </div>
  );
};

interface IPropsFromState {
  nationalSearchResults: any;
  saved: any;
  user: auth0.Auth0UserProfile;
  onLoadMore: (v: any) => void;
}

interface INationalSearchProps {
  query: string;
  source_name?: string[];
}

export interface IDetailsList {
  sortedItems: any[];
  searchResultOffset: number;
  selectedItems: any[];
  columns: IColumn[];
  showPanel: boolean;
  selectedCallId: string;
}

class NationalSearchResults extends React.Component<IPropsFromState, IDetailsList> {
  constructor(props: IPropsFromState) {
    super(props);

    this.state = {
      sortedItems: [],
      selectedItems: [],
      columns: [],
      showPanel: false,
      selectedCallId: '',
      searchResultOffset: 0,
    };

    this.selectCall = this.selectCall.bind(this);
    this.getSelectedItem = this.getSelectedItem.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(props: IPropsFromState) {
    this.filterItems(props.nationalSearchResults.results);
  }

  isSelected(call: any): boolean {
    return !!this.props.nationalSearchResults.selectedItems.find((obj: any) => obj.id === call.id);
  }

  isSaved(call: any): boolean {
    return !!this.props.saved.savedDocuments.find(
      (obj: any) => obj.objectId === call.topic_id && obj.scope === selectableTabs.NATIONAL,
    );
  }

  selectCall(call: any): void {
    this.setState({
      selectedCallId: call.id,
      showPanel: true,
    });
  }

  render() {
    const { isFetching, results } = this.props.nationalSearchResults;
    const { showPanel, sortedItems } = this.state;

    const loadingSpinnerStyling = {
      display: 'flex',
      height: '50vh',
      justifyContent: 'center',
    };

    return (
      <div className="search-results--container">
        <div
          className="search-results--content"
          style={isFetching ? { opacity: 0.5, pointerEvents: 'none' } : {}}
        >
          {results &&
            results.length > 0 &&
            sortedItems
              .filter(this.filterCondition)
              .sort(this.compare)
              .map((result: INationalSearchResult, index: number) => {
                return (
                  <CallRow
                    index={index}
                    call={result}
                    selected={this.isSelected(result)}
                    key={createId()}
                    saved={this.isSaved(result)}
                    selectCall={this.selectCall}
                  />
                );
              })}
        </div>

        {showPanel ? (
          <NationalSearchResult
            topic={this.getSelectedItem()}
            onDismiss={() => this.setState({ showPanel: false })}
          />
        ) : null}

        {!isFetching && results.length > 0 && (
          <ActionButton text="Load more" handleClick={this.handleLoadMore} />
        )}

        {isFetching && (
          <div style={loadingSpinnerStyling}>
            <Spinner />
          </div>
        )}
      </div>
    );
  }

  filterCondition = (item: any) => {
    const { filterConditions } = this.props.nationalSearchResults;

    return containsValue(item, filterConditions); // && this.removeOpenInProjects(item);
  };

  compare = (a: any, b: any) => {
    const { orderCondition } = this.props.nationalSearchResults;

    switch (orderCondition.key) {
      case 'deadline_date':
        if (a[orderCondition.key] === 'N/A') {
          return 1;
        }

        if (b[orderCondition.key] === 'N/A') {
          return -1;
        }

        const mb = moment(b[orderCondition.key]);
        const ma = moment(a[orderCondition.key]);

        return mb.isBefore(ma) ? 1 : mb.isAfter(ma) ? -1 : 0;
      case 'opening_date':
        if (a[orderCondition.key] === 'N/A') {
          return 1;
        }

        if (b[orderCondition.key] === 'N/A') {
          return -1;
        }

        const mc = moment(b[orderCondition.key]);
        const md = moment(a[orderCondition.key]);

        return mc.isBefore(md) ? 1 : mc.isAfter(md) ? -1 : 0;
      case 'similarity':
        return b[orderCondition.key] - a[orderCondition.key];
      // case 'trl_text':
      //   const aTrlNumber = a[orderCondition.key] ? getTrlNumber(a[orderCondition.key]) : -1;
      //   const bTrlNumber = b[orderCondition.key] ? getTrlNumber(b[orderCondition.key]) : -1;
      //   return bTrlNumber - aTrlNumber;
      // case 'budget_numbers':
      //   const aBudget = getTotalBudget(a.budget_groups as IBudgetGroup[]);
      //   const bBudget = getTotalBudget(b.budget_groups as IBudgetGroup[]);
      //   return bBudget - aBudget;
      default:
        return a[orderCondition.key] - b[orderCondition.key];
    }
  };

  public filterItems = memoize((originalList: any[]) => {
    const newList = [
      ...originalList.map((item: any) => {
        return {
          id: item.id,
          key: item.id,
          applicant_types: item.metadata.applicant_types,
          application_term: item.metadata.application_terms,
          application_url: item.metadata.application_url,
          call_identifier: item.metadata.title, // missing field, for now is the same as title
          call_title: item.metadata.title,
          categories: item.metadata.categories,
          deadline_date: item.metadata.deadline_date,
          deadline_ongoing: item.metadata.deadline_ongoing,
          description: item.summary.preview,
          eligible_countries: item.metadata.eligible_countries,
          foundation_name: item.metadata.foundation_name,
          foundation_url: item.metadata.foundation_url,
          funding_types: item.metadata.funding_types,
          html_desc: item.metadata.application_terms,
          keywords: item.metadata.keywords,
          opening_date: item.metadata.opening_date,
          similarity: '0', // missing field
          source: item.metadata.source,
          source_name: item.metadata.source_name,
          title: item.metadata.title,
          topic_id: item.id,
          topic_url: item.metadata.url,
        };
      }),
    ];
    this.setState({ sortedItems: newList });
  });

  private getSelectedItem() {
    return this.props.nationalSearchResults.results.find(
      (item: any) => item.id === this.state.selectedCallId,
    );
  }

  private handleLoadMore() {
    this.setState(
      {
        searchResultOffset: this.state.searchResultOffset + 30,
      },
      () => {
        const sourceName = this.props.nationalSearchResults.filterConditions
          .filter((filter: any) => filter.key === 'source_name')
          .map(({ value }: any) => value);

        this.props.onLoadMore({
          sourceName,
          query: this.props.nationalSearchResults.query,
          offset: this.state.searchResultOffset,
          limit: 30,
        });
      },
    );
  }
}

const mapStateToProps = ({ nationalSearchResults, user, saved }: IState) => {
  return { nationalSearchResults, user, saved };
};
const mapDispatchToProps = (dispatch: any) => ({
  onLoadMore: (data: INationalSearchProps) => dispatch(nationalSearchMoreRequest({ ...data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(NationalSearchResults);
