import * as React from 'react';
import * as moment from 'moment';
import type { Moment } from 'moment';
import { ISaved, ISavedState } from '../../app/Saved/reducer';

interface IDeadline {
  title: string;
  deadline: moment.Moment;
}

interface ICalendarContext {
  loading: boolean;
  open: boolean;
  deadlines: IDeadline[];
  month: Moment;
  viewDate?: Moment;
  previousMonth: () => void;
  nextMonth: () => void;
  openCalendar: () => void;
  closeCalendar: () => void;
  openDate: (viewDate: Moment) => void;
  closeDate: () => void;
}

const defaultContext = {
  loading: false,
  open: false,
  deadlines: [],
  month: moment(),
  viewDate: undefined,
  previousMonth: () => undefined,
  nextMonth: () => undefined,
  openCalendar: () => undefined,
  closeCalendar: () => undefined,
  openDate: () => undefined,
  closeDate: () => undefined,
};

export const CalendarContext = React.createContext<ICalendarContext>(defaultContext);

export const useCalendarContext = () => {
  return React.useContext(CalendarContext);
};

interface ICalendarContextProviderProps {
  saved: ISavedState;
  children: React.ReactNode;
}

export const CalendarContextProvider = ({ saved, children }: ICalendarContextProviderProps) => {
  const [month, setMonth] = React.useState(moment());
  const [viewDate, setViewDate] = React.useState<Moment | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [deadlines, setDeadlines] = React.useState<IDeadline[]>([]);

  const openCalendar = React.useCallback(() => setOpen(true), []);
  const closeCalendar = React.useCallback(() => {
    setOpen(false);
    setMonth(moment());
  }, []);
  const previousMonth = React.useCallback(
    () => setMonth((getCurrentMonth) => getCurrentMonth.clone().subtract(1, 'month')),
    [],
  );
  const nextMonth = React.useCallback(
    () => setMonth((getCurrentMonth) => getCurrentMonth.clone().add(1, 'month')),
    [],
  );
  const openDate = React.useCallback((getCurrentDateData: Moment) => setViewDate(getCurrentDateData), []);
  const closeDate = React.useCallback(() => setViewDate(undefined), []);

  React.useEffect(() => {
    if (saved.loading !== loading) {
      setLoading(saved.loading);
    }

    setDeadlines(
      saved.savedDocuments.filter(filterBySavedScope).map((save) => ({
        title: save.title,
        deadline: moment(save.deadline),
      })),
    );
  }, [saved]);

  const value = {
    loading,
    open,
    deadlines,
    month,
    viewDate,
    previousMonth,
    nextMonth,
    openCalendar,
    closeCalendar,
    openDate,
    closeDate,
  };

  return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
};

const filterBySavedScope = (save: ISaved) => {
  return save.deadline && save.deadline !== '0000-00-00' && save.scope === 'FUNDING';
};
