import * as React from 'react';

import { connect } from 'react-redux';

import { IState } from '../../../../reducers';

import { exportNationalTopicsRequest } from '../../../../app/Export/actions';
import { fetchNationalTopicDescription } from '../../../../app/NationalSearch/actions';

import Dropdown from '../Filter/Dropdown';

import { ExportNationalTopicsWholeText } from './ExportNationalTopicsWholeText';
import './GroupActions.css';

interface IProps {
  nationalSearchResults: any;
  user: auth0.Auth0UserProfile;
  onExport: (v: any) => void;
  fetchTopicDescription: (v: any) => void;
}

interface IExportCreationOpt {
  value: string;
  key: string;
}
interface IGroupActions {
  exportClicked: boolean;
  exportCreationOpt: IExportCreationOpt;
  exportWholeText: boolean;
}

class GroupActions extends React.Component<IProps, IGroupActions> {
  keys = [
    // { key: 'summaryCall', name: 'Summary list (.ppt)', value: 'Summary list (.ppt)' },
    { key: 'wholeCall', name: 'Whole text (.doc)', value: 'Whole text (.doc)' },
  ];
  constructor(props: IProps) {
    super(props);

    this.state = {
      exportClicked: false,
      exportCreationOpt: {
        value: this.keys[0].value,
        key: this.keys[0].key,
      },
      exportWholeText: false,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (
      this.state.exportClicked &&
      this.state.exportCreationOpt.key === 'wholeCall' &&
      nextProps.nationalSearchResults.topicDescription &&
      Object.keys(nextProps.nationalSearchResults.topicDescription).length ===
        nextProps.nationalSearchResults.selectedItems.length
    ) {
      this.setState({ exportWholeText: true });
    } else {
      this.setState({ exportWholeText: false });
    }
  }
  render() {
    const { exportCreationOpt } = this.state;
    const { nationalSearchResults /*, user*/, onExport } = this.props;
    // Fixed previous lint error, by making custom variable called
    // "getSelectedValue". How this function works is that, when called
    // it gets current selected data and passes the "topidcId" and
    // "query" objects for dispatch purpose and continue from there.
    const getSelectedValue = this.props.fetchTopicDescription;
    const { selectedItems, topicDescription } = nationalSearchResults;
    const exportSummaryList = () => {
      onExport(selectedItems.map((selection: any) => selection.id));
    };

    const handleExport = (item: any) => {
      this.setState(
        {
          exportClicked: true,
          exportCreationOpt: {
            value: item.value,
            key: item.key,
          },
        },
        () => {
          if (item.key === 'wholeCall') {
            selectedItems.map((topic: any) => {
              getSelectedValue({
                topicId: topic.id,
                query: nationalSearchResults.query,
              });
            });
          } else {
            exportSummaryList();
          }
        },
      );
    };

    const setExportClicked = (getExportClickedStatus: boolean) => {
      this.setState({
        exportClicked: getExportClickedStatus,
      });
    };

    return (
      <>
        {selectedItems && selectedItems.length > 0 && (
          <Dropdown
            className={{
              customDropdownList: 'dropdown__list-custom',
              customDropdownItem: 'dropdown__item-custom',
              customDropdownItemName: 'dropdown__item--name-custom',
            }}
            label="Export"
            items={this.keys}
            value={exportCreationOpt.value}
            onSelect={handleExport}
            showIconWhenSelected={true}
            iconName="CheckMark"
            identifier="powerpoint"
            selectedConditions={[exportCreationOpt.value]}
          />
        )}

        {this.state.exportWholeText && (
          <ExportNationalTopicsWholeText
            setExportClicked={(exportClicked: boolean) => setExportClicked(exportClicked)}
            selectedItems={selectedItems}
            topicDescription={topicDescription}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ nationalSearchResults, user }: IState) => {
  return { nationalSearchResults, user };
};

const mapDispatchToProps = (dispatch: any) => ({
  onExport: (data: any[]) => dispatch(exportNationalTopicsRequest({ export: data })),
  fetchTopicDescription: (id: number) => dispatch(fetchNationalTopicDescription(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupActions);
