import * as React from 'react';
import { useEffect } from 'react';

export type TooltipAlignment =
  | 'top'
  | 'topLeft'
  | 'topRight'
  | 'bottom'
  | 'bottomLeft'
  | 'bottomRight'
  | 'left'
  | 'right';

interface IUseTooltipArgs {
  ref: React.MutableRefObject<HTMLElement | null>;
  content: React.ReactNode;
  align?: TooltipAlignment;
}

interface ITooltipPosition {
  top: number;
  left: number;
}

export const useTooltip = ({ ref, content, align = 'bottom' }: IUseTooltipArgs) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [position, setPosition] = React.useState<ITooltipPosition>({
    top: -1,
    left: -1,
  });
  const toggleTooltip = (force?: boolean) =>
    setIsOpen((tooltipStatus) => (typeof force !== 'undefined' ? force : !tooltipStatus));
  const isBottom = ['bottom', 'bottomLeft', 'bottomRight'].includes(align);

  useEffect(() => {
    if (isOpen && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const top = ref.current.offsetTop + (isBottom ? rect.height : 0);
      const left = ref.current.offsetLeft + rect.width / 2;

      setPosition({
        top,
        left,
      });
    }
  }, [isOpen, isBottom]);

  if (!content) {
    return {
      toggleTooltip: () => undefined,
      tooltip: null,
    };
  }

  return {
    toggleTooltip,
    tooltip: <TooltipContent show={isOpen} align={align} position={position} content={content} />,
  };
};

interface ITooltipContentProps {
  show: boolean;
  align: TooltipAlignment;
  position: ITooltipPosition;
  content: React.ReactNode;
}

const TooltipContent = ({ show, align, position, content }: ITooltipContentProps) => {
  if (!show || position.top < 0) {
    return null;
  }

  return (
    <div
      className={`tooltip__content tooltip__content--${align}`}
      style={{
        top: position.top,
        left: position.left,
      }}
    >
      {content}
    </div>
  );
};
