import * as React from 'react';
import * as cn from 'classnames';

const highlights = {
  blue: 'TRL level',
  green: '€ Amount of funding available for each proposal under this call',
  yellow: 'Most relevant sentence to your text query',
  orange: 'Queried terms in document title, keywords, tags, summary terms and description',
};

export const HighlightLegend = () => {
  return (
    <div className="highlight-legends">
      {Object.entries(highlights).map(([color, text]) => (
        <div key={color} className={cn('highlight-legend', `highlight-legend--${color}`)}>
          <p>{text}</p>
        </div>
      ))}
    </div>
  );
};
