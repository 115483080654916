import * as moment from 'moment';
import * as React from 'react';
import { fetchHealthCheck } from '../../utils/healthCheck';

interface IHealthCheck {
  vxtStatusCode: number | null;
  vxtStatusText: string;
  backendStatusCode: number | null;
  backendStatusText: string;
  requestTime: string;
}

const HealthCheckPage: React.FC = () => {
  const newRequest = {
    vxtStatusCode: null,
    vxtStatusText: '',
    backendStatusCode: null,
    backendStatusText: '',
    requestTime: '',
  };
  const [request, setRequest] = React.useState<IHealthCheck>(newRequest);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    handleHealthCheck();
  }, [setRequest]);

  const handleHealthCheck = async () => {
    const response = await fetchHealthCheck();

    if (typeof response !== 'object') {
      setErrorMessage(response);
    } else {
      const currentTime =
        moment(response.date).format('dddd, DD MMMM YYYY') +
        ' at ' +
        moment(response.date).format('HH:mm:ss') +
        ' GMT' +
        moment(response.date).format('Z');
      setRequest({
        vxtStatusCode: response.status,
        vxtStatusText: response.statusText,
        backendStatusCode: response.backendStatus,
        backendStatusText: response.message,
        requestTime: currentTime,
      });
    }
  };

  if (!request.backendStatusCode) {
    return (
      <div>
        <h1>{`${errorMessage || 'Fetching'} from server`}</h1>
      </div>
    );
  }

  return (
    <div>
      <h1>{`Request to server successful with message: ${request.backendStatusText}`}</h1>

      <h2>{`Status code: ${request.backendStatusCode}`}</h2>

      <br />

      <h1>{`Server's request to VXT database with Status Text: ${request.vxtStatusText}`}</h1>

      <h2>{request.requestTime}</h2>

      <h2>{`Status code: ${request.vxtStatusCode}`}</h2>
    </div>
  );
};

export default HealthCheckPage;
