import * as React from 'react';
import { connect } from 'react-redux';
import { setSettings, updateSettings } from '../../../app/Settings/actions';
import { ISettingsState } from '../../../app/Settings/reducer';
import { Onboarding } from '../../../app/Settings/types';
import { IState } from '../../../reducers';
import { store } from '../../../store';

import IntroFilter from '../../../assets/intro__filtering.svg';
import IntroKeyboard from '../../../assets/intro__keyboard.svg';
import IntroSearch from '../../../assets/intro__search.svg';
import './Intro.css';

interface IPropsFromState extends ISettingsState {}

interface IIntroState {
  dontShowAgain: boolean;
}

class Intro extends React.Component<IPropsFromState, IIntroState> {
  state = {
    dontShowAgain: false,
  };

  constructor(props: IPropsFromState) {
    super(props);

    this.handleDontShowAgain = this.handleDontShowAgain.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  handleDontShowAgain() {
    this.setState({ dontShowAgain: !this.state.dontShowAgain });
  }

  handleContinue() {
    if (this.state.dontShowAgain) {
      store.dispatch(updateSettings({ onboarding: Onboarding.HIDE_INTRO }));
    } else {
      store.dispatch(
        setSettings({
          ...this.props.settings,
          onboarding: Onboarding.HIDE_INTRO,
        }),
      );
    }
  }

  render() {
    return (
      <div className="intro">
        <div className="intro__content">
          <div className="intro__content__item keyboard">
            <img src={IntroKeyboard} alt="Text input" />
            <div>
              <p>
                <strong>Type in</strong> your desired text to obtain the most fitting result.
              </p>

              <p>
                You can also <strong>copy-paste</strong> large texts or <strong>enter keywords</strong>{' '}
                related to the subject you have in mind
              </p>
            </div>
          </div>

          <div className="intro__content__item search">
            <img src={IntroSearch} alt="Search" />
            <div>
              <p>
                Look for <strong>funding opportunities</strong>, <strong>partnerships</strong> or{' '}
                <strong>projects</strong> based on your result's:
              </p>

              <ul>
                <li>Match percentage</li>

                <li>Budget</li>

                <li>Call's deadline</li>
              </ul>
            </div>
          </div>

          <div className="intro__content__item filtering">
            <div className="filtering__inner">
              <div>
                <p>
                  <strong>Filtering</strong>
                </p>
              </div>

              <img src={IntroFilter} alt="Filtering" />
              <ol>
                <li>Type of action</li>

                <li>Call programme</li>

                <li>Call status</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="intro__actions">
          <div className="intro__dont-show-again">
            <input
              type="checkbox"
              id="intro__dont-show-again"
              checked={this.state.dontShowAgain}
              onChange={this.handleDontShowAgain}
            />

            <label htmlFor="intro__dont-show-again">
              Don't show this again
              <span className="checkbox">
                {this.state.dontShowAgain ? (
                  <svg width="22" height="22" viewBox="0 0 22 22">
                    <path
                      d="M1.764 12.009l6.727 7.662L20.264 1.732"
                      fill="none"
                      stroke="#404040"
                      strokeLinecap="round"
                      strokeWidth="2.5"
                    />
                  </svg>
                ) : null}
              </span>
            </label>
          </div>

          <button onClick={this.handleContinue}>Continue</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => state.settings;

export default connect(mapStateToProps)(Intro);
