import * as React from 'react';
import Modal from '../../Modal/Modal';
import * as auth from '../../../app/Auth/Auth';

export const TrialEnded = () => {
  return (
    <Modal>
      <h3>Your trial period has ended</h3>
      <hr />
      <p>
        Please visit{' '}
        <a target="_blank" href="https://spinbase.eu/#team">
          spinbase.eu
        </a>{' '}
        and contact us in order to continue using the service.
      </p>
    </Modal>
  );
};

export const AccessNotAllowed = () => {
  return (
    <Modal>
      <h3>You do not have appropriate right to use the spinbase service</h3>
      <hr />

      <p>
        Please visit{' '}
        <a target="_blank" href="https://spinbase.eu/#team">
          spinbase.eu
        </a>{' '}
        and contact us in order to continue using the service.
      </p>
    </Modal>
  );
};

export const TrialEnding = () => {
  return (
    <Modal>
      <h3>Your trial period will end in {auth.getTrialDaysLeft()} days.</h3>
      <hr />

      <p>
        Please visit{' '}
        <a target="_blank" href="https://spinbase.eu/#team">
          spinbase.eu
        </a>{' '}
        and contact us in order to continue using the service.
      </p>
    </Modal>
  );
};

export const EmailNotVerified = () => {
  return (
    <Modal>
      <h3>Please verify your email address</h3>

      <hr />

      <p>Your email address has not been verified. Please follow the link sent to your email.</p>
    </Modal>
  );
};
