import type { Moment } from 'moment';
import * as moment from 'moment';
import * as React from 'react';
import { isTrialUser } from '../../../../../app/Auth/Auth';

const emailTemplate = `Hello,

I found the following funding opportunity in Spinbase that might interest you:

Call title ”{{title}}".  {{deadline}}. Programme: {{programme}}, Budget: {{budget}}


You can view it here: https://spinbase.eu/`;

export const Share = ({ topicMetadata }: { topicMetadata: any }) => {
  const [showShare, setShowShare] = React.useState(false);
  const emailShareText = replaceTemplate(emailTemplate, topicMetadata);

  return (
    <div
      className="share-call"
      onClick={() => {
        isTrialUser() && topicMetadata.index >= 3 ? '' : setShowShare(!showShare);
      }}
    >
      <svg
        viewBox="0 0 27 34"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
      >
        <path
          d="M18.247 9.799c.866.56 1.876.858 2.907.858 2.938 0 5.356-2.418 5.356-5.356 0-2.939-2.418-5.356-5.356-5.356-2.938 0-5.356 2.417-5.356 5.356 0 .49.067.977.2 1.449l-3.16 2.242-5.006 3.551a5.3342 5.3342 0 00-2.504-.625c-2.921 0-5.326 2.404-5.326 5.326 0 2.922 2.405 5.326 5.326 5.326 1.22 0 2.404-.419 3.352-1.187l4.157 2.949 3.16 2.242c-.131.463-.199.942-.2 1.423v.017c0 2.931 2.412 5.343 5.343 5.343s5.343-2.412 5.343-5.343-2.412-5.343-5.343-5.343c-1.028 0-2.034.296-2.898.854l-4.524-3.21-3.129-2.22c.047-.282.072-.566.074-.852a5.299 5.299 0 00-.331-1.831l3.386-2.4 4.529-3.213zm2.766-7.525c1.646 0 3 1.354 3 3s-1.354 3-3 3-3-1.354-3-3 1.354-3 3-3zM5.271 20.421c-1.646 0-3-1.354-3-3s1.354-3 3-3 3 1.354 3 3c-.001 1.645-1.355 2.999-3 2.999v.001zm15.742 4.618c1.646 0 3 1.354 3 3s-1.354 3-3 3-3-1.354-3-3v-.001c0-1.646 1.354-3 3-3v.001z"
          fill="#ed1c24"
          fillRule="nonzero"
        />
      </svg>

      <strong>Share this result</strong>

      {showShare ? (
        <div className="share-methods">
          {emailShareText !== '' ? (
            <a
              target="_blank"
              href={`mailto:?subject=Funding opportunity&body=${replaceTemplate(
                emailTemplate,
                topicMetadata,
              )}`}
              title="Share by email"
            >
              @
            </a>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const replaceTemplate = (template: string, topicMetadata: any) => {
  try {
    const title = topicMetadata.title.replace(/<(?:.|\n)*?>/gm, '');
    const nextDeadline = moment(getNextDeadline(topicMetadata.deadline_dates)).format('DD.MM.YYYY');
    const deadline = nextDeadline !== 'Invalid date' ? `Deadline: ${nextDeadline}` : '-';
    let budget = '-';

    if (topicMetadata.budget_groups.length > 0) {
      const year = Object.keys(topicMetadata.budget_groups[0].budget_year_map)[0];
      const budgetAmount = topicMetadata.budget_groups[0].budget_year_map[year];

      if (year && budgetAmount) {
        budget = `${year}: ${budgetAmount}`;
      }
    }

    return template
      .replace('{{title}}', title)
      .replace('{{deadline}}', deadline)
      .replace('{{programme}}', topicMetadata.call_programme)
      .replace('{{budget}}', budget)
      .replace(/\n/g, '%0D%0A'); // Replace newlines
  } catch (e) {
    console.log(e);
    return '';
  }
};

export function getNextDeadline(dates: Date[]): Moment {
  const currentDate = moment();
  let nextDeadline = moment(dates[0]);

  for (const dateStr of dates) {
    const date = moment(dateStr, 'DD MMMM YYYY');
    if (currentDate < date) {
      nextDeadline = date;
      break;
    }
  }

  return moment(nextDeadline);
}
