import { Checkbox } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import * as React from 'react';
import * as auth from '../../app/Auth/Auth';

import './LicenseAgreement.css';

interface IState {
  showModal: boolean;
  acceptTerms: boolean;
}

interface IProps {
  continue?: string;
}

class LicenseAgreement extends React.Component<IProps, IState> {
  readonly state = {
    showModal: false,
    acceptTerms: false,
  };

  constructor(props: {}) {
    super(props);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  render() {
    return (
      <div className="license-agreement">
        <div className="application-terms">
          <p>
            Spinbase is an AI-based funding search tool, that takes natural language as an input. By entering
            your idea or project abstract, you will find suitable funding instruments, other players
            interested in same kind of subjects, and information to refine your plans.
          </p>

          <p>
            You can request free test access to service with your LinkedIn credentials. After receiving your
            request we will grant you access within 48 hours, in case you request is applicable. You will
            receive notification to your email connected your LinkedIn account.
          </p>

          <p>
            Free test period of the service is limited to 2 weeks. After this test period you agree to
            participate into interview/poll, which is used to further develop the usability of the service.
          </p>

          <p>
            After test period you will also receive an offer how to continue the actual use of the service.
          </p>
        </div>

        <div className="actions">
          <div className="check-agreement">
            <Checkbox label="" onChange={this.onCheckboxChange} />

            <span>
              I Agree to the{' '}
              <a href="#" onClick={this.showModal}>
                terms of service
              </a>
            </span>
          </div>

          <div className="apply-button">
            {this.props.continue ? (
              <PrimaryButton disabled={!this.state.acceptTerms} href={this.props.continue}>
                Continue
              </PrimaryButton>
            ) : (
              <PrimaryButton disabled={!this.state.acceptTerms} onClick={() => auth.login()}>
                Continue
              </PrimaryButton>
            )}
          </div>
        </div>

        <Panel
          isOpen={this.state.showModal}
          isLightDismiss={true}
          headerText=""
          onDismiss={this.closeModal}
          type={PanelType.large}
        >
          <div>
            <h1>Trial Software License Agreement for Funding Search</h1>

            <p>
              THIS IS A LEGAL AGREEMENT BETWEEN YOU, HERETOFORE REFERRED TO AS “THE CLIENT”, AND SPINVERSE OY,
              HERETOFORE REFERRED TO AS “THE VENDOR”, REGARDING THE TRIAL OF SPINBASE, HEREINAFTER REFERRED TO
              AS “THE SOFTWARE”. BY ACCESSING OR USING THE SOFTWARE, THE CLIENT ACKNOWLEDGES THAT THE CLIENT
              HAS READ THIS TRIAL SOFTWARE LICENSE AGREEMENT (“AGREEMENT”), THAT THE CLIENT UNDERSTANDS IT,
              CONSENTS TO BE BOUND BY AND BECOMING A PARTY TO THIS AGREEMENT.
            </p>

            <h2>1. GRANT OF LICENSE.</h2>

            <p>
              Spinverse Oy grants to the Client non-transferable, non-exclusive right to use the Software for
              non-commercial trial purposes during the Term defined in Section 5. The Client may use the
              Software on any computer owned, leased, or otherwise controlled solely by the Client. Trial is
              done in confidentiality basis and Client is not entitled to disclose neither details of the
              Software nor results from the trial use of the Software to third parties. Client understands
              that data collected from usage of the Software is property of Vendor and can be used to further
              develop the Software and/or Vendor’s services.
            </p>

            <h2>2. RESTRICTIONS:</h2>

            <p>Under this license, the Client MAY NOT:</p>

            <ol>
              <li>
                sell, lease, rent, license, sublicense, give access or otherwise distribute the Software or
                the results generated with it or any part thereof to any third person or entity;
              </li>

              <li>use the Software for any purpose other than expressly permitted by this Agreement;</li>

              <li>
                reproduce, modify, copy, transmit or create derivate work of all or any portion of the
                Software;
              </li>

              <li>
                reverse engineer, decompile, or disassemble the Software or otherwise attempt to recreate all
                or any portion of the Software;
              </li>

              <li>
                remove the copyright notice from the Software or the written materials, if any, accompanying
                the Software;
              </li>

              <li>
                use the Software for any illegal purpose, or in violation of any local, state, national, or
                international law;
              </li>

              <li>authorize any third party to do any of the foregoing.</li>

              <li>
                give access to the Software to any other Client’s employees, officers or representatives
                except those agreed between the Vendor and the Client.
              </li>
            </ol>

            <h2>3. CONTENT IN THE SOFTWARE</h2>

            <ol>
              <li>
                The Client understands that all information (such as data files, written text, computer
                software, music, audio files or other sounds, photographs, videos or other images) which the
                Client may have access to as part of, or through the Client ‘s use of, the Software are the
                sole responsibility of the person from which such content originated. All such information is
                referred to below as the “Content”.
              </li>

              <li>
                The Client should be aware that Content presented through the Software, may be protected by
                intellectual property rights which are owned by the Content owners. The Client may not modify,
                rent, lease, loan, sell, distribute or create derivative works based on this Content (either
                in whole or in part) unless The Client has been specifically told that the Client may do so by
                the owners of that Content, in a separate agreement.
              </li>

              <li>
                The Client understands that by using the Software the Client may be exposed to Content that
                may be found offensive, indecent or objectionable and that, in this respect, the Client uses
                the Software at the Client’s own risk.
              </li>

              <li>
                The Client agrees that the Client is solely responsible for (and that the Vendor has no
                responsibility to the Client or to any third party for) any Content that the Client creates,
                transmits or displays while using the Software and for the consequences of the Client actions
                (including any loss or damage which the Vendor may suffer) by doing so.
              </li>
            </ol>

            <h2>4. COPYRIGHT.</h2>

            <p>
              The Client acknowledges and agrees that the Software is proprietary product of the Vendor
              whether or not patented or copyrighted. The Client further acknowledges and agrees that all
              right, title, and interest in and to the Software, including associated intellectual property
              rights, is and shall remain with the Vendor. This Agreement does not convey to the Client an
              interest in or to the Software, but only a limited right of use revocable in accordance with the
              terms of this Agreement. No right, title, or interest in or to any trademark, service mark, logo
              or trade name of the Vendor is granted under this Agreement.
            </p>

            <h2>5. PRIVACY AND DATA PROTECTION.</h2>

            <p>
              In case the Vendor accesses any personal data from the Client for processing purposes in
              connection with this Agreement, the Client acts as a Controller, as defined by EU’s GDPR. The
              Client is responsible for the privacy and data protection of such data according to existing
              legislation. The Vendor acts as a Processor, as defined by EU’s GDPR, and is responsible for
              processing personal data on behalf of the Client and according to instructions given by the
              Client.
            </p>

            <h2>6. TERM.</h2>

            <p>
              This Agreement becomes effective as of the date on which the Client receives access to the
              Software (Effective Date) and terminates 30 days from the Effective Date. The Client may
              terminate this Agreement at any time by notifying the Vendor. The Vendor may terminate this
              Agreement at any time and without notice in the event that:
            </p>

            <ol>
              <li>
                The Client uses the Software or otherwise engages in any action that, in the Vendor's sole
                discretion, may harm the Vendor;
              </li>

              <li>
                The Vendor determines that it is in the best interest of the Vendor to terminate the
                Agreement;
              </li>
            </ol>

            <p>
              This Agreement will terminate automatically if the Client fails to comply with the terms and
              conditions of this Agreement.
            </p>

            <p>
              Upon termination for any reason, the Client shall cease to use the Software, destroy all copies
              of the Software and the Content, all of its component parts, functionally-equivalent
              derivatives, and all portions and modifications thereof in any form and delete and permanently
              purge the Software and the Content from any server or computer on which it has been installed,
              if any. The Client shall upon request provide the Vendor with a certificate on the fulfillment
              of the Client’s obligations hereunder.
            </p>

            <h2>7. NO WARRANTY.</h2>

            <p>
              ANY USE BY THE CLIENT OF THE SOFTWARE IS AT THE CLIENT’S OWN RISK. THE SOFTWARE IS PROVIDED FOR
              USE “AS IS” WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE VENDOR
              DISCLAIM ALL WARRANTIES OF ANY KIND, EITHER EXPRESS, STATUTORY OR IMPLIED, INCLUDING, WITHOUT
              LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
              NONNINFRINGEMENT. THE VENDOR IS NOT OBLIGATED TO PROVIDE ANY UPDATES TO THE SOFTWARE. THE VENDOR
              DOES NOT WARRANT THAT THE SOFTWARE OR ANY PART THEREOF WILL MEET THE CLIENT’S REQUIREMENTS OR BE
              UNINTERRUPTED, SECURE OR ERROR-FREE, OR THAT ERRORS WILL BE CORRECTED.
            </p>

            <h2>8. NO LIABILITY FOR DAMAGES.</h2>

            <p>
              IN NO EVENT SHALL THE VENDOR OR ITS SUPPLIERS BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING,
              WITHOUT LIMITATION, INCIDENTAL, DIRECT, INDIRECT, SPECIAL AND CONSEQUENTIAL DAMAGES, DAMAGES FOR
              LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR OTHER
              PECUNIARY LOSS) ARISING OUT OF THE USE OR INABILITY TO USE THE SOFTWARE, EVEN IF ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING FAULT, NEGLIGENCE AND THE FAILURE OF THE
              ESSENTIAL PURPOSE. REGARDLESS OF THE CAUSE OR FORM OF THE ACTION, THE VENDOR'S AGGREGATE
              LIABILITY TO THE CLIENT FOR ACTUAL DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT SHALL BE
              LIMITED TO THE TOTAL AMOUNT OF THE LICENSE FEE, IF ANY, PAID BY THE CLIENT TO THE VENDOR FOR THE
              USE OF THE SOFTWARE. BECAUSE SOME STATES/COUNTRIES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
              LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO THE
              CLIENT.
            </p>

            <h2>9. INDEMNIFICATION.</h2>

            <p>
              The Client shall defend, indemnify and hold harmless the Vendor, its Affiliates, and their
              licensors, officers, directors, agents and employees from any liability, loss, damage, cost or
              expense (including reasonable attorney's fees) arising out of any act or omission by the Client
              in connection with the Software.
            </p>

            <h2>10. GOVERNING LAW AND JURISDICTION.</h2>

            <p>
              This Agreement and all rights and obligations hereunder, including but not limited to matters of
              construction, validity and performance, shall be governed by and construed in accordance with
              the laws of Finland.
            </p>

            <h2>11. PREVAILING AGREEMENT.</h2>

            <p>
              In the event of any conflict between the terms and conditions of this Agreement and the terms
              and conditions of any license agreements appearing with or in the software products comprising
              the Software, this Agreement shall prevail.
            </p>

            <h2>12. ASSIGNMENT.</h2>

            <p>
              This Agreement may not be assigned by the Client without the prior written consent of the
              Vendor. The Vendor may assign this Agreement without the Client’s consent.
            </p>

            <h2>13. SEVERABILITY.</h2>

            <p>
              Should any term of this Agreement be declared void or unenforceable by any court of competent
              jurisdiction, such declaration shall have no effect on the remaining terms hereof.
            </p>

            <h2>13. ENTIRE AGREEMENT; NO WAIVER.</h2>

            <p>
              This Agreement represents the entire agreement concerning the Software between the Client and
              the Vendor, and it supersedes any prior proposal, representation, or understanding between the
              parties. The failure of either party to enforce any rights granted hereunder or to take action
              against the other party in the event of any breach hereunder shall not be deemed a waiver by
              that party as to subsequent enforcement of rights or subsequent actions in the event of future
              breaches.
            </p>
          </div>
        </Panel>
      </div>
    );
  }

  private showModal = (e: any): void => {
    e.preventDefault();
    this.setState({ showModal: true });
  };

  private closeModal = (): void => {
    this.setState({ showModal: false });
  };

  private onCheckboxChange(ev: React.FormEvent<HTMLElement>, isChecked: boolean): void {
    this.setState({ acceptTerms: isChecked });
  }
}

export default LicenseAgreement;
