import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import * as React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '../../app/Modal/actions';
import { IState } from '../../reducers';
import { store } from '../../store';

import './Modal.css';

interface IModalProps {
  showModal: boolean;
  children: React.ReactNode;
}

const Modal: React.FC<IModalProps> = ({ showModal, children }) => {
  React.useEffect(() => {
    if (showModal) {
      document.body.classList.add('preventModalScrolling');
    } else {
      document.body.classList.remove('preventModalScrolling');
    }
  }, [showModal]);

  return (
    <React.Fragment>
      {showModal && (
        <div className="modal__window">
          <div className="modal__container">
            <button onClick={() => store.dispatch(closeModal())} className="modal__close">
              <Icon iconName="Cancel" />
            </button>

            <div className="modal__content">{children}</div>

            <div className="modal__actions">
              <PrimaryButton onClick={() => store.dispatch(closeModal())}>Close</PrimaryButton>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ modal }: IState) => {
  return {
    showModal: modal.showModal,
  };
};

export default connect(mapStateToProps)(Modal);
