import { History } from 'history';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import { Router } from 'react-router-dom';
import { IState } from './reducers';
import Routes from './routes';

interface IPropsFromDispatch {
  [key: string]: any;
}

interface IOwnProps {
  store: Store<IState>;
  history: History;
}

type AllProps = IPropsFromDispatch & IOwnProps;

class Main extends React.Component<AllProps> {
  public render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    );
  }
}

export default Main;
