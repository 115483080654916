import * as React from 'react';
import { INationalSearchState } from '../../../../../app/NationalSearch/reducer';

import { Accordion } from '../../../../Accordion';

interface INationalSearchResultMainProps {
  nationalSearchResults: INationalSearchState;
  topic: {
    id: any;
    metadata: {
      applicant_types: string[];
      application_terms: string;
      application_url: string;
      categories: string[];
      deadline_date: string;
      deadline_ongoing: boolean;
      description: string;
      eligible_countries: string[];
      foundation_name: string;
      foundation_url: string;
      funding_types: string[];
      keywords: string;
      opening_date: string;
      source: string | null;
      source_name: string;
      title: string;
      url: string;
    };
    summary: {
      keywords: string[];
      preview: string;
    };
  };
}

export const NationalSearchResultMain = ({
  nationalSearchResults,
  topic,
}: INationalSearchResultMainProps) => {
  return (
    <div className="topic__description">
      <div
        dangerouslySetInnerHTML={{
          __html: nationalSearchResults.topicDescription[topic.id],
        }}
      />

      <br />

      {topic && topic.metadata.application_terms ? (
        <Accordion showTitle="Show call applications terms" hideTitle="Hide call applications terms">
          <div
            dangerouslySetInnerHTML={{
              __html: topic.metadata.application_terms,
            }}
          />
          <hr />
        </Accordion>
      ) : null}
    </div>
  );
};
