import * as React from 'react';
import { connect } from 'react-redux';
import { removeSavedDocumentRequest } from '../../../app/Saved/actions';
import { clearSelectedTopic, fetchTopicDescription, selectableTabs } from '../../../app/Search/actions';
import SearchResult from '../../../components/Search/SearchResults/SearchResult/SearchResult';
import { IState } from '../../../reducers';
import { store } from '../../../store';
import { ExpandableSection } from '../ExpandableSection';
import './SavedTopics.css';

interface IProps {
  saved?: any;
  searchResults?: any;
}

interface ITopicProps {
  topic: any;
  handleOpen: () => void;
}

const Topic: React.FC<ITopicProps> = ({ topic, handleOpen }) => {
  const removeCall = (savedDocument: any) => {
    store.dispatch(removeSavedDocumentRequest(savedDocument.objectId));
  };

  const openCall = (savedDocument: any) => {
    handleOpen();
    store.dispatch(
      fetchTopicDescription({
        topicId: savedDocument.objectId,
        query: '',
      }),
    );
  };

  return (
    <div className="saved-topics__topic">
      <p className="saved-topics__topic-title">{topic.title}</p>
      <div className="saved-topics__topic-buttons">
        <button className="open" onClick={() => openCall(topic)}>
          Open
        </button>

        <button className="remove" onClick={() => removeCall(topic)}>
          Remove
        </button>
      </div>
    </div>
  );
};

interface ISavedTopics {
  showTopicPanel: boolean;
}

class SavedTopics extends React.Component<IProps, ISavedTopics> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showTopicPanel: false,
    };

    this.openTopicPanel = this.openTopicPanel.bind(this);
    this.closeTopicPanel = this.closeTopicPanel.bind(this);
  }

  openTopicPanel() {
    this.setState({ showTopicPanel: true });
  }

  closeTopicPanel() {
    this.setState({ showTopicPanel: false });
    store.dispatch(clearSelectedTopic());
  }

  render() {
    const { saved, searchResults } = this.props;
    const savedTopics = saved.savedDocuments.filter(
      (document: any) => document.scope === selectableTabs.FUNDING,
    );

    return (
      <ExpandableSection title="Saved Topics" count={savedTopics.length}>
        <div className="saved-topics">
          {savedTopics.map((document: any, i: number) => (
            <Topic key={document.objectId + i} topic={document} handleOpen={this.openTopicPanel} />
          ))}

          {this.state.showTopicPanel ? (
            <SearchResult topic={searchResults.selectedTopic} onDismiss={this.closeTopicPanel} />
          ) : null}
        </div>
      </ExpandableSection>
    );
  }
}

const mapStateToProps = ({ saved, searchResults }: IState) => {
  return { saved, searchResults };
};

export default connect(mapStateToProps)(SavedTopics);
