/* Difficult files to refactor */
import { createId } from '@paralleldrive/cuid2';
import * as React from 'react';
import { connect } from 'react-redux';
import { fetchProjectRequest } from '../../../app/Projects/actions';
import { removeSavedDocumentRequest } from '../../../app/Saved/actions';
import { ISavedState } from '../../../app/Saved/reducer';
import { selectableTabs } from '../../../app/Search/actions';
import ProjectDialog from '../../../components/Search/SearchResults/ProjectResult/ProjectDialog';
import { IState } from '../../../reducers';
import { store } from '../../../store';
import { ExpandableSection } from '../ExpandableSection';
import './SavedTopics.css';

interface IProps {
  saved: ISavedState;
  searchResults?: any;
}

interface IProjectProps {
  project: any;
}

const Project: React.FC<IProjectProps> = ({ project }) => {
  const removeProject = (savedDocument: any) => {
    store.dispatch(removeSavedDocumentRequest(savedDocument.objectId));
  };

  const openProject = (savedDocument: any) => {
    store.dispatch(fetchProjectRequest(Number(savedDocument.objectId)));
  };

  return (
    <div className="saved-topics__topic">
      <div className="saved-topics__topic-title">
        <p>{project.title}</p>
      </div>

      <div className="saved-topics__topic-buttons">
        <button className="open" onClick={() => openProject(project)}>
          Open
        </button>

        <button className="remove" onClick={() => removeProject(project)}>
          Remove
        </button>
      </div>
    </div>
  );
};

const SavedProjects: React.FC<IProps> = ({ saved }) => {
  const savedProjects = saved.savedDocuments.filter(
    (document: any) => document.scope === selectableTabs.PROJECTS,
  );

  return (
    <ExpandableSection title="Saved projects" count={savedProjects.length}>
      <div className="saved-topics">
        {savedProjects.map((document: any) => (
          <Project project={document} key={createId()} />
        ))}
        <ProjectDialog />
      </div>
    </ExpandableSection>
  );
};

const mapStateToProps = ({ saved, searchResults }: IState) => {
  return { saved, searchResults };
};

export default connect(mapStateToProps)(SavedProjects);
