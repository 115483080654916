import { Reducer } from 'redux';
import * as auth from '../Auth/Auth';
import { SearchActionTypes, selectableTabs } from './actions';

export interface ISearchState {
  results: any[];
  isFetching: boolean;
  isClosedTopicsFetching: boolean;
  isDescriptionFetching: boolean;
  error: string;
  closedEntries: boolean;
  closedTopics: any[];
  query: string;
  exclude: any[];
  formValid: boolean;
  searchInputValid: boolean;
  selectedTab: string;
  orderCondition: any;
  selectedItems: any[];
  filterConditions: any[];
  selectedTopic: any;
  topicDescription: any;
}

export const initialState: ISearchState = {
  error: '',
  isFetching: false,
  isClosedTopicsFetching: false,
  isDescriptionFetching: false,
  results: [],
  closedTopics: [],
  query: '',
  closedEntries: false,
  exclude: [],
  formValid: false,
  searchInputValid: false,
  selectedTab: selectableTabs.FUNDING,
  orderCondition: { name: 'Match', key: 'similarity', value: 'similarity' },
  selectedItems: [],
  filterConditions: [
    { key: 'topic_status', value: 'Draft' },
    { key: 'topic_status', value: 'Open' },
    { key: 'topic_status', value: 'Forthcoming' },
  ],
  selectedTopic: {},
  topicDescription: {},
};

const searchReducer: Reducer<ISearchState> = (state = initialState, action): ISearchState => {
  switch (action.type) {
    case SearchActionTypes.SEARCH_REQUEST: {
      return {
        ...state,
        selectedItems: [],
        topicDescription: {},
        isFetching: true,
      };
    }
    case SearchActionTypes.SEARCH_SUCCESS: {
      return {
        ...state,
        results: action.payload.results,
        isFetching: false,
        exclude: getIds(action.payload.results),
        error: '',
      };
    }
    case SearchActionTypes.SEARCH_MORE_REQUEST: {
      return { ...state, isFetching: true };
    }
    case SearchActionTypes.SEARCH_MORE_SUCCESS: {
      return {
        ...state,
        results: [...state.results, ...action.payload.results],
        isFetching: false,
        exclude: [...state.exclude, ...getIds(action.payload.results)],
      };
    }
    case SearchActionTypes.SEARCH_CLOSED_TOPICS_REQUEST: {
      return {
        ...state,
        isClosedTopicsFetching: true,
      };
    }
    case SearchActionTypes.SEARCH_CLOSED_TOPICS_SUCCESS: {
      return {
        ...state,
        isClosedTopicsFetching: false,
        closedTopics: [...state.closedTopics, ...action.payload],
      };
    }
    case SearchActionTypes.SEARCH_ERROR: {
      if (action.payload === 'invalid_token') {
        auth.login();
      }
      return { ...state, error: action.payload, isFetching: false };
    }
    case SearchActionTypes.SET_QUERY: {
      if (action.payload.trim().length < 1) {
        return {
          ...state,
          formValid: false,
          searchInputValid: false,
          query: action.payload,
        };
      }
      return {
        ...state,
        formValid: true,
        searchInputValid: true,
        query: action.payload,
      };
    }
    case SearchActionTypes.SET_FORM_VALID: {
      return { ...state, formValid: true, searchInputValid: true };
    }
    case SearchActionTypes.SELECT_TAB: {
      return {
        ...state,
        selectedTab: action.payload,
        filterConditions:
          action.payload === selectableTabs.FUNDING
            ? [
                {
                  key: 'topic_status',
                  value: 'Draft',
                },
                {
                  key: 'topic_status',
                  value: 'Open',
                },
                {
                  key: 'topic_status',
                  value: 'Forthcoming',
                },
              ]
            : [],
      };
    }
    case SearchActionTypes.SET_ORDER_CONDITION: {
      return { ...state, orderCondition: action.payload };
    }
    case SearchActionTypes.ADD_FILTER_CONDITION: {
      return {
        ...state,
        filterConditions: [
          ...state.filterConditions,
          { key: action.payload.key, value: action.payload.value },
        ],
      };
    }
    case SearchActionTypes.REMOVE_FILTER_CONDITION: {
      return {
        ...state,
        filterConditions: state.filterConditions.filter(
          (condition) => !(condition.key === action.payload.key && condition.value === action.payload.value),
        ),
      };
    }
    case SearchActionTypes.CLEAR_FILTER: {
      if (state.selectedTab === selectableTabs.FUNDING) {
        return {
          ...state,
          filterConditions: [
            {
              key: 'topic_status',
              value: 'Draft',
            },
            {
              key: 'topic_status',
              value: 'Open',
            },
            {
              key: 'topic_status',
              value: 'Forthcoming',
            },
          ],
        };
      }

      return {
        ...state,
        filterConditions: [],
      };
    }
    case SearchActionTypes.SELECT_ITEM: {
      const alreadySelected = state.selectedItems.some(
        (selectedItem) => selectedItem.id === action.payload.id,
      );
      if (alreadySelected) {
        return state;
      } else {
        return {
          ...state,
          selectedItems: [...state.selectedItems, action.payload],
        };
      }
    }
    case SearchActionTypes.UNSELECT_ITEM: {
      const newTopicDescription = { ...state.topicDescription };
      delete newTopicDescription[action.payload.id];
      return {
        ...state,
        selectedItems: state.selectedItems.filter((item) => item.id !== action.payload.id),
        topicDescription: newTopicDescription,
      };
    }
    case SearchActionTypes.FETCH_TOPIC_SUCCESS: {
      return {
        ...state,
        selectedTopic: action.payload,
      };
    }
    case SearchActionTypes.CLEAR_SELECTED_TOPIC: {
      return {
        ...state,
        selectedTopic: {},
      };
    }
    case SearchActionTypes.FETCH_TOPIC_INTERESTED_PARTNERS_SUCCESS: {
      const { topicInterestedPartner, topicId } = action.payload;
      const selectedItems = state.selectedItems.map((selectedItem) => {
        if (selectedItem.id === topicId) {
          selectedItem.topic_organizations = topicInterestedPartner;
        }
        return selectedItem;
      });

      return {
        ...state,
        selectedItems,
      };
    }
    case SearchActionTypes.FETCH_TOPIC_DESCRIPTION_REQUEST: {
      return {
        ...state,
        isDescriptionFetching: true,
      };
    }
    case SearchActionTypes.FETCH_TOPIC_DESCRIPTION_SUCCESS: {
      return {
        ...state,
        isDescriptionFetching: false,
        topicDescription: {
          ...state.topicDescription,
          [action.payload.id]: action.payload.highlighted_texts.description[0],
        },
        selectedTopic: action.payload,
      };
    }
    case SearchActionTypes.CLEAR_TOPIC_DESCRIPTION: {
      return {
        ...state,
        topicDescription: {},
      };
    }
  }
  return state;
};

const getIds = (results: any) => {
  return [
    ...results.map((res: any) => {
      return res.metadata.identifier;
    }),
  ];
};

export { searchReducer };
