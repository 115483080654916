import * as classnames from 'classnames';
import * as React from 'react';
import { useCalendarContext } from './Context';
import Month from './Month';
import ViewDate from './ViewDate';
import './calendar.css';

export const Calendar = () => {
  const { loading, open, viewDate, closeCalendar } = useCalendarContext();

  const preventDefaultCalendarClick = React.useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  const hideCalendarModal = () => {
    document.body.classList.remove('preventModalScrolling');
    closeCalendar();
  };

  if (!open) {
    return null;
  }

  return (
    <div className="calendar-container" onClick={hideCalendarModal}>
      <div
        className={classnames('calendar', {
          'calendar--loading': loading,
        })}
        onClick={preventDefaultCalendarClick}
      >
        <div className="calendar-header">
          Calendar
          <button onClick={hideCalendarModal} className="calendar-close">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="calendar-content">{viewDate ? <ViewDate /> : <Month />}</div>
      </div>
    </div>
  );
};
